<template>

    <span class="ergebnisseTableShowResults">

        <b-button variant="outline-danger"
                        size="sm"
                        @click="leereCache()"
                        v-if="$store.getters.user_role.authority == ROLE_ADMIN">
          {{ $t('message.ergebnisseTable_cache_leeren') }}
        </b-button>

        <div class="row">
            <div class="col-md-12">

              <b-overlay :show="showOverlay" rounded="sm">
                <template #overlay>
                  <div class="text-center">
                    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label">{{ $t('message.ergebnisseTable_aufbereiten') }} ...</p>
                  </div>
                </template>
                <b-alert variant="info" show v-if="empty">
                    {{ $t('message.ergebnisseTable_leer') }}
                </b-alert>
                <transition name="fade" mode="out-in">
                    <div v-if="ergebnisse">

                        <div class="row mb-3" v-if="totalRows >= anzahl">
                            <div class="col text-right">
                                <b-button variant="outline-secondary"
                                          size="sm"
                                          class="mr-2"
                                          @click="generateReport('excel')">
                                    <font-awesome-icon icon="file-excel" />&nbsp;{{ $t('message.positionen_excel') }}
                                </b-button>
                                <b-button variant="outline-secondary"
                                          size="sm"
                                          class="mr-2"
                                          @click="generateReport('pdf')">
                                    <font-awesome-icon icon="file-pdf" />&nbsp;{{ $t('message.positionen_pdf') }}
                                </b-button>
                                <b-button variant="outline-secondary"
                                          size="sm"
                                          class="mr-2"
                                          @click="print()">
                                    <font-awesome-icon icon="print" />&nbsp;{{ $t('message.positionen_drucken') }}
                                </b-button>
                            </div>
                        </div>

                        <!-- Ergebnis Tabelle Filtern -->
                        <div id="filter" class="row border-top mb-3 pt-3">

                            <div class="col-md-11">

                                <b-input-group size="sm">
                                    <b-form-input v-model="keyword"
                                                  type="search"
                                                  id="filterInput"
                                                  :placeholder="$t('message.positionen_filter')"
                                    />
                                    <b-input-group-append>
                                        <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </div>
                            <div class="col-md-1 text-right">

                                <b-form-group horizontal label="" class="">
                                    <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                                </b-form-group>

                            </div>

                        </div>

                        <b-table striped
                                 id="ergebnisse-table"
                                 small
                                 :fields="fields"
                                 :items="items"
                                 :keyword="keyword"
                                 :current-page="currentPage"
                                 :per-page="perPage"
                                 :sort-compare="sortPositionsergebnis"
                                 hover
                                 :responsive="true">

                            <template v-slot:cell(alarm_level)="row">
                                <ergebnis-and-chart
                                     :alarm_level="row.item.alarm_level"
                                     :result_value="row.item.result_value"
                                     :not-checkable="row.item.is_not_checkable"
                                     :task_ergebnis_id="row.item.task_ergebnis_id"
                                     v-if="row.item.result_value || row.item.is_not_checkable"
                                />
                                <span v-else-if="row.item.traplinked_device_id">
                                  <traplinked-ergebnis v-model="row.item.traplinked_report_report_type"
                                                       :id="row.item.id"
                                                       v-on:set-ergebnis-text="setErgebnisText"
                                  />
                                </span>
                            </template>

                            <template v-slot:cell(berater_name)="row">
                                <b-button variant="outline-primary"
                                          size="sm"
                                          v-if="row.item.berater_name"
                                          @click="showBearbeiter(row.item.berater_id)">
                                     <b-icon icon="person-square"></b-icon>&nbsp;{{ row.item.berater_name }}
                                </b-button>
                            </template>

                            <template v-slot:cell(sicherheitsdatenblaetter)="row">
                                <sicherheitsdatenblaetter v-if="row.item.article_id" :article_id="row.item.article_id" />
                            </template>

                            <!-- Link auf Plandarstellung von Position weg -->
                            <template v-slot:cell(position_nr)="row">
                              <span v-if="row.item.position_nr && row.item.position_id">
                                <ShowPositionInMap
                                    :position-id="row.item.position_id"
                                    :position-nr="row.item.position_nr"
                                />
                              </span>
                            </template>

                            <!-- Bei Positions Text noch zusätzlich ausgeben ob es eine temp Position (Sap Temp) ist: -->
                            <template v-slot:cell(position_beschreibung)="row">
                              <span v-if="row.item.position_beschreibung">
                               {{ row.item.position_beschreibung }}
                              </span>
                              <span v-if="row.item.stays_temp">
                                <b-badge variant="info">Zusatzleistung</b-badge>
                              </span>
                            </template>

                            <!-- Traplinked task_name Biotech 24/7 anzeigen, sonst den tast_name -->
                            <template v-slot:cell(task_name)="row">
                              <span v-if="row.item.task_name">
                               {{ row.item.task_name }}
                              </span>
                              <span v-else-if="row.item.traplinked_device_id">
                                Biotech 24/7
                              </span>
                            </template>

                            <!-- Traplinked objekt_bezeichnung Device anzeigen, sonst die objekt_bezeichnung -->
                            <template v-slot:cell(objekt_bezeichnung)="row">
                              <span v-if="row.item.objekt_bezeichnung">
                               {{ row.item.objekt_bezeichnung }}
                              </span>
                              <span v-else-if="row.item.objekt && row.item.objekt.bezeichnung">
                                {{ row.item.objekt.bezeichnung }}
                              </span>
                            </template>

                            <!-- bei Traplinked in die Bemerkung den Timestamp reinschreiben -->
                            <template v-slot:cell(bemerkung)="row">
                              <span v-if="row.item.bemerkung">
                               {{ row.item.bemerkung }}
                              </span>
                              <span v-else-if="row.item.traplinked_device_id">
                                <span v-if="row.item.timestamp">
                                  {{ $t('message.ergebnisseTable_zeitpunkt') }}: <java-date-to-default-time-format v-model="row.item.timestamp" />
                                </span>
                                <span v-else-if="row.item.start || row.item.end">
                                  <!-- für ein grünes Ergebnis den kompletten Zeitraum des Besuchsberichts anzeigen -->
                                  {{ $t('message.ergebnisseTable_zeitraum') }}: <java-date-to-default-date-format v-model="row.item.start" /> - <java-date-to-default-date-format v-model="row.item.end" />
                                </span>
                              </span>
                            </template>

                        </b-table>

                        <b-row>
                            <b-col md="12" class="my-1">
                                <b-pagination :total-rows="totalRows"
                                              :per-page="perPage"
                                              v-model="currentPage"
                                              class="my-0"
                                />
                            </b-col>
                        </b-row>

                    </div>
                </transition>
              </b-overlay>
            </div>

        </div>

        <!-- Modal für Personal Details -->
        <b-modal id="modal-berater" :title="$t('message.ergebnisseTable_berater')" hide-footer scrollable>
            <person :person="person" />
        </b-modal>

        <!-- Dummy Form um pdf und excel zu holen -->
        <form ref="form" action="" id="form_id" target="_blank" method="post" style="position: fixed; left: -1000px; top: -1000px;">
            <input name="type" id="form_type" value="">
            <input name="liste" id="form_liste" value="">
            <input name="besuchsbericht" id="form_besuchsbericht" value="">
            <input name="customer" id="form_customer" value="">
            <input name="auftrag" id="form_auftrag" value="">
        </form>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import ErgebnisAndChart from "@/components/ergebnisliste/ErgebnisAndChart";
    import Person from '@/components/team/person'
    import Sicherheitsdatenblaetter from '@/components/ergebnisliste/Sicherheitsdatenblaetter'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
    import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
    import { faPrint } from '@fortawesome/free-solid-svg-icons';
    library.add(faFileExcel, faFilePdf, faPrint);
    import TraplinkedErgebnis from '@/components/traplinkedbericht/TraplinkedErgebnis';
    import JavaDateToDefaultTimeFormat from '@/components/layout/JavaDateToDefaultTimeFormat';
    import JavaDateToDefaultDateFormat from '@/components/layout/JavaDateToDefaultDateFormat';

    // Define a new component
    export default {
        name: 'ErgebnisseTable',
        components: {
          Person,
          ErgebnisAndChart,
          Sicherheitsdatenblaetter,
          ShowPositionInMap: () => import('@/components/plan/ShowPositionInMap'),
          TraplinkedErgebnis,
          JavaDateToDefaultTimeFormat,
          JavaDateToDefaultDateFormat
        },
        props: {

            /** Ergebnisse für welchen Besuchsbericht? */
            besuchsbericht: {
                type: Object,
                required: true
            },
            auftrag: {
                type: Object,
                required: true
            },
            reset: {
                type: Boolean,
                required: false
            }

        },
        /*
          Defines the data used by the component
        */
        data: function() {
          return {
            ergebnisse: [],
            anzahl: 0,
            fields: [
              {
                key: 'area_name',
                label: this.$t('message.printPositionen_bereich'),
                sortable: true
              },
              {
                key: 'position_nr',
                label: this.$t('message.printPositionen_position_nummer'),
                sortable: true
              },
              {
                key: 'position_beschreibung',
                label: this.$t('message.printPositionen_position'),
                sortable: true
              },
              {
                key: 'task_name',
                label: this.$t('message.printErgebnisliste_aufgabe'),
                sortable: true
              },
              {
                key: 'objekt_bezeichnung',
                label: this.$t('message.printErgebnisliste_objekt'),
                sortable: true
              },
              {
                key: 'alarm_level',
                label: this.$t('message.printErgebnisliste_ergebnis'),
                sortable: true
              },
              {
                key: 'bemerkung',
                label: this.$t('message.printErgebnisliste_bemerkung'),
                sortable: true
              },
              {
                key: 'item_name',
                label: this.$t('message.printErgebnisliste_material'),
                sortable: true
              },
              {
                key: 'menge',
                label: this.$t('message.printErgebnisliste_menge'),
                sortable: true
              },
              {
                key: 'sal_unit_msr',
                label: this.$t('message.printErgebnisliste_einheit'),
                sortable: true
              },
              {
                key: 'berater_name',
                label: this.$t('message.printErgebnisliste_berater'),
                sortable: true
              },
              {
                key: 'sicherheitsdatenblaetter',
                label: this.$t('message.ergebnisseTable_sdb'),
                sortable: false
              }
            ],
            /** Filter Query String für Suche nach Position */
            keyword: '',
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            /** Pagination der Ergebnistabelle */
            currentPage: 1,
            /** auf true setzen falls keine Ergebnisse vorhanden sind */
            empty: false,
            /** Anzahl der Ergebnisse nach Tasks */
            anzahlTasks: 0,
            /** Anzahl der Ergebnisse nach Positionen */
            anzahlPositions: 0,
            /** Anzahl der Ergebnisse nach Besuchsbericht */
            anzahlBesuchsbericht: 0,
            /** Anzahl der Ergebnisse aus Traplinkedberichten */
            anzahlTraplinked: 0,
            /** Berater der in den Details angezeigt wird */
            person: null,
            form_action: null,
            form_type: null,
            form_liste: null,
            form_besuchsbericht: null,
            form_customer: null,
            form_auftrag: null,
            /** loading Spinner */
            showOverlay: false
          }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          besuchsbericht: function () {
            this.init();
          }

        },
        computed: {

            items: function () {

              var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

              var selectedRows = lowercase
                ? this.ergebnisse.filter( item =>

                  (item.area_name                   && item.area_name.toLowerCase().includes(lowercase))                ||
                  (item.position_nr                 && item.position_nr.toLowerCase().includes(lowercase))              ||
                  (item.position_beschreibung       && item.position_beschreibung.toLowerCase().includes(lowercase))    ||
                  (item.task_name                   && item.task_name.toLowerCase().includes(lowercase))                ||
                  (item.objekt_bezeichnung          && item.objekt_bezeichnung.toLowerCase().includes(lowercase))       ||
                  (item.ergebnis                    && item.ergebnis.toLowerCase().includes(lowercase))                 ||
                  (item.item_name                   && item.item_name.toLowerCase().includes(lowercase))                ||
                  (item.menge == lowercase)                                                                             ||
                  (item.berater_name                && item.berater_name.toLowerCase().includes(lowercase))             ||
                  (item.result_value                && item.result_value.toLowerCase().includes(lowercase))             ||
                  (item.ergebnisText                && item.ergebnisText.toLowerCase().includes(lowercase))             ||
                  (item.is_not_checkable === true   && 'nicht überprüfbar'.includes(lowercase))

                )
                : this.ergebnisse;

              return selectedRows;

            },
            totalRows: function () {
              if (this.items) {
                return this.items.length;
              }
              return 0;
            }

        },
        methods: {

          /**
           * Text des Ergebnisses in Liste aufnehmen zum Filtern
           * @param obj
           */
          setErgebnisText: function (obj) {

            /*
            const obj = {
              text: text,
              id: this.id
            }
            */

            for (let i = 0; i < this.ergebnisse.length; i++) {
              if (this.ergebnisse[i].id == obj.id) {
                this.ergebnisse[i].ergebnisText = obj.text;
                break;
              }
            }

          },
          /** Sortierung der Positionsergebnisse */
          sortPositionsergebnis: function (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {

            // console.log("sort by key = " + key + " a=" + aRow);

            if (key === 'alarm_level') {

              const a = aRow[key];
              const b = bRow[key];
              const aValue = aRow['result_value'];
              const bValue = bRow['result_value'];
              var first = 0;
              var next  = 0;
              console.log('sort by key = ' + key + ' a= ' + (a ? a : null) +
                ' b=' + (b ? b : null) + ' value a = ' + aValue + ' value b = ' + bValue);
              if (a && a == 'GRUEN')      first = 1;
              else if (a && a == 'GELB')  first = 2;
              else if (a && a == 'ROT')   first = 3;
              if (b && b == 'GRUEN')      next = 1;
              else if (b && b == 'GELB')  next = 2;
              else if (b && b == 'ROT')   next = 3;

              // wenn alarm_level ungleich ist, dann sortiere nach diesem:
              if (first != next) {
                return first < next ? -1 : first > next ? 1 : 0;
              } else {
                // sonst sortiere nach dem result_value
                console.log('sort by value a = ' + aValue + ' value b = ' + bValue);
                return this.toString(aValue).localeCompare(this.toString(bValue), compareLocale, compareOptions);
              }

            }

          },
          /**
           * zeige Druck Page
           */
          print: function () {

            var items       = JSON.stringify(this.items);
            var auftragsNr  = this.auftrag.auftragsNr;
            var ended       = this.besuchsbericht.ended;

            let routeData = this.$router.resolve({path: '/printErgebnisliste/:name', query: {
                items: items,
                auftragsNr: auftragsNr,
                ended: ended,
                kundenname: this.$store.getters.customer.kunde,
                adresse: this.$store.getters.customer.zipcode + " " + this.$store.getters.customer.ort + ", " + this.$store.getters.customer.adresse,
                cardcode: this.$store.getters.customer.cardcode
            }});
            console.log('open ' + routeData.href);
            window.open(routeData.href, '_blank');

          },
          /**
           * PDF oder Excel der Ergebnisliste anzeigen
           */
          generateReport: function (type) {

            var serverurl       = process.env.VUE_APP_SERVER_URL
            var liste           = JSON.stringify(this.items);
            liste               = encodeURI(liste);
            var kunde           = JSON.stringify(this.$store.getters.customer)
            kunde               = encodeURI(kunde);
            var besuchsbericht  = JSON.stringify(this.besuchsbericht);
            besuchsbericht      = encodeURI(besuchsbericht);
            var url             = serverurl + '/report/generateErgebnislisteReport';
            var auftrag         = JSON.stringify(this.auftrag);
            auftrag             = encodeURI(auftrag);

            this.form_action = url;
            document.getElementById('form_id').action               = url;
            document.getElementById('form_type').value              = type;
            document.getElementById('form_liste').value             = liste;
            document.getElementById('form_besuchsbericht').value    = besuchsbericht;
            document.getElementById('form_customer').value          = kunde;
            document.getElementById('form_auftrag').value           = auftrag;

            this.$refs.form.submit();

          },
          /** zeigt Details eines Personals */
          showBearbeiter: function (personal_id) {

            this.person  = null;
            this.showSpinner();
            var person   = { id: personal_id };
            var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPerson';
            var that     = this;
            var params   = {
              person: JSON.stringify(person)
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              that.hideSpinner();
              that.person = response.data;
              that.$bvModal.show('modal-berater');

            }).catch(error => {
              console.log(error);
              that.hideSpinner();
            });

          },
          init: function () {
            this.getErgebnisse();
          },
          /** Holt die Ergebnisse */
          getErgebnisse: function () {

            if (this.besuchsbericht) {

              this.showOverlay = true;
              const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getErgebnisliste';
              const params = {
                besuchsbericht_id: this.besuchsbericht.id,
                areasAllowed:      this.$store.getters.benutzerrechte ? this.$store.getters.benutzerrechte.showAreas : null
              };

              // zähle die Kunden
              var that = this;
              axios({
                method: 'GET',
                params: params,
                url:    url
              }).then(function (response) {

                that.ergebnisse  = response.data;
                that.showOverlay = false;
                if (!that.ergebnisse || that.ergebnisse.length < 1) {
                  that.empty      = true;
                } else {
                  that.empty      = false;
                }

              }).catch(error => {
                console.log(error);
                that.showOverlay = false;
              });

            }

          }, // ende getErgebnisse
          leereCache: function () {

            const url      = process.env.VUE_APP_SERVER_URL + '/cache/leereErgebnisseCache'
            const params   = {
              besuchsbericht_id: this.besuchsbericht.id,
              user_id: this.$store.getters.user.id
            }

            console.log('leereCache', url)

            this.showSpinner()
            try {

              const that = this
              axios({
                method: 'GET',
                params: params,
                url: url,
              }).then(function () {

                that.hideSpinner()
                alert(that.$t('message.ergebnisseTable_cache_leer'))

              }).catch(error => {
                console.log(error)
              })

            } catch (e) {
              console.log(e)
            }

          }

        } // ende methods

    }

</script>

<style scoped>

    #modal-berater .img-fluid {
        width: 100%;
    }

</style>
