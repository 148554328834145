<!--
zeige die Area an der ein Traplinked Gerät ist
-->
<template>

  <span v-if="area && area.id && area.name">
     {{ area.name }}
  </span>

</template>
<script>

  // Define a new component
  import axios from "axios";

  export default {

    name: 'AreaOfTraplinkedDevice',
    props: ['value', 'bereich'], // das Traplinked Device
    /*
      Defines the data used by the component
    */
    data: function(){
      return {
        area: null
      }
    },
    watch: {

      value: function () {
        this.init()
      },
      bereich: function () {
        this.init()
      }

    },
    mounted: function () {

      this.init();

    },
    methods: {

      /** hole Area zu einem Traplinked Device */
      init: function () {

        this.area = null
        if (this.bereich) {

          this.area = this.bereich

        } else if (this.value) {

          var customer = this.$store.getters.customer;
          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAreaOfTraplinkedDevice';
          var params   = {
            traplinked_device_id: this.value.id,
            customer_id:          customer.id
          };
          var that    = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data) {

              that.area = response.data;
              let emitme = {
                traplinked_device_id: that.value.id,
                area: that.area
              }
              that.$emit('set-area', emitme);

            }

          }).catch(error => {
            console.log(error);
          });

        }
      }

    }

  }

</script>
