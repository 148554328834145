<!--
aktuellen Status der Traplinked Fallen anzeigen
-->
<template>

  <transition name="fade" mode="out-in">

    <div class="card mt-3"  v-if="traplinkedDevices && traplinkedDevices.length > 0">
      <div class="card-header" @click="showTraplinkedStatus = !showTraplinkedStatus" style="cursor: pointer">
        <div class="row">
          <div class="col-md-8 text-left">
            <h3 class="h4 text-left">{{ $t('message.showHideTraplinkedStatus_24_7') }} ({{ javaDateToDefaultTimeFormat(lastTraplinkedSynchDate) }})</h3>
          </div>
          <div class="col-md-4 text-right">
            <b-icon icon="caret-down" font-scale="2" v-if="!showTraplinkedStatus"></b-icon>
            <b-icon icon="caret-up" font-scale="2" v-else></b-icon>
          </div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="card-body" v-if="showTraplinkedStatus">
          <TraplinkedStatus v-model="traplinkedDevices" />
        </div>
      </transition>
    </div>

  </transition>

</template>
<script>

  // Define a new component
  import axios from "axios";
  import TraplinkedStatus from '@/components/traplinkedbericht/TraplinkedStatus';

  export default {

    name: 'ShowHideTraplinkedStatus',
    components: {
      TraplinkedStatus
    },
    props: [],
    /*
      Defines the data used by the component
    */
    data: function (){
      return {
        /** Traplinked Status ein/ausblenden */
        showTraplinkedStatus: false,
        /** wann wurde das letzte mal mit Traplinked synchronisiert */
        lastTraplinkedSynchDate: null,
        /** hat der Kunde überhaupt devices? */
        traplinkedDevices: null
      }
    },
    mounted: function () {

      this.init();

    },
    methods: {

      init: function () {

        var customer = this.$store.getters.customer;
        if (customer) {

          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedDevices';
          var params  = {
            customer_id: customer.id
          };

          this.traplinkedDevices = null;

          var that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && response.data.traplinkedDevices instanceof Array) {
              that.traplinkedDevices = response.data.traplinkedDevices;
            }
            if (that.traplinkedDevices && that.traplinkedDevices.length > 0) {
              that.getLastTraplinkedSynchDate();
            }

          }).catch(error => {
            console.log(error);
          });

        }

      },
      /** wann wurde das letzte mal mit Traplinked synchronisiert */
      getLastTraplinkedSynchDate: function () {

        var url  = process.env.VUE_APP_SERVER_URL + '/datenbroker/getLastTraplinkedSynchDate';
        var that = this;
        axios({
          method: 'get',
          url: url
        }).then(function (response) {
          that.lastTraplinkedSynchDate = response.data.lastSynch;
        });

      },

    }

  }

</script>
