<template>

    <div>
        <span class="alarmeJeMonat" v-if="chartdata">
           <line-chart
                   v-if="loaded"
                   :chartdata="chartdata"
                   :options="options" />
        </span>
        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage/>
        </div>
    </div>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import LineChart from '@/components/charts/LineChart';
    import SpinnerImage from "@/components/layout/SpinnerImage";

    export default {
      name: 'LineStackedAreaChartAlarmeNachMonaten',
      components: {
        LineChart,
        SpinnerImage
      },
      props: ['besuchsbericht', 'filter', 'traplinkedbericht'],
      data () {
        return {
          loaded: false,
          /** Daten für den Chart */
          chartdata: null,
          options:
            {
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: true,
                text: this.$t('message.charts_relative')
              },
              legend: {
                display: false
              },
              hover: {
                mode: 'index'
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true
                  }
                }],
                yAxes: [{
                  stacked: true,
                  scaleLabel: {
                    display: true
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) { if (Number.isInteger(value)) { return value + '%'; } },
                    stepSize: 10
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                      label += ': ' + tooltipItem.yLabel; // Math.round( tooltipItem.yLabel );
                    }
                    return label + ' %';
                  }
                }
              }
            },
            /** Filter der Charts */
            filterung: {
              /** Filter nach einer Objektkategorie */
              filterObjektkategorie: null,
              /** Filter nach einer Area */
              filterArea: null
            }
        }
      },
      watch: {
        besuchsbericht: function () {
            this.init();
        },
        traplinkedbericht: function () {
            this.init();
        },
        filter: {
          handler: function (newObj) {
            // do stuff
            this.filterung = newObj;
            this.init();
          },
          deep: true
        }
      },
      created () {

        this.init();

      },
      methods: {

        init: async function () {

          this.loaded = false;
          if (this.besuchsbericht || this.traplinkedbericht) {

            // ich brauche hier die Areas von allen Standorten des Benutzers:
            let areasAllowed = null;
            if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
              areasAllowed = this.$store.getters.showAreas.join(',');
            }

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfBesuchsbericht';
            var params  = {
              besuchsbericht_id:        this.besuchsbericht ? this.besuchsbericht.id : null,
              traplinkedbericht_id:     this.traplinkedbericht ? this.traplinkedbericht.id : null,
              type:                     'LINE_STACKED_AREA_CHART_ALARME_NACH_MONATEN',
              filterObjektkategorie:    this.filterung ? this.filterung.filterObjektkategorie : null,
              filterArea:               this.filterung ? this.filterung.filterArea : null,
              areasAllowed:             areasAllowed,
              auftragsart:              this.filterung ? JSON.stringify(this.filterung.auftragsart) : null
            };

            var that = this;
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              if (response.data) {

                that.chartdata = response.data.chartdata;
                if (that.chartdata) {

                  var datasets = that.chartdata.datasets;
                  if (datasets) {
                    console.info('no datasets', datasets);
                  } else {
                    that.chartdata = null;
                    that.$emit('set-no-data', true);
                  }

                } else { // end if that.chartdata
                  that.$emit('set-no-data', true);
                }

              }
              that.loaded = true;

            }).catch(error => {
              console.log(error);
              that.loaded = true;
              that.$emit('set-no-data', true);
            });
          }

        }

      }

    }

</script>
