<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.benutzerSuche_h1') }}</h1>
          </div>
        </div>

      </div>

      <div class="card mt-3">
        <div class="card-body">

            <v-select
                    label="value"
                    :options="options"
                    @search="onSearch"
                    :filterable="false"
                    v-model="selected"
                    :placeholder="$t('message.benutzerSuche_h1')"
                    @input="doSelected">

                <template  v-slot:no-options="{ search, searching }">
                    <template v-if="searching && search.length > 3">
                        {{ $t('message.benutzerSuche_no_user') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>{{ $t('message.benutzerSuche_4digits') }} ...</em>
                </template>

                <template v-slot:option="option">
                    <span class="optionName" v-if="option.nachname">
                        {{ option.vorname }} {{ option.nachname }} {{ option.username }}
                    </span>
                    <span class="optionName" v-else>
                      {{ option.name }} {{ option.username }}
                    </span>
                </template>

                <template #selected-option="{ vorname, nachname, username, name }">
                    <div style="display: flex; align-items: baseline;">
                        {{ vorname }} {{ nachname }} {{ name }} {{ username }}
                    </div>
                </template>

            </v-select>

            <hr />

            <transition name="fade" mode="out-in">
                <benutzer-liste v-if="benutzerliste" v-model="benutzerliste" />
            </transition>

        </div>
      </div>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import vSelect from 'vue-select'
  import BenutzerListe from '@/components/benutzer/BenutzerListe'

  export default {
    name: 'BenutzerSuche',
    components: {
      vSelect,
      BenutzerListe
    },
    data () {
      return {
        /** aus Select ausgewählter Benutzer */
        selected: null,
        /** Optionen/Aufträge zur Auswahl */
        options: [],
        /** AJAX Suche steuern, damit keine Race Condition entsteht */
        lastRequest: null,
        benutzerliste: null,
      }
    },
    mounted () {
      // Umami Tracking aufrufen
      this.doTracking(this.$route.name)
    },
    methods: {

      /** AJAX Auftrag Suche */
      onSearch: function (search, loading) {

        //erst ab einer Zeichenlänge von 4 Zeichen mit der Suche starten
        if (search && search.length > 3) {

          loading(true)
          console.log('Search Benutzer: ' + search)
          var that = this
          // GET request
          if (this.lastRequest) {
            clearTimeout(this.lastRequest)
          }
          this.lastRequest = setTimeout(function() {

            var url = process.env.VUE_APP_SERVER_URL + '/biotechUser/searchBiotechUser'
            var params = {
              term: search
            }
            axios ({
              method:   'GET',
              params:   params,
              url:      url,
            }).then((response) => {

              console.log('Suchergebnis ' + response.data)
              that.options = response.data
              loading(false)

            })

          }, 300)

        }

      },
      /**
       * ein Auftrag wurde vom Benutzer ausgewählt
       * @param val Auftrag
       */
      doSelected: function (val) {

        console.log('Benutzer wurde ausgewählt ' + (val ? val.id : ''))
        this.selected = val
        this.benutzerliste = [this.selected]

      }

    }
  }

</script>
