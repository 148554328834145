<template>

    <div class="planlist">

      <div class="row mt-5">

        <div class="col-md-2">
          <router-link to="/Plan"
                       class="btn btn-outline-primary"
                       href="#">
            <b-icon icon="caret-left" />&nbsp;{{ $t('message.default_zurueck') }}
          </router-link>
        </div>
        <div class="col-md-10 text-right">

          <b-button variant="outline-primary" @click="neuenPlanHochladen()">
            <b-icon icon="upload" />&nbsp;&nbsp;{{ $t('message.planList_neu') }}
          </b-button>

        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="content_title">

            <h1>{{ $t('message.plan_plaene') }}</h1>

            <!-- Plan Liste -->
            <div id="planliste" v-if="plaene">

              <!-- Ergebnis Tabelle Filtern -->
              <div id="filter" class="row border-top mb-3 pt-3">

                <div :class="'col-md-11'">

                  <b-input-group size="sm">

                    <b-form-input
                        v-model="keyword"
                        type="search"
                        id="filterInput"
                        :placeholder="$t('message.positionen_filter')">

                    </b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                      </b-input-group-append>

                    </b-input-group>

                </div>
                <div class="col-md-1 text-right">

                  <b-form-group horizontal label="" class="">
                    <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                  </b-form-group>

                </div>

              </div>

              <b-table
                  id="plan-table"
                  striped
                  small
                  :fields="fields"
                  :items="items"
                  :keyword="keyword"
                  :current-page="currentPage"
                  :per-page="perPage"
                  hover
                  :responsive="true">

                <template v-slot:cell(dateCreated)="row">
                  {{ javaDateToDateInputFieldDate(row.item.dateCreated) }}
                </template>
                <template v-slot:cell(revision)="row">
                  <span v-if="row.item.revisionsDatum">
                    {{ javaDateToDateInputFieldDate(row.item.revisionsDatum) }}
                  </span>
                </template>
                <template v-slot:cell(revisionUser)="row">
                  <span v-if="row.item.revisionUser">
                    <print-user v-model="row.item.revisionUser.id" />
                  </span>
                </template>
                <template v-slot:cell(image)="row">
                  <show-plan-thumb :plan="row.item" />
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button variant="danger" @click="deletePlan(row.item)" class="mr-2" size="sm">
                    <b-icon icon="trash"></b-icon>&nbsp;{{ $t('message.default_loeschen') }}
                  </b-button>
                  <b-button @click="editPlan(row.item)">
                    <b-icon icon="pencil"></b-icon>&nbsp;{{ $t('message.default_aendern') }}
                  </b-button>
                </template>

                <template v-slot:row-details="row">
                  <div class="m-3">
                    <label>{{ $t('message.planList_bemerkung_freigabe') }}: </label> {{ row.item.revisionBemerkung }}
                  </div>
                </template>

              </b-table>

              <b-row>
                <b-col md="12" class="my-1">
                  <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
                </b-col>
              </b-row>

            </div>
            <!-- noch keine Pläne vorhanden -->
            <div v-else-if="hasNoPlan" class="mt-5">
              <b-alert variant="warning" show>{{ $t('message.plan_empty') }}</b-alert>
            </div>
            <!-- pläne laden -->
            <b-card
                v-else
                :title="$t('message.planList_loading') + ' ...'"
                style="max-width: 20rem;"
                class="mb-2">
              <b-card-text class="text-center">
                <SpinnerImage />
              </b-card-text>
            </b-card>

          </div>
        </div>
      </div>

      <!-- Model zum Hochladen eines Plans -->
      <b-modal id="modal-upload" :title="modalUploadTitle" size="lg" hide-footer>

        <div class="my-4">

          <b-alert variant="info" show v-if="editMode">
            {{ $t('message.planList_austauschen') }}
          </b-alert>
          <b-alert variant="info" show v-else>
            {{ $t('message.planList_p') }}
          </b-alert>

          <b-form enctype="multipart/form-data">

            <b-form-group id="bezeichnung-label"
                          :label="$t('message.plan_bezeichnung') + ':*'"
                          label-for="bezeichnung">
              <b-form-input
                  id="bezeichnung"
                  v-model.trim="bezeichnung"
                  maxlength="255"
                  required
                  :placeholder="$t('message.planList_bezeichnung')"
              />
            </b-form-group>

            <!-- Plan freigeben -->
            <span v-if="editMode">

               <b-form-checkbox
                   id="checkbox-plan-freigegeben"
                   v-model="freigegeben"
                   name="checkbox-plan-freigegeben"
                   value="true"
                   unchecked-value="false">
                {{ $t('message.planList_ueberprueft') }}
              </b-form-checkbox>
              <b-form-textarea
                  id="textareaRevisionBemerkung"
                  v-model="revisionBemerkung"
                  :placeholder="$t('message.plan_bemerkungen') + ' ...'"
                  class="mt-2 mb-2"
                  rows="3" />

            </span>

            <b-form-checkbox
                id="leerer-plan"
                v-model="useEmpty"
                name="useEmpty"
                class="mb-3"
                value="true"
                unchecked-value="false">
              {{ $t('message.planList_ohne_file') }}
            </b-form-checkbox>

            <b-form-group id="file-label"
                          :label="$t('message.planList_datei') + ':*'"
                          label-for="file"
                          v-if="!editMode && useEmpty == 'false'">

              <b-form-file
                  id="file"
                  required
                  v-model="file"
                  :state="Boolean(file)"
                  :placeholder="$t('message.planList_upload') + ' ...'"
                  :drop-placeholder="$t('message.fileUpload_einfuegen')">
              </b-form-file>

            </b-form-group>

            <b-button type="button" variant="primary" @click="saveFile()" :disabled="!editUploadFormValid" v-if="editMode">
              <b-icon icon="check2-square"></b-icon>&nbsp;{{ $t('message.default_speichern') }}
            </b-button>
            <b-button type="button" variant="primary" @click="saveNewFile()" :disabled="!uploadFormValid" v-else>
              <b-icon icon="upload"></b-icon>&nbsp;{{ $t('message.default_speichern') }}
            </b-button>

          </b-form>

        </div>

      </b-modal>

    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import ShowPlanThumb from '@/components/plan/ShowPlanThumb';
  import SpinnerImage from '@/components/layout/SpinnerImage';
  import PrintUser from '@/components/benutzer/PrintUser';
  import bootbox from 'bootbox';

  export default {
    name: 'PlanList',
    components: {
      ShowPlanThumb,
      SpinnerImage,
      PrintUser
    },
    data () {
      return {
        /** Liste mit Plänen des Kunden */
        plaene: null,
        /** Bezeichnung eines neuen Plans */
        bezeichnung: null,
        /** File der hochgeladen werden soll */
        file: null,
        /** Spalten der Planliste */
        fields: [
          {
            key: 'image',
            label: this.$t('message.planList_thumbnail'),
            sortable: false
          },
          {
            key: 'bezeichnung',
            label: this.$t('message.plan_bezeichnung'),
            sortable: true
          },
          {
            key: 'dateCreated',
            label: this.$t('message.planList_hochgeladen_am'),
            sortable: true
          },
          {
            key: 'revision',
            label: this.$t('message.planList_freigegeben_am'),
            sortable: true
          },
          {
            key: 'revisionUser',
            label: this.$t('message.planList_freigegeben_von'),
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: 'text-right'
          }
        ],
        /** Filter Query String für Suche nach Plänen */
        keyword: '',
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 50,
        /** Pagination der Ergenistabelle */
        currentPage: 1,
        /** Titel des Modals für Plan Upload */
        modalUploadTitle: this.$t('message.planList_neu'),
        /** der Plan der geändert werden soll */
        editMode: null,
        /** wenn es noch keine Pläne gibt */
        hasNoPlan: null,
        /** Plan wurde freigegeben */
        freigegeben: 'false', // String da value der Checkbox
        /** Anmerkung zur Plan Freigabe */
        revisionBemerkung: null,
        /** erzeuge einen leeren Plan oder lade einen hoch */
        useEmpty: 'false'
      }
    },
    mounted() {

      this.init();

      var that = this;
      this.$root.$on('bv::hide::modal', (bvEvent, modalId) => {

        if (modalId == 'modal-upload') {
          that.file         = null;
          that.bezeichnung  = null;
          that.editMode     = null;
        }

      })

    },
    computed: {

      /**
       * prüft ob die UPload Form ausgefüllt wurde
       */
      uploadFormValid: function () {

        if (!this.file && this.useEmpty == 'false') return false;
        if (!this.bezeichnung) return false;
        return true;

      },
      /** prüft ob die Upload Form valide ist für eine Plan Änderung */
      editUploadFormValid: function () {

        if (!this.bezeichnung) return false;
        return true;

      },
      items: function () {

        var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

        var selectedRows = lowercase
            ? this.plaene.filter( item =>

                (item.bezeichnung && item.bezeichnung.toLowerCase().includes(lowercase))

            )
            : this.plaene;

        return selectedRows;

      },
      totalRows: function () {
        return this.items.length;
      }

    },
    methods: {

      /**
       * Modal öffnen um neuen Plan hochzuladen
       */
      neuenPlanHochladen: function () {

        this.bezeichnung        = null;
        this.file               = null;
        this.modalUploadTitle   = this.$t('message.planList_hochladen');
        this.editMode           = null;
        this.freigegeben        = 'false';
        this.revisionBemerkung  = null;
        this.useEmpty           = 'false';

        this.$bvModal.show('modal-upload');

      },
      /** holt alle Pläne des Kunden */
      init: function () {

        if (this.$store.getters.customer) {

          var that = this;
          var url = process.env.VUE_APP_SERVER_URL + '/plan/index';
          var params = {
            'bezeichnung': this.bezeichnung,
            'cardcode':    this.$store.getters.customer.cardcode,
            'mandant':     this.$store.getters.customer.biotechCountry.name
          };
          // speichere File in Filesystem
          axios({
            method: 'GET',
            url: url,
            params: params,
            withCredentials: true
          }).then(function (daten) {

            if (daten.data) {

              that.plaene = daten.data;
              if (!that.plaene || that.plaene.length < 1) {
                that.hasNoPlan = true;
              } else if (that.plaene.length > 0) {

                that.hasNoPlan = false;

                // zeige detailsShowing wenn Bemerkung vorhanden  ist
                var newlist = [];
                for (var i = 0; i < that.plaene.length; i++) {
                  if (that.plaene[i].revisionBemerkung) {
                    that.plaene[i]._showDetails = true;
                  }
                  newlist.push(that.plaene[i]);
                }
                that.plaene = newlist;

              }

            }

          }).catch(error => {

            console.log('Fehler beim Lesen der Pläne: ' + error);

          });

        }

      },
      /** Plan löschen */
      deletePlan: function (plan) {

        var that = this;
        var text = this.$t('message.planList_loeschen').replace('{0}', plan.bezeichnung);
        bootbox.confirm(text, function (result) {
          if (result) {

            var url     = process.env.VUE_APP_SERVER_URL + '/plan/delete';
            var params  = {
              plan_id: plan.id
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              if (response.data.id) {
                var newlist = [];
                for (var i = 0; i < that.plaene.length; i++) {
                  if (that.plaene[i].id != plan.id) {
                    newlist.push(that.plaene[i]);
                  }
                }
                that.plaene = newlist;
              }

            });

          }
        });

      },
      /** Modal öffnen um Plan zu ändern */
      editPlan: function (plan) {

        // Zuerset öffnen, dann Werte setzen
        this.$bvModal.show('modal-upload');

        this.bezeichnung        = plan.bezeichnung;
        this.file               = null;
        this.modalUploadTitle   = 'Plan ändern';
        this.editMode           = plan;
        this.freigegeben        = plan.revisionsDatum ? 'true' : 'false';
        this.revisionBemerkung  = plan.revisionBemerkung;

      },
      /**
       * Plan ändern
       */
      saveFile: function () {

        var formData = new FormData();
        if (this.file) {
          formData.append('file', this.file);
        }

        this.showSpinner();

        // File an Server senden
        var that = this;
        var url = process.env.VUE_APP_SERVER_URL + '/plan/edit';
        var params = {
          'bezeichnung':        this.bezeichnung,
          'plan_id':            this.editMode.id,
          'revisionBemerkung':  this.revisionBemerkung,
          'freigegeben':        this.freigegeben
        };
        // speichere File in Filesystem
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true,
          contentType: 'multipart/form-data',
          data: formData
        }).then(function (daten) {

          console.log('File erfolgreich geändert: ' + daten);

          try {
            if (daten.data.id) {

              var newlist = [];
              for (var i = 0; i < that.plaene.length; i++) {
                if (that.plaene[i].id == that.editMode.id) {
                  if (daten.data.revisionBemerkung) {
                    daten.data._showDetails = true;
                  }
                  newlist.push(daten.data);
                } else {
                  newlist.push(that.plaene[i]);
                }
              }
              that.plaene = newlist;

              that.hideSpinner();

            }
          } catch(e) {
            console.log('Fehler beim Updaten der Planliste ' + e);
          }

        }).catch(error => {

          console.log('Upload Error: ' + error)
          bootbox.alert(this.$t('message.planList_error'))
          that.hideSpinner()

        });

        this.$bvModal.hide('modal-upload');

      },
      /**
       * neuen Plan speichern
       */
      saveNewFile: function () {

        console.log('save new file ' + this.file);

        try {

          var formData = new FormData();
          formData.append('file', this.file);

          this.showSpinner();

          // File an Server senden
          var that = this;
          var url = process.env.VUE_APP_SERVER_URL + '/plan/upload';
          var params = {
            'bezeichnung': this.bezeichnung,
            'cardcode':    this.$store.getters.customer.cardcode,
            'mandant':     this.$store.getters.customer.biotechCountry.name,
            'useEmpty':    this.useEmpty
          };
          // speichere File in Filesystem
          axios({
            method: 'post',
            url: url,
            params: params,
            withCredentials: true,
            contentType: 'multipart/form-data',
            data: formData
          }).then(function (daten) {

            console.log('File erfolgreich hochgeladen: ', daten)

            if (daten.data.id) {

              if (!that.plaene) that.plaene = [];
              that.plaene.unshift(daten.data);
              that.$bvModal.hide('modal-upload');
              that.hideSpinner();

            }

          }).catch(function(error) {

            console.log('Upload Error: ', error)
            that.$bvModal.hide('modal-upload')
            bootbox.alert(that.$t('message.planList_error'))
            that.hideSpinner()

          })

        } catch (e) {
          console.log('Error Upload new file ' + e);
        }

      } // end upload new file

    }
  }

</script>