<!--
zeige die Position an der ein Traplinked Gerät ist
-->
<template>

  <span v-if="position && position.id && position.positionsNr">
    <b-overlay :show="showOverlay" rounded="sm">
      <ShowPositionInMap :position-id="position.id" :position-nr="position.positionsNr" />
      &nbsp;
       {{ position.positionsBesch }}

    </b-overlay>
  </span>

</template>
<script>

  // Define a new component
  import axios from 'axios'

  export default {

    name: 'PositionOfTraplinkedDevice',
    components: {
      ShowPositionInMap: () => import('@/components/plan/ShowPositionInMap'),
    },
    props: ['value', 'pos'], // das Traplinked Device
    data: function () {
      return {
        position: null,
        /** Overlay anzeigen während Position geladen wird */
        showOverlay: false
      }
    },
    watch: {

      value: function () {
        this.init()
      },
      pos: function () {
        this.init()
      }

    },
    mounted: function () {

      this.init();

    },
    methods: {

      /** hole Position zu einem Traplinked Device */
      init: function () {

        this.position = null;

        if (this.pos) {

          this.position = this.pos

        } else if (this.value) {

          // zeige overlay-loading:
          this.showOverlay = true;
          var customer = this.$store.getters.customer;
          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionOfTraplinkedDevice';
          var params   = {
            traplinked_device_id: this.value.id,
            customer_id:          customer.id
          };
          var that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data) {

              that.position = response.data;
              let emitme = {
                traplinked_device_id: that.value.id,
                position: that.position
              }
              console.log('emit position of traplinked_device_id ' + that.value.id + ' position = '  + that.value.id);
              that.$emit('set-position', emitme);

            }
            that.showOverlay = false;

          }).catch(error => {
            console.log(error);
            that.showOverlay = false;
          });

        }
      }

    }

  }

</script>
