<template>

  <b-overlay :show="loading" rounded="sm">
    <b-card title="" :aria-hidden="loading ? 'true' : null" class="plan-thumbnail">

      <img :src="base64" class="img-thumbnail" />

    </b-card>
  </b-overlay>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    // Define a new component
    export default {
        name: 'ShowPlanThumb',
        props: {

            /** Plan dessen Bild angezeigt werden soll */
            plan: {
                type: Object,
                required: true
            }

        },
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              base64: null,
              /** auf false setzen wenn base 64 geladen wurde */
              loading: true
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          plan: function () {
            this.base64  = null;
            this.loading = true;
            this.init();
          }

        },
        methods: {

          init: function () {

            if (this.plan) {

              var that    = this;
              var url     = process.env.VUE_APP_SERVER_URL + '/plan/getBase64';
              var params  = {
                plan_id: this.plan.id
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(function (response) {

                that.base64  = response.data.base64;
                that.loading = false;

              }).catch(function () {
                that.loading = false;
              });

            }

          }

        }

    }

</script>

<style scoped>

  .img-thumbnail {
    max-width: 200px;
  }
  .plan-thumbnail{
    min-height: 100px;
  }

</style>
