//////////////////////////////////////////////////////////////////
// Ready translated locale messages
export const messages = {
  de: {
    message: {

      default_speichern: 'speichern',
      default_loeschen: 'löschen',
      default_absenden: 'absenden',
      default_abbrechen: 'abbrechen',
      default_weiter: 'weiter',
      default_bearbeiten: 'bearbeiten',
      default_anzeigen: 'anzeigen',
      default_ok: 'OK',
      default_aendern: 'ändern',
      default_zurueck: 'zurück',
      default_bezeichnung: 'Bezeichnung',

      welcome_browser_veraltet: 'Ihr Browser ist veraltet!',
      welcome_browser_aktualisieren: 'Aktualisieren Sie bitte Ihren Browser um diese Anwendung sicher, sorgenfrei und fehlerfrei benutzen zu können.',
      welcome_browser_herunterladen: 'Aktuellen Browser herunterladen',
      welcome_neue_funktionen: 'Neue Funktionen:',
      welcome_neue_funktionen_list: '<li><strong>Export:</strong> Export Ihrer Daten als Excel Dokument</li><li><strong>Benachrichtigungen:</strong> Benachrichtigungen von Biotech für Ihre Standorte</li>',

      team_h1: 'Ihr Biotech Team',
      team_vertrieb: 'Ihr Ansprechpartner für Vertrieb',
      team_kundenbetreuer: 'Ihre Kundenbetreuer',

      printPositionen_h1: 'Einrichtungsliste/Positionen',
      printPositionen_kundennummer: 'Kundennummer',
      printPositionen_bereich: 'Bereich',
      printPositionen_position_nummer: 'Position Nummer',
      printPositionen_position: 'Position',
      printPositionen_objekte: 'Indikatoren',

      printMaengel_h1: 'Bearbeitungsliste',
      printMaengel_nummer: 'Nummer',
      printMaengel_datum: 'Datum',
      printMaengel_kurzbeschreibung: 'Mangelbeschreibung',
      printMaengel_beschreibung: 'Details',
      printMaengel_zustaendig: 'Zuständig',
      printMaengel_status: 'Status',
      printMaengel_foto: 'Foto',

      printErgebnisliste_h1: 'Ergebnisliste',
      printErgebnisliste_auftrag_nr: 'Auftrag',
      printErgebnisliste_vom: 'vom',
      printErgebnisliste_datum_uhrzeit: 'Datum/Uhrzeit',
      printErgebnisliste_aufgabe: 'Aufgabe',
      printErgebnisliste_objekt: 'Indikator',
      printErgebnisliste_ergebnis: 'Ergebnis',
      printErgebnisliste_bemerkung: 'Bemerkung',
      printErgebnisliste_material: 'Artikel',
      printErgebnisliste_menge: 'Menge',
      printErgebnisliste_einheit: 'Einheit',
      printErgebnisliste_berater: 'User',

      positionen_h1: 'Einrichtungsliste/Indikatoren',
      positionen_loading: 'Positionen werden geladen und aufbereitet. Bitte um Geduld ...',
      positionen_excel: 'Excel',
      positionen_pdf: 'PDF',
      positionen_drucken: 'drucken',
      positionen_filter: 'Filter',
      positionen_daten_laden: 'Daten werden geladen ...',
      positionen_empty: 'Es sind keine Positionen vorhanden oder Sie haben nicht die Benutzerrechte um Positionen sehen zu können.',

      plan_plaene: 'Pläne',
      plan_plaene_verwalten: 'Pläne verwalten',
      plan_freigeben: 'Plan freigeben',
      plan_freigeben_ueberprueft: 'Plan überprüft und freigegeben',
      plan_freigeben_von: 'freigegeben von',
      plan_freigeben_am: 'am',
      plan_bemerkungen: 'Anmerkungen',
      plan_maengel_einzeichnen: 'Mängel einzeichnen',
      plan_positionen: 'Positionen',
      plan_positionen_alle: 'Alle Positionen',
      plan_positionen_filter: 'Positionen gefiltert',
      plan_markierungen: 'Markierungen',
      plan_markierung_neu: 'Neue Markierung',
      plan_einstellungen: 'Einstellungen',
      plan_bearbeitungsmodus: 'Bearbeitungsmodus',
      plan_aktiv: 'aktiv',
      plan_inaktiv: 'inaktiv',
      plan_verwalten: 'Pläne verwalten',
      plan_empty: 'Es wurden noch keine Pläne hochgeladen.',
      plan: 'Plan',
      plan_position_saved: 'Die Position wurde erfolgreich bei Ihrem Plan gespeichert.',
      plan_position_loading: 'Positionen werden geladen',
      plan_bezeichnung: 'Bezeichnung',
      plan_bezeichnung_placeholder: 'Bezeichnung Ihrer Markierung',
      plan_farbe: 'Farbe',
      plan_transparenz: 'Transparenz',
      plan_rahmen_farbe: 'Rahmen-Farbe',
      plan_starten: 'starten',
      plan_position_details: 'Position Details',
      plan_markierung_zeichnen: 'Markierung einzeichnen',
      plan_freigabe_ok: 'Planfreigabe erfolgreich gespeichert',
      plan_freigabe_error: 'Fehler beim Speichern der Planfreigabe. Bitte versuchen Sie es erneut oder wenden Sie sich an einen System Administrator.',
      plan_position_fehler: 'Fehler beim Einfügen der Position oder des Mangels. Bitte wählen Sie aus der Liste aus und ziehen Sie das Symbol in den Plan',
      plan_mangel_nr: 'Mangel Nr.',
      plan_position_gespeichert: 'Die Position<br/>{0}<br/>wurde erfolgreich im Plan<br/>{1}<br/>gespeichert.',
      plan_mangel_details: 'Mangel Details',
      plan_neue_markierung: 'Neue Markierung anlegen',
      plan_markierung_loeschen: 'Wollen Sie die Markierung {0} wirklich löschen?',
      plan_markierung_aendern: 'Markierung ändern',
      plan_maengel_load_error: 'Es gab einen Fehler beim Laden der Mängel. Bitte laden Sie den Plan erneut.',
      plan_position_load_error: 'Es gab einen Fehler beim Laden der Positionen. Bitte laden Sei den Plan neu.',
      plan_position_load_error_no_objekt: 'Die Position hat kein Objekt/Indikator und kann daher nicht eingezeichnet werden.',
      plan_mangel_erfolgreich: 'Der Mangel<br/><br/>{0}<br/><br/>wurde erfolgreich in Ihrem Plan<br/>{1}<br/>gespeichert.',

      newPassword_h1: 'Neues Passwort eingeben',
      newPassword_h1_2: 'Passwort festlegen',
      newPassword_pleaseinsert: 'Geben Sie bitte ein neues Passwort ein.',
      newPassword_festlegen: 'Legen Sie bitte ihr Passwort fest.',
      newPassword_description: 'Bitte achten Sie darauf, dass das Passwort mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten muss und mindestens 8 Zeichen lang sein muss.',
      newPassword_code_error: 'Der übergebene Code existiert nicht oder das Passwort zu ihrem Konto wurde bereits geändert.',

      login: 'einloggen',
      login_error: 'Es konnte kein Benutzer mit Ihren Login Daten gefunden werden!',
      login_wartung: 'BioTech Analytics wird gerade gewartet. Bitte versuchen Sie es später nochmals. Danke für Ihr Verständnis.',
      login_username: 'Benutzername/E-Mail Adresse',
      login_username_ph: 'Ihre E-Mail-Adresse',
      login_password: 'Passwort',
      login_password_ph: 'Ihr Passwort',
      login_password_vergessen: 'Passwort vergessen',

      lieferscheine_h1: 'Lieferscheine',
      lieferscheine_pdf: 'PDF öffnen',
      lieferscheine_kw: 'KW',
      lieferscheine_thema: 'Thema',

      kundenliste_h1: 'Standortliste',
      kundenliste_suchen: 'Standort suchen',
      kundenliste_not_found: 'es konnte kein Standort mit {0} gefunden werden',
      kundenliste_austria: 'Österreich',
      kundenliste_germany: 'Deutschland',

      forgotPassword_h1: 'Passworthilfe',
      forgotPassword_p: 'Geben Sie bitte den Benutzernamen (E-Mail Adresse) ein der mit ihrem Konto verbunden ist.',
      forgotPassword_username_invalid: 'Geben Sie bitte ihren Benutzernamen (E-Mail Adresse) ein.',

      export_h1: 'Export',
      export_p: 'Bitte beachten Sie, dass bei einem Export über einen längeren Zeitraum große Datenmengen aufbereitet werden. Diese Berechnung kann daher mehrere Minuten dauern.',
      export_startdatum: 'Startdatum Ihres Exports.',
      export_select_datum: 'bitte Datum wählen',
      export_validationab: 'Bitte Datum ab eingeben und auf das korrekte Datumsformat (JJJJ-MM-TT) achten.',
      export_enddatum: 'Enddatum Ihres Exports.',
      export_validationbis: 'Bitte bis-Datum eingeben und auf das korrekte Datumsformat (JJJJ-MM-TT) achten. Das bis- Datum muss nach dem ab- Datum ab liegen.',
      export_start: 'Export starten',
      export_berechnen: 'Daten werden berechnet',
      export_keine_daten: 'Für die getroffene Auswahl sind keine Ergebnisse vorhanden. Bitte überprüfen Sie Ihre Eingaben.',
      export_error: 'Leider ist ein Fehler beim Abrufen der Daten entstanden.',
      export_datum_ab: 'Datum ab',
      export_datum_bis: 'Datum bis',
      export_dateiformat: 'Dateiformat',
      export_dateiformat_des: 'Dateiformat Ihres Exports.',

      dashboard_h1: 'Biotech Analytics Dashboard',
      dashboard_h3: 'Übersicht alle Standorte',
      dashboard_uebersicht_standort: 'Übersicht Standort',
      dashboard_uebersicht: 'Übersicht',
      dashboard_no_data: 'Derzeit stehen in Biotech Analytics noch keine ausreichenden Daten zur Verfügung.',

      customerNotifications_h1: 'Postbox',
      customerNotifications_alle: 'alle',
      customerNotifications_alle_gelesen: 'alle als gelesen markieren',
      customerNotifications_alle_gelesen_erfolg: 'Perfekt! Sie haben alle Benachrichtigungen gelesen und bearbeitet.',
      customerNotifications_prioritaet: 'Priorität',
      customerNotifications_nachricht: 'Nachricht',
      customerNotifications_status: 'Bearbeitungs-Status',
      customerNotifications_status_offen: 'offen',
      customerNotifications_status_bearbeitet: 'bearbeitet',
      customerNotifications_status_abgeschlossen: 'abgeschlossen',
      customerNotifications_status_not_editable: 'kann nur von einem Biotech Mitarbeiter gesetzt werden',
      customerNotifications_notizen: 'Antworten',
      customerNotifications_notiz_add: 'Antwort erfassen',
      customerNotifications_standort: 'Standort',
      customerNotifications_betreff: 'Betreff',
      customerNotifications_delete_sure: 'Wollen Sie die Nachricht {0} wirklich löschen?',
      customerNotifications_ungelesen: 'Es sind {0} ungelesene Nachrichten in Ihrer Postbox vorhanden.',
      customerNotifications_ungelesen_1: 'Es ist eine ungelesene Nachricht in Ihrer Postbox vorhanden.',
      customerNotifications_erfolgreich: 'Ihre Nachricht wurde erfolgreich versendet.',
      customerNotifications_nachricht_von_biotech: 'Nachricht von Ihrem Bio-Tech Team.',
      customerNotifications_247_bilder_pruefen: 'Bilder überprüfen',
      customerNotifications_fuer_alle_aktivieren: 'für alle Standorte aktivieren',
      customerNotifications_fuer_alle_deaktivieren: 'für alle Standorte deaktivieren',
      customerNotifications_fuer_alle_aktivieren_ok: 'Die Benachrichtigung wurde für alle Standorte aktiviert.',
      customerNotifications_fuer_alle_deaktivieren_ok: 'Die Benachrichtigung wurde für alle Standorte deaktiviert.',
      customerNotifications_fuer_alle_deaktivieren_fail: 'Fehler beim Deaktivieren der Benachrichtigung. Bitte wenden Sie sich an einen BioTech Mitarbeiter.',

      changePassword_h1: 'Passwort ändern',
      changePassword_existing: 'bestehendes Passwort',
      changePassword_existing_error: 'Das eingegebene Passwort ist nicht korrekt.',
      changePassword_new_pass_p: 'Geben Sie bitte ein neues Passwort ein. Bitte achten Sie darauf, dass das Passwort mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten muss und mindestens 8 Zeichen lang sein muss.',
      changePassword_new_pass_p2: 'Geben Sie bitte ein neues Passwort ein. Bitte achten Sie darauf, dass das Passwort mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten muss und mindestens 16 Zeichen lang sein muss.',

      besuchsbericht_monitoringbesuche: 'Monitoringbesuche',
      besuchsbericht_einzelauftrag: 'Andere',
      besuchsbericht_monatsberichte: 'Monatsberichte',
      besuchsbericht_select: 'Bitte eine Auftragsart wählen.',
      besuchsbericht_auftrag_suchen: 'Auftrag suchen',
      besuchsbericht_monatsbericht: 'Monatsbericht',
      besuchsbericht: 'Besuchsbericht',
      besuchsbericht_1bearbeiterMangel: 'und 1 bearbeiteter Mangel',
      besuchsbericht_MbearbeiterMangel: 'und {0} bearbeitete Mängel',
      besuchsbericht_1unerledigter: 'unerledigter Mangel vorhanden.',
      besuchsbericht_Munerledigter: 'unerledigte Mängel vorhanden.',
      besuchsbericht_thereAre: 'Es sind ',
      besuchsbericht_thereIs: 'Es ist ',
      besuchsbericht_mangelVorhanden: ' erledigter Mangel vorhanden.',
      besuchsbericht_maengelVorhanden: ' erledigte Mängel vorhanden.',
      besuchsbericht_vorheriger_von: 'zeige vorherigen Auftrag vom ',
      besuchsbericht_naechsten_von: 'zeige nächsten Auftrag vom ',
      besuchsbericht_keiner: 'Leider steht kein aktueller Besuchsbericht in Biotech Analytics zur Verfügung.',

      benutzerSuche_h1: 'Benutzer suchen',
      benutzerSuche_no_user: 'Keine Benutzer gefunden mit Suchbegriff',
      benutzerSuche_4digits: 'Bitte geben Sie mindestens 4 Zeichen ein',

      benutzerOfCustomer_h1: 'Benutzerliste',

      benutzer_h1: 'Benutzer',
      benutzer_add: 'Benutzer hinzufügen',
      benutzer_standorte: 'Standorte',
      benutzer_no_standorte: 'Ihrem Benutzer wurden keine Standorte zugewiesen!',
      benutzer_benutzername: 'Benutzername',
      benutzer_valid_email: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
      benutzer_vorname: 'Vorname',
      benutzer_nachname: 'Nachname',
      benutzer_name: 'Name',
      benutzer_aktiv: 'aktiv',
      benutzer_inaktiv: 'inaktiv',
      benutzer_exists: 'Es gibt bereits einen Benutzer mit der E-Mail-Adresse. Fahren Sie fort wenn Sie {0} erlauben möchten, auf die ausgewählten Standorte zuzugreifen.',
      benutzer_delete: 'Wollen Sie den Benutzer {0} wirklich löschen?',

      benachrichtigungen_h1: 'Benachrichtigungen',
      benachrichtigungen_vertragszuteilung: 'Vertragszuteilung',
      benachrichtigungen_mailVerstaendigung: 'Verständigungen für Ihre Standorte',
      benachrichtigungen_konf: 'Konfigurieren Sie ob Sie eine Benachrichtigung erhalten wollen, wenn sie einem neuen Vertrag zugeteilt wurden',
      benachrichtigungen_konf2: 'Konfigurieren Sie ob Sie eine Benachrichtigung erhalten wollen, wenn ein neuer Besuchsbericht verfügbar ist',
      benachrichtigungen_label: 'E-Mail Benachrichtigung bei neuem Besuchsbericht',
      benachrichtigungen_label_app: 'App Push Benachrichtigung bei neuem Besuchsbericht',
      benachrichtigungen_247_meldungen: 'Biotech247 Meldungen',
      benachrichtigungen_app: 'App Push Benachrichtigung bei neuem Vertrag',
      benachrichtigungen_mail: 'E-Mail Benachrichtigung bei neuem Vertrag',
      benachrichtigungen_besuchsbericht_meldungen: 'Besuchsbericht Meldungen',
      benachrichtigungen_alle_on: 'E-Mail alle aktivieren',
      benachrichtigungen_alle_on_confirm: 'Sollen wirklich für alle Ihre Standorte alle E-Mail-Benachrichtungen aktiviert werden?',
      benachrichtigungen_alle_off: 'E-Mail alle deaktivieren',
      benachrichtigungen_alle_off_confirm: 'Sollen wirklich für alle Ihre Standorte alle E-Mail-Benachrichtungen deaktiviert werden?',
      benachrichtigungen_alle_app_on: 'App Push alle aktivieren',
      benachrichtigungen_alle_app_on_confirm: 'Sollen wirklich für alle Ihre Standorte alle App-Push-Benachrichtungen aktiviert werden?',
      benachrichtigungen_alle_app_off: 'App Push alle deaktivieren',
      benachrichtigungen_alle_app_off_confirm: 'Sollen wirklich für alle Ihre Standorte alle App-Push-Benachrichtungen deaktiviert werden?',
      benachrichtigungen_global: 'Einstellungen global für alle Standorte durchführen',
      benachrichtigungen_global_nein: 'Einstellungen individuell pro Standort durchführen',
      benachrichtigungen_global_desc: 'Die hier konfigurierten Einstellungen überschreiben die Einstellungen welche Sie pro Standort konfiguriert haben.',

      alarmvorlagen_h1: 'Alarmvorlagen',
      alarmvorlagen_h2: 'für Ergebnisse der letzten 12 Monate',
      alarmvorlagen_aufgabe_beschreibung: 'Beschreibung Aufgabe',
      alarmvorlagen_gruen_ab: 'grün',
      alarmvorlagen_gelb_ab: 'gelb',
      alarmvorlagen_massnahmen: 'Maßnahmen',
      alarmvorlagen_rot_ab: 'rot',

      benutzerListe_key_user: 'Key User',
      benutzerListe_delete_sure: 'Wollen Sie den Benutzer {0} wirklich löschen?',
      benutzerListe_login_as: 'Wenn Sie sich als Benutzer einloggen, werden sämliche Funktionen als der eingeloggte Benutzer durchgeführt. Achtung auch automatisierte E-Mails an den Kunden und User werden möglicherweise versendet.',
      benutzerListe_key_user_aktiv: 'Key User aktiv',
      benutzerListe_geschaeftspartner: 'Geschäftspartner',

      selectAuftrag_auftrag_auswaehlen: 'Auftrag auswählen',

      charts_alarme_je_bereich: 'Alarme je Bereich',
      charts_alarme_je_bereich_seit: 'Alarme je Bereich seit ',
      charts_alarme_je_jahr: 'Alarme pro Jahr',
      charts_alarme_je_objektkategorie: 'Alarme je Kategorie',
      charts_alarme_je_objektkategorie_seit: 'Alarme je Kategorie seit ',
      charts_status_falle: 'Status des Indikators',
      charts_falle_kein_ergebnis: 'Für den Indikator ist kein Ergebnis verfügbar.',
      charts_falle_gelb: 'Warnung: Falle ausgelöst, inaktiv, Verbindung unterbrochen, schwache Batterie, Lokalisierungs Modus, schlechte Verbindung oder Aktivität kritisch',
      charts_falle_rot: 'Gefahr: Befall festgestellt oder Aktivitäts-Warnung',
      charts_nicht_genuegend_ergebnisse: 'Nicht genügend Ergebnisse für einen Chart.',
      charts_diagramme_filtern: 'Diagramme filtern',
      charts_min_1_auftrag: 'Es muss mindestens eine Auftragsart ausgewählt werden um die Diagramme anzeigen zu können.',
      charts_relative: 'Relative Alarmergebnisse nach Monaten',
      charts_nicht_pruefbar: 'nicht überprüfbar',
      charts_warnung: 'Warnung',
      charts_gefahr: 'Gefahr',
      charts_ok: 'OK: Nicht ausgelöst, aktiv oder neu gespannt',
      charts_filter_objektkat: 'Daten werden nach Kategorie gefiltert',
      charts_filter_und_bereich: 'und Bereich',
      charts_filter_bereich: 'Daten werden nach Bereich gefiltert',
      charts_filter_delete: 'Filter löschen',

      customerList_kundendaten_laden: 'Daten werden aufbereitet. Bitte um Geduld',
      customerList_standorte_laden: 'Standorte werden geladen und aufbereitet. Bitte um Geduld',
      customerList_ihre_standorte: 'Ihre Standorte',
      customerList_alle_auswaehlen: 'alle auswählen',
      customerList_auswaehlen: 'auswählen',
      customerList_name: 'Name',
      customerList_plz: 'PLZ',
      customerList_ort: 'Ort',
      customerList_address: 'Adresse',
      customerList_alarm: 'Alarm',
      customerList_maengel: 'Mängel',
      customerList_letzte_pruefung: 'letzte Prüfung',

      editNotifications_verstaendigung_aendern: 'Verständigungen ändern',
      editNotifications_vertragszuteilung: 'Benachrichtigungen',
      editNotifications_neuer_besuchsbericht: 'Benachrichtigung bei neuem Besuchsbericht',
      editNotifications_h2: 'Biotech247 Meldungen',

      listKundenOfBenutzer: 'Standorte werden geladen',
      listKundenOfBenutzer_fehler_laden_standorte: 'Es gab einen Fehler beim Lesen der Standorte. Bitte wenden Sie sich an einen System Administrator.',

      notificationAlarm_gelber_alarm: 'E-Mail Benachrichtigung bei gelbem Alarm',
      notificationAlarm_roter_alarm: 'E-Mail Benachrichtigung bei rotem Alarm',
      notificationAlarm_gelber_alarm_app: 'App Push Benachrichtigung bei gelbem Alarm',
      notificationAlarm_roter_alarm_app: 'App Push Benachrichtigung bei rotem Alarm',

      notificationNeuerMangel: 'E-Mail Benachrichtigung bei neuem Mangel',
      notificationNeuerMangel_app: 'App Push Benachrichtigung bei neuem Mangel',

      selectBenutzerToCustomer_rechte: 'Rechte',
      selectBenutzerToCustomer_rechte_fuer: 'Benutzerrechte wählen für',
      selectBenutzerToCustomer_plaene_bearbeiten: 'Pläne bearbeiten',
      selectBenutzerToCustomer_maengel_ansehen: 'Mängel ansehen',
      selectBenutzerToCustomer_maengel_plan_ansehen: 'Mängel in Plänen anzeigen',
      selectBenutzerToCustomer_maengel_bearbeiten: 'Mängel bearbeiten',
      selectBenutzerToCustomer_maengel_fotos: 'Mängel Fotos betrachten',
      selectBenutzerToCustomer_lieferschein_ansehen: 'Lieferscheine ansehen',
      selectBenutzerToCustomer_bereiche_ansehen: 'Bereiche ansehen',
      selectBenutzerToCustomer_bereiche_min_1: 'Bitte wählen Sie mindestens einen Bereich aus.',

      customerNotificationPrioritaet_gruen: 'Information',
      customerNotificationPrioritaet_gelb: 'Warnung',
      customerNotificationPrioritaet_rot: 'dringende Nachricht',

      customerNotificationStatus_abgeschlossen: 'abgeschlossen',
      customerNotificationStatus_bearbeitet: 'bearbeitet',
      customerNotificationStatus_offen: 'offen',

      allgemeineDokumente_wird_geladen: 'Dokumente werden geladen',
      allgemeineDokumente_oeffnen: 'Dokument öffnen',

      eigeneDokumente_wird_geladen: 'Eigene Dokumente werden geladen',
      eigeneDokumente_dateiname: 'Dateiname',
      eigeneDokumente_loeschen_confirm: 'Wollen Sie das Dokument {0} wirklich löschen?',

      fileUpload_bezeichnung: 'Bezeichnung Ihres Dokuments',
      fileUpload_auswaehlen: 'Dokument auswählen',
      fileUpload_einfuegen: 'Dokument einfügen',
      fileUpload_ziehen: 'Dokument hierher ziehen',
      fileUpload_oder: 'oder',
      fileUpload_wait: 'Dokument wird hochgeladen',
      fileUpload_uploaded: 'Dokument erfolgreich hochgeladen.',
      fileUpload_error: 'Fehler beim Upload der Datei. Bitte achten Sie auf die erlaubte Dateigröße (max. 10 MB).',

      fileUploadButton_button: 'Dokument hochladen',
      fileUploadButton_neu: 'neues Dokument hochladen',

      sicherheitsdatenblaetterList_loading: 'Sicherheitsdatenblätter werden geladen',
      sicherheitsdatenblaetterList_artikel: 'Artikel',

      ergebnisAndChart_chart_besuch: 'Chart letzter Ergebnisse',
      ergebnisAndChart_entwicklung: 'letzte Ergebnisse',

      ergebnisseTable_zeitpunkt: 'Zeitpunkt',
      ergebnisseTable_zeitraum: 'Zeitraum',
      ergebnisseTable_berater: 'Ihr(e) Berater(in)',
      ergebnisseTable_cache_leeren: 'Cache leeren',
      ergebnisseTable_aufbereiten: 'Ergebnisse werden berechnet und aufbereitet. Bitte um Geduld',
      ergebnisseTable_leer: 'Es sind keine Ergebnisse vorhanden.',
      ergebnisseTable_sdb: 'SDB',
      ergebnisseTable_cache_leer: 'Cache wurde gelöscht. Bitte Ergebnisliste neu laden.',

      sicherheitsdatenblaetter: 'SDB / Produktinfos',
      sicherheitsdatenblaetter_sdb: 'Sicherheitsdatenblätter / Produktinfos',
      sicherheitsdatenblaetter_allgemein: 'allgemeine Dokumente',
      sicherheitsdatenblaetter_eigene: 'eigene Dokumente',

      biotechFooter_kontakt: 'Kontakt',
      biotechFooter_impressum: 'Impressum',
      biotechFooter_datenschutz: 'Datenschutz',
      biotechFooter_agb: 'AGB',
      biotechFooter_bienenblog: 'Bienenblog',

      biotechHeader_home: 'Home',
      biotechHeader_dashboard: 'Dashboard',
      biotechHeader_besuchsberichte: 'Besuchsberichte',
      biotechHeader_sdb: 'SDB/Dokumente',
      biotechHeader_logout: 'Logout',
      biotechHeader_logged_in_as: 'Sie sind als {0} eingeloggt. Alle Tätigkeiten werden als dieser Benutzer durchgeführt!',
      biotechHeader_support: 'Support Anfrage',

      captchaFormRow_label: 'Sicherheitscode',
      captchaFormRow_placeholder: 'Geben Sie bitte den Sicherheitscode ein.',

      spinner_loading: 'Daten werden geladen',

      anzahlMaengelIcon_hover: 'Mängelliste anzeigen',

      editMangelPrioritaet_hoch: 'hoch',
      editMangelPrioritaet_mittel: 'mittel',
      editMangelPrioritaet_gering: 'gering',

      imageCarousel_aufgenommen_am: 'aufgenommen am',
      imageCarousel_aufgenommen_von: 'aufgenommen von',
      imageCarousel_detailansicht: 'Detailansicht',
      imageCarousel_delete_sure: 'Sind Sie sicher, dass sie das Bild löschen wollen?',
      imageCarousel_abgeschlossen: 'abgeschlossen',
      imageCarousel_erledigt: 'bearbeitet',
      imageCarousel_abgeschlossen_desc: 'Das Bild wurde abgeschlossen.',

      maengelTable_alert: 'Es sind keine unerledigten Mängel vorhanden.',
      maengelTable_show_abgeschlossen: 'abgeschlossene Mängel anzeigen',
      maengelTable_keine_bilder: 'Keine Bilder vorhanden',
      maengelTable_bilder_hochladen: 'Bild hochladen',
      maengelTable_bilder_hochladen_abschliessen: 'Bild hochladen und Mangel abschließen',
      maengelTable_bilder_hochladen_desc: 'Neues Bild hochladen, welches den Mangel zeigt und dokumentiert.',
      maengelTable_bilder_hochladen_abschliessen_desc: 'Neues Bild hochladen, welches die Erledigung des Mangels dokumentiert. Der Mangel wird nach dem Hochladen abgeschlossen.',
      maengelTable_bilder_hochladen_abschliessen_customer: 'Bild hochladen und Mangel auf bearbeitet stellen',
      maengelTable_bilder_hochladen_abschliessen_desc_customer: 'Neues Bild hochladen, welches die Bearbeitung des Mangels dokumentiert. Der Mangel wird nach dem Hochladen auf bearbeitet gestellt.',
      printMaengel_kein_bild_title: 'Upload error',
      printMaengel_kein_bild: 'Der hochgeladene File {0} ist nicht im jpg or png Format. Bitte laden Sie jpg oder png hoch.',
      maengelTable_show_details: 'Details anzeigen',
      maengelTable_upload_erfolgreich: 'Bild {0} wurde erfolgreich hochgeladen.',
      maengelTable_upload_error: 'Fehler beim Upload des Bild {0}. Bitte versuchen Sie es erneut oder wenden Sie sich an eine BioTech MitarbeiterIn.',
      maengelTable_details: 'Details / Mangel bearbeiten',
      maengelTable_aufgenommen_am: 'Aufgenommen am',
      maengelTable_aufgenommen_von: 'Aufgenommen von',
      maengelTable_abgeschlossen: 'Abgeschlossen am',
      maengelTable_pos_beschreibung: 'Position',
      maengelTable_add_notiz: 'Notiz hinzufügen',
      maengelTable_add_notiz_ph: 'Notiz eingeben',
      maengelTable_abschliessen: 'abschließen',
      maengelTable_schliessen: 'schließen',
      maengelTable_text1: 'Sind Sie sicher, dass Sie den Mangel auf Status "erledigt" stellen wollen?',
      maengelTable_text2: 'Sind Sie sicher, dass der Mangel abgeschlossen wurde?',
      maengelTable_confirm_delete: 'Wollen Sie den Mangel {0} wirklich löschen?',

      newPasswordFormRow_passwort_wiederholen: 'Passwort wiederholen',
      newPasswordFormRow_rule1: 'Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten. Das Passwort muss mindestens 8 Zeichen lang sein.',
      newPasswordFormRow_rule2: 'Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten. Das Passwort muss mindestens 16 Zeichen lang sein.',
      newPasswordFormRow_passwort_error: 'Die beiden Passwörter stimmen nicht überein',

      maengelList_nr: 'Nr.',

      mangelDetails_entfernen: 'Mangel aus Plan entfernen',

      objekteDetails_typ: 'Typ',
      objekteDetails_artikel_no: 'Artikelnummer',

      planList_neu: 'neuer Plan',
      planList_hochladen: ' Plan hochladen',
      planList_bemerkung_freigabe: 'Bemerkung zur Freigabe',
      planList_loading: 'Pläne werden geladen',
      planList_austauschen: 'Falls Sie den Plan selbst austauschen wollen müssen Sie bitte einen neuen Plan anlegen.',
      planList_p: 'Wählen Sie eine beliebige Bezeichnung für Ihren Plan und laden Sie diesen hoch. Erlaubte Dateitypen sind JPG, GIF, PNG oder PDF. Maximale Dateigröße sind 10 MB.',
      planList_bezeichnung: 'Bezeichnung des Plans',
      planList_ueberprueft: 'Plan überprüft und freigegeben',
      planList_upload: 'Bitte Datei auswählen oder hier her ziehen',
      planList_thumbnail: 'Thumbnail',
      planList_hochgeladen_am: 'hochgeladen am',
      planList_freigegeben_am: 'freigegeben am',
      planList_freigegeben_von: 'freigegeben von',
      planList_loeschen: 'Wollen Sie den Plan {0} wirklich löschen? Alle Einstellungen zu diesem Plan werden entfernt.',
      planList_error: 'Es gab einen Fehler beim Hochladen des Plans. Bitte achten Sie darauf dass der Plan die maximale Dateigröße von 10MB nicht überschreitet. Erlaubte Dateitypen sind jpg, gif, png oder pdf.',
      planList_datei: 'Datei',
      planList_ohne_file: 'leeren Plan anlegen',

      positionDetails_zusatzleistung: 'Zusatzleistung',
      positionDetails_charts: 'Charts',
      positionDetails_installierteObjekte: 'Installierte Indikatoren',
      positionDetails_seriennummer: 'Seriennummer',
      positionDetails_statusErgebnis: 'Status/Ergebnis',
      positionDetails_pos_entfernen: 'Position aus Plan entfernen',

      searchPosition_keine_gefunden: 'Keine Position gefunden mit Suchbegriff',

      selectBereich_alle: 'Alle Bereiche',
      selectBereich_keine_bezeichnung: 'keine Bezeichnung',

      selectBereiche_mind: 'Es muss mindestens ein Bereich ausgewählt werden.',

      selectPlan_select: 'bitte Plan wählen',
      selectPlan_freigabe: 'Freigabe am',

      selectPosition_select: 'Positionen verwalten',

      showPositionInMap_select_plan: 'Plan auswählen',

      person_quali: 'Qualifikationen',

      schulungsunterlagen_kompetent: 'Kompetenz&shy;nach&shy;weise',
      schulungsunterlagen_kompetenz_anzeigen: 'Kompetenznachweise anzeigen',

      deviceHistory_aeltere: 'ältere Meldungen anzeigen',
      deviceHistory_vorherige: 'Vorherige Ergebnisse von Biotech247: ',
      deviceHistory_noch_keine: 'Es sind keine älteren Meldungen vorhanden.',
      deviceHistory_zeitpunkt: 'Zeitpunkt Meldung',
      deviceHistory_befall: 'Befallsbeschreibung',
      deviceHistory_akku: 'Akku-Ladestand',

      deviceStatus_befall: 'Befall festgestellt.',
      deviceStatus_kritisch: 'Aktivität kritisch.',
      deviceStatus_ausgeloest: 'Das Gerät wurde ausgelöst.',
      deviceStatus_warnung: 'Aktivitäts Warnung.',
      deviceStatus_falsch: 'Ausgelöst, kein Fang',
      deviceStatus_funkioniert: 'Das Gerät funktioniert ordnungsgemäß.',
      deviceStatus_inaktiv: 'Das Gerät ist inaktiv.',
      deviceStatus_unterbrochen: 'Verbindung zum Server unterbrochen.',
      deviceStatus_schwach: 'Schwache Batterie.',
      deviceStatus_location: 'Location Mode.',
      deviceStatus_wlan: 'Schlechte WLAN Verbindung.',

      deviceStatusForChartLegende_deinstalliert: '{1} ({0}) wurde deinstalliert am',
      deviceStatusForChartLegende_status: '{1} ({0})',
      deviceStatusForChartLegende_kein_ergebnis: 'Für {1} ({0}) ist kein Ergebnis verfügbar.',

      showHideTraplinkedStatus_24_7: 'BIOTECH247 Status',

      showHideSensorStatus: 'Sensor Status',

      showTraplinkedReportImage_vergroessern: 'Foto vergrößern',
      showTraplinkedReportImage_automatisch: 'automatisch aufgenommenes Foto',
      showTraplinkedReportImage_aufgenommene: 'aufgenommene Fotos',

      traplinkedErgebnisliste_24_7: 'Biotech247 Ergebnisse',

      traplinkedStatus_letzte_meldung: 'Letzte Meldung',
      traplinkedStatus_24_7_name: 'Biotech247 Name',
      traplinkedStatus_meldung: 'Meldung',
      traplinkedStatus_batterie: 'Batterie Status',
      traplinkedStatus_alt: 'ältere Meldungen',

      main_ausgeloest: 'ausgelöst',
      main_unterbrochen: 'Verbindung unterbrochen',
      main_batterie: 'schwache Batterie',
      main_lokalisierung: 'Lokalisierungs Modus',
      main_schlecht: 'schlechte Verbindung',
      main_kritisch: 'Aktivität kritisch',
      main_befallen: 'Befall festgestellt',
      main_befallen_leicht: 'leichter Befall festgestellt',
      main_befallen_stark: 'Starker Befall festgestellt',
      main_akt_warnung: 'Aktivitäts Warnung',
      main_ok: 'ok',
      main_neu: 'neu gespannt',
      main_bild: 'Bild aufgenommen',
      main_online: 'online',
      main_konfiguriert: 'konfiguriert',
      main_gewartet: 'gewartet',
      main_unerledigt: 'unerledigt',
      main_bearbeitet: 'bearbeitet',
      main_abgeschlossen: 'abgeschlossen',

      support_h1: 'BIO-TECH Mitarbeiter anfordern',
      support_mon_pos: 'Monitoring Position',
      support_mon_mangel: 'Mangel aus Bearbeitungsliste',
      support_tel: 'Telefonnummer',
      support_meine_tel: 'Meine Telefonnummer',
      support_meine_tel_err: 'Geben Sie bitte Ihre Telefonnummer für Rückfragen ein.',
      support_problemtyp: 'Problemtyp',
      support_thema: 'Thema',
      support_thema_placeholder: 'Ich benötige Unterstützung von einem BIO-TECH Mitarbeiter zum Thema:',
      support_thema_err: 'Bitte das Thema des Problems kurz beschreiben.',
      support_problem_beschreibung: 'Problembeschreibung',
      support_problem_beschreibung_err: 'Bitte beschreiben Sie das Problem möglichst ausführlich.',
      support_problem_foto_auswaehlen: 'Foto auswählen oder hier her ziehen',
      support_problem_foto_ziehen: 'Foto hier her ziehen',
      support_danke: 'Vielen Dank, Ihre Anfrage wurde erfolgreich an uns übermittelt. Eine BIO-TECH MitarbeiterIn wird sich so schnell als möglich mit Ihnen in Verbindung setzen.',
      support_error: 'Entschuldigung, es gab einen Fehler beim Speichern Ihrer Anfrage. Bitte versuchen Sie es später erneut oder wenden Sie sich telefonisch an uns.',

      besuchsberichtErgebnisse_deinstalliert_am: 'deinstalliert am',

      searchPosition: 'Position suchen',

      selectObjekttypen_filtern: 'Objekttypen filtern',

      selectBereiche_filtern: 'Bereiche filtern',

      traplinkedImages_nav: 'Biotech247 Bilder prüfen',
      traplinkedImages_gruen: 'OK',
      traplinkedImages_gelb: 'Warnung',
      traplinkedImages_rot: 'Gefahr',
      traplinkedImages_alt: 'zu prüfendes Biotech247 Bild',
      traplinkedImages_date_time: 'Datum/Zeit:',
      traplinkedImages_support: 'Support Anfrage erstellen',
      traplinkedImages_ergebnis_eingeben: 'Bitte erfassen Sie ein Ergebnis!',
      traplinkedImages_empty: 'Gratuliere! Keine unbewerteten Bilder vorhanden.',
      traplinkedImages_success: 'Ihre Eingabe wurde erfolgreich gespeichert.',
      traplinkedImages_delete_confirm: 'Wollen Sie das Bild wirklich löschen?',
      traplinkedImages_delete_confirm_checked: 'Wollen Sie die ausgewählten Bilder wirklich löschen?',
      traplinkedImages_ergebnis_checked: 'Wollen Sie das Ergebnis wirklich für alle ausgewählten Bilder speichern?',
      traplinkedImages_success_title: 'Erfolgreich gespeichert.',
      traplinkedImages_success_delete: 'Das Bild wurde erfolgreich gelöscht',
      traplinkedImages_success_delete_checked: 'Die ausgewählten Bilder wurden erfolgreich gelöscht',
      traplinkedImages_success_title_delete: 'Erfolgreich gelöscht.',

      sensorStatus_name: 'Bezeichnung',
      sensor_vorherige: 'Vorherige Sensor Ergebnisse',
      sensor_chart_title: 'Sensor Ergebnisse',
      sensor_charts_warnung: 'Warnung',
      sensor_charts_gefahr: 'Gefahr',
      sensor_charts_ok: 'OK',
      sensor_temp: 'Temperatur °C',
      sensor_luftfeuchtigkeit: 'Luftfeuchtigkeit %',
      sensor_vorher: 'vorheriges Monat',
      sensor_naechstes: 'nächstes Monat',
      sensor_day: 'Tagesansicht',
      sensor_week: 'Wochenansicht',
      sensor_month: 'Monatsansicht',
      sensor_vorher_week: 'vorherige Woche',
      sensor_naechstes_week: 'nächste Woche',
      sensor_vorher_day: 'vorheriger Tag',
      sensor_vorher_24h: 'letzte 24h',
      sensor_naechstes_day: 'nächster Tag',
      sensor_taupunkt_anzeigen: 'Taupunkt einblenden',
      sensor_taupunkt_ausblenden: 'Taupunkt ausblenden',
      sensor_schwellenwert_anzeigen: 'Schwellenwerte einblenden',
      sensor_schwellenwert_ausblenden: 'Schwellenwerte ausblenden',
      sensor_show_x: 'Datum/Uhrzeit einblenden',
      sensor_hide_x: 'Datum/Uhrzeit ausblenden',
      sensor_alarm_nach: 'nach',
      sensor_alarm_minuten: 'Minuten',

      show_benutzer_of_customer_none: 'Keine Benutzer ausser dem eigenen für den Standort vorhanden.',

      check_passwort_sicherheit_desc: 'Ihr Passwort entspricht nicht den Sicherheitsanforderungen. Bitte ändern Sie ihr Passwort.',
      check_passwort_sicherheit_change: 'Bitte Passwort ändern',

      benutzerModel_keinStandort: 'Sind Sie sicher, dass sie dem Benutzer keinen Standort zuweisen wollen?',

    }
  },
  en: {
    message: {

      default_speichern: 'save',
      default_loeschen: 'delete',
      default_absenden: 'submit',
      default_abbrechen: 'cancel',
      default_weiter: 'next',
      default_bearbeiten: 'edit',
      default_anzeigen: 'show',
      default_ok: 'OK',
      default_aendern: 'edit',
      default_zurueck: 'back',
      default_bezeichnung: 'Description',

      welcome_browser_veraltet: 'Your browser is outdated!',
      welcome_browser_aktualisieren: 'Please update your browser to use this application safely, worry-free and error-free.',
      welcome_browser_herunterladen: 'Download the latest browser',
      welcome_neue_funktionen: 'New features:',
      welcome_neue_funktionen_list: '<li><strong>Export</strong>: Export your data as an Excel document</li><li><strong>Notifications:</strong> Notifications from Biotech for your sites</li>',

      team_h1: 'Your Biotech team',
      team_vertrieb: 'Your sales contact',
      team_kundenbetreuer: 'Your field consultants',

      printPositionen_h1: 'Indicators',
      printPositionen_kundennummer: 'Customer ID',
      printPositionen_bereich: 'Area',
      printPositionen_position_nummer: 'Position ID',
      printPositionen_position: 'Position',
      printPositionen_objekte: 'Indicators',

      printMaengel_h1: 'deficiencies',
      printMaengel_nummer: 'ID',
      printMaengel_datum: 'Date',
      printMaengel_kurzbeschreibung: 'Deficiency description',
      printMaengel_beschreibung: 'Details',
      printMaengel_zustaendig: 'Responsible',
      printMaengel_status: 'Status',
      printMaengel_foto: 'Image',

      printErgebnisliste_h1: 'Results',
      printErgebnisliste_auftrag_nr: 'Servicecall ID',
      printErgebnisliste_vom: 'from',
      printErgebnisliste_datum_uhrzeit: 'Date/Time',
      printErgebnisliste_aufgabe: 'Task',
      printErgebnisliste_objekt: 'Indicator',
      printErgebnisliste_ergebnis: 'Result',
      printErgebnisliste_bemerkung: 'Remark',
      printErgebnisliste_material: 'Materials',
      printErgebnisliste_menge: 'Quantity',
      printErgebnisliste_einheit: 'Unit',
      printErgebnisliste_berater: 'User',

      positionen_h1: 'Indicators',
      positionen_loading: 'Loading...',
      positionen_excel: 'Excel',
      positionen_pdf: 'PDF',
      positionen_drucken: 'Print',
      positionen_filter: 'Filter',
      positionen_daten_laden: 'Loading...',
      positionen_empty: 'No positions available / no user permission.',

      plan_plaene: 'Plans',
      plan_plaene_verwalten: 'Manage plans',
      plan_freigeben: 'Review plan',
      plan_freigeben_ueberprueft: 'Plan reviewed',
      plan_freigeben_von: 'Reviewed by',
      plan_freigeben_am: 'Date:',
      plan_bemerkungen: 'Remarks',
      plan_maengel_einzeichnen: 'Place deficiencies',
      plan_positionen: 'Positions',
      plan_positionen_alle: 'All positions',
      plan_positionen_filter: 'Positions filtered',
      plan_markierungen: 'Draw',
      plan_markierung_neu: 'Draw new zone',
      plan_einstellungen: 'Settings',
      plan_bearbeitungsmodus: 'Edit positions',
      plan_aktiv: 'active',
      plan_inaktiv: 'inactive',
      plan_verwalten: 'Manage plans',
      plan_empty: 'No plans uploaded',
      plan: 'Plan',
      plan_position_saved: 'Position saved',
      plan_position_loading: 'Loading positions',
      plan_bezeichnung: 'Description',
      plan_bezeichnung_placeholder: 'description of your new zone',
      plan_farbe: 'Colour',
      plan_transparenz: 'Transparency',
      plan_rahmen_farbe: 'Frame color',
      plan_starten: 'Start',
      plan_position_details: 'Positions details',
      plan_markierung_zeichnen: 'Draw new zone',
      plan_freigabe_ok: 'Review safed',
      plan_freigabe_error: 'An error occurred while saving. Please try again or contact a system administrator.',
      plan_position_fehler: 'Error inserting the position or deficiency. For positioning in plan please drag and drop ',
      plan_mangel_nr: 'Deficiency ID',
      plan_position_gespeichert: 'The position<br/>{0}<br/>has been successfully {1}<br/>saved to plan<br/>.',
      plan_mangel_details: 'Lack of details',
      plan_neue_markierung: 'Draw new zone',
      plan_markierung_loeschen: 'Do you really want to delete zone {0}?',
      plan_markierung_aendern: 'Edit zone',
      plan_maengel_load_error: 'There was an error while loading deficiencies. Please open plan again.',
      plan_position_load_error: 'There was an error while loading positions. Please open plan again',
      plan_position_load_error_no_objekt: 'Positions without devices can´t be displayed on plan',
      plan_mangel_erfolgreich: 'The defect<br/><br/>{0}<br/><br/>has been successfully saved to your map<br/>{1}.',

      newPassword_h1: 'Enter new password',
      newPassword_h1_2: 'Set password',
      newPassword_pleaseinsert: 'Please enter a new password.',
      newPassword_festlegen: 'Please set your password.',
      newPassword_description: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character ($@$!%*#?&) and must be at least 8 characters long.',
      newPassword_code_error: 'The given code does not exist or your account password has already been changed.',

      login: 'login',
      login_error: 'No user could be found with your login data!',
      login_wartung: 'BioTech Analytics is currently under maintenance. Please try again later, we`re working on it. Thank you for your understanding.',
      login_username: 'Username/email address',
      login_username_ph: 'your email address',
      login_password_ph: 'your password',
      login_password: 'Password',
      login_password_vergessen: 'Forgot password',

      lieferscheine_h1: 'delivery notes',
      lieferscheine_pdf: 'Open PDF',
      lieferscheine_kw: 'CW',
      lieferscheine_thema: 'Topic',

      kundenliste_h1: 'Locations',
      kundenliste_suchen: 'search location',
      kundenliste_not_found: 'No location could be found with {0}',
      kundenliste_austria: 'Austria',
      kundenliste_germany: 'Germany',

      forgotPassword_h1: 'Password assistance',
      forgotPassword_p: 'Please enter the username (email address) associated with your account.',
      forgotPassword_username_invalid: 'Please enter your username (e-mail address).',

      export_h1: 'Export',
      export_p: 'Please note that large amounts of data are processed over a longer period of time when exporting over a longer period of time. This calculation can therefore take several minutes.',
      export_startdatum: 'Start date',
      export_select_datum: 'Please select date',
      export_validationab: 'Please enter date using (YYYY-MM-DD)',
      export_enddatum: 'End date',
      export_validationbis: 'Please enter date using (YYYY-MM-DD). End date has to be after Start date.',
      export_start: 'Start export',
      export_berechnen: 'Calculating data',
      export_keine_daten: 'No results. Please check your selections.',
      export_error: 'Unfortunately, an error occurred while retrieving the data.',
      export_datum_ab: 'Date from',
      export_datum_bis: 'Date to',
      export_dateiformat: 'File format',
      export_dateiformat_des: 'File format of your export.',

      dashboard_h1: 'Biotech Analytics dashboard',
      dashboard_h3: 'Overview all locations',
      dashboard_uebersicht_standort: 'Location overview',
      dashboard_uebersicht: 'Overview',
      dashboard_no_data: 'Not enough data available.',

      customerNotifications_h1: 'Postbox',
      customerNotifications_alle: 'all',
      customerNotifications_alle_gelesen: 'Mark all as read',
      customerNotifications_alle_gelesen_erfolg: 'Perfect! You have read and edited all notifications.',
      customerNotifications_prioritaet: 'Priority',
      customerNotifications_nachricht: 'Message',
      customerNotifications_status: 'Processing status',
      customerNotifications_status_offen: 'active',
      customerNotifications_status_bearbeitet: 'edited',
      customerNotifications_status_abgeschlossen: 'completed',
      customerNotifications_status_not_editable: 'Completion by Bio-Tech',
      customerNotifications_notizen: 'Responses',
      customerNotifications_notiz_add: 'New responses',
      customerNotifications_standort: 'Location',
      customerNotifications_betreff: 'Subject',
      customerNotifications_delete_sure: 'You´re about to delete the message {0}. Sure??',
      customerNotifications_ungelesen: 'You have {0} unread messages in your postbox.',
      customerNotifications_ungelesen_1: 'There is an unread message in your postbox.',
      customerNotifications_erfolgreich: 'Your message has been sent successfully.',
      customerNotifications_nachricht_von_biotech: 'Message from Bio-Tech team.',
      customerNotifications_247_bilder_pruefen: 'check images',
      customerNotifications_fuer_alle_aktivieren: 'activate for all locations',
      customerNotifications_fuer_alle_deaktivieren: 'deactivate for all locations',
      customerNotifications_fuer_alle_aktivieren_ok: 'The notification has been activated for all locations.',
      customerNotifications_fuer_alle_deaktivieren_ok: 'The notification has been deactivated for all locations.',
      customerNotifications_fuer_alle_deaktivieren_fail: 'Error disabling notification. Please contact a BioTech representative.',

      changePassword_h1: 'Change password',
      changePassword_existing: 'Existing password',
      changePassword_existing_error: 'The password you entered is not correct.',
      changePassword_new_pass_p: 'Please enter a new password. Please note that the password must contain at least one uppercase letter, one lowercase letter, one digit and one special character ($@$!%*#?&) and must be at least 8 characters long.',
      changePassword_new_pass_p2: 'Please enter a new password. Please note that the password must contain at least one uppercase letter, one lowercase letter, one digit and one special character ($@$!%*#?&) and must be at least 16 characters long.',

      besuchsbericht_monitoringbesuche: 'Monitoring visits',
      besuchsbericht_einzelauftrag: 'Other Services',
      besuchsbericht_monatsberichte: 'Monthly reports',
      besuchsbericht_select: 'Please select an order type.',
      besuchsbericht_auftrag_suchen: 'Select visits',
      besuchsbericht_monatsbericht: 'Monthly report',
      besuchsbericht: 'Visit report',
      besuchsbericht_1bearbeiterMangel: 'and 1 deficiency edited',
      besuchsbericht_MbearbeiterMangel: 'and {0} deficiency completed',
      besuchsbericht_1unerledigter: 'actice deficiency {0}',
      besuchsbericht_Munerledigter: 'unfinished deficiencies {0}',
      besuchsbericht_thereAre: 'There are ',
      besuchsbericht_thereIs: 'There is',
      besuchsbericht_mangelVorhanden: ' edited deficiency.',
      besuchsbericht_maengelVorhanden: ' completed deficiencies',
      besuchsbericht_vorheriger_von: 'show previous order from ',
      besuchsbericht_naechsten_von: 'show next order from ',
      besuchsbericht_keiner: 'Unfortunately, there is no report available.',

      benutzerSuche_h1: 'Search for users',
      benutzerSuche_no_user: 'No users found. Please check your settings.',
      benutzerSuche_4digits: 'Please enter min. 4 charakters.',

      benutzerOfCustomer_h1: 'User list',

      benutzer_h1: 'Users',
      benutzer_add: 'Add user',
      benutzer_standorte: 'Locations',
      benutzer_no_standorte: 'No locations assigned!',
      benutzer_benutzername: 'Username',
      benutzer_valid_email: 'Please enter a valid e-mail address',
      benutzer_vorname: 'First name',
      benutzer_nachname: 'Last Name',
      benutzer_name: 'Name',
      benutzer_aktiv: 'active',
      benutzer_inaktiv: 'inactive',
      benutzer_exists: 'User already exists. Please proceed to enable {0} to access your locations?',
      benutzer_delete: 'Are you sure you want to delete the user {0}?',

      benachrichtigungen_h1: 'Notifications',
      benachrichtigungen_vertragszuteilung: 'Contract settings',
      benachrichtigungen_mailVerstaendigung: 'Notifications for your locations.',
      benachrichtigungen_konf: 'Notification settings for new locations.',
      benachrichtigungen_konf2: 'Notification settings for new reports',
      benachrichtigungen_label: 'eMail notification for net visit report',
      benachrichtigungen_label_app: 'App push notification for net visit report',
      benachrichtigungen_247_meldungen: 'Biotech247 messages',
      benachrichtigungen_app: 'App push notification for new locations',
      benachrichtigungen_mail: 'eMail notification for new locations',
      benachrichtigungen_besuchsbericht_meldungen: 'Visit messages',
      benachrichtigungen_alle_on: 'activate all',
      benachrichtigungen_alle_off: 'deactivate all',
      benachrichtigungen_alle_app_on: 'App push activate all',
      benachrichtigungen_alle_app_off: 'App push deactivate all',
      benachrichtigungen_alle_on_confirm: 'Do you really want to activate all email notifications for all your locations?',
      benachrichtigungen_alle_off_confirm: 'Do you really want to deactivate all email notifications for all your locations?',
      benachrichtigungen_alle_app_on_confirm: 'Do you really want to activate all app push notifications for all your locations?',
      benachrichtigungen_alle_app_off_confirm: 'Do you really want to deactivate all app push notifications for all your locations?',
      benachrichtigungen_global: 'Settings globally for all locations',
      benachrichtigungen_global_nein: 'Settings individually for each location',
      benachrichtigungen_global_desc: 'The settings configured here overwrite the settings you have configured per location.',

      alarmvorlagen_h1: 'Alarm templates',
      alarmvorlagen_h2: 'for results of the last 12 months',
      alarmvorlagen_aufgabe_beschreibung: 'Task desciption',
      alarmvorlagen_gruen_ab: 'green',
      alarmvorlagen_gelb_ab: 'yellow',
      alarmvorlagen_massnahmen: 'Measures',
      alarmvorlagen_rot_ab: 'red',

      benutzerListe_key_user: 'Key User',
      benutzerListe_delete_sure: 'Sure to delete the user {0}?',
      benutzerListe_login_as: 'If you log in as a user, all functions will be performed as the logged-in user. Attention: automated e-mails to the customer and user may also be sent.',
      benutzerListe_key_user_aktiv: 'Key User enabled',
      benutzerListe_geschaeftspartner: 'Business partner',

      selectAuftrag_auftrag_auswaehlen: 'Select order',

      charts_alarme_je_bereich: 'Alarms per area',
      charts_alarme_je_bereich_seit: 'Alarms per area since ',
      charts_alarme_je_jahr: 'Alarms per year',
      charts_alarme_je_objektkategorie: 'Alarms per category',
      charts_alarme_je_objektkategorie_seit: 'Alarms per category since ',
      charts_status_falle: 'Device status',
      charts_falle_kein_ergebnis: 'No results available for this device.',
      charts_falle_gelb: 'Warning: device triggered, inactive, disconnected, low battery, localization mode, poor connection or activity critical',
      charts_falle_rot: 'Danger: infestation detected or activity warning',
      charts_nicht_genuegend_ergebnisse: 'Not enough results to display chart.',
      charts_diagramme_filtern: 'Filter charts',
      charts_min_1_auftrag: 'At least one order type must be selected to display diagrams.',
      charts_relative: 'Relative alarm results by month',
      charts_nicht_pruefbar: 'not checked',
      charts_warnung: 'Warning',
      charts_gefahr: 'Danger',
      charts_ok: 'OK: device active or reset',
      charts_filter_objektkat: 'Data filtered by device category',
      charts_filter_und_bereich: 'and area',
      charts_filter_bereich: 'Data is filtered by area',
      charts_filter_delete: 'clear filter',

      customerList_kundendaten_laden: 'Customer data beeing loaded and processed. Please be patient',
      customerList_standorte_laden: 'Locations beeing loaded and processed. Please be patient',
      customerList_ihre_standorte: 'Your Locations',
      customerList_alle_auswaehlen: 'select all',
      customerList_auswaehlen: 'select',
      customerList_name: 'Name',
      customerList_plz: 'Postcode',
      customerList_ort: 'City',
      customerList_address: 'Address',
      customerList_alarm: 'Alarm',
      customerList_maengel: 'Deficiencies',
      customerList_letzte_pruefung: 'Last check',

      editNotifications_verstaendigung_aendern: 'Edit notifications',
      editNotifications_vertragszuteilung: 'Messages',
      editNotifications_neuer_besuchsbericht: 'Message: new report',
      editNotifications_h2: 'Biotech247 messages',

      listKundenOfBenutzer: 'Loading locations',
      listKundenOfBenutzer_fehler_laden_standorte: 'There was an error reading the locations. Please contact a system administrator.',

      notificationAlarm_gelber_alarm: 'eMail message: Yellow alert',
      notificationAlarm_roter_alarm: 'eMail message: Red alert',
      notificationAlarm_gelber_alarm_app: 'App push message: Yellow alert',
      notificationAlarm_roter_alarm_app: 'App push message: Red alert',

      notificationNeuerMangel: 'eMail message: new deficiency',
      notificationNeuerMangel_app: 'App push message: new deficiency',

      selectBenutzerToCustomer_rechte: 'Rights',
      selectBenutzerToCustomer_rechte_fuer: 'Select user rights for',
      selectBenutzerToCustomer_plaene_bearbeiten: 'Edit plans',
      selectBenutzerToCustomer_maengel_ansehen: 'Show deficiencies',
      selectBenutzerToCustomer_maengel_plan_ansehen: 'Show deficiencies in plans',
      selectBenutzerToCustomer_maengel_bearbeiten: 'Edit deficiencies',
      selectBenutzerToCustomer_maengel_fotos: 'Deficiencies: show pictures',
      selectBenutzerToCustomer_lieferschein_ansehen: 'Show delivery notes',
      selectBenutzerToCustomer_bereiche_ansehen: 'Show areas',
      selectBenutzerToCustomer_bereiche_min_1: 'Please select at least 1 area.',

      customerNotificationPrioritaet_gruen: 'Information',
      customerNotificationPrioritaet_gelb: 'Warning',
      customerNotificationPrioritaet_rot: 'Urgent alert',

      customerNotificationStatus_abgeschlossen: 'completed',
      customerNotificationStatus_bearbeitet: 'edited',
      customerNotificationStatus_offen: 'active',

      allgemeineDokumente_wird_geladen: 'Loading documents',
      allgemeineDokumente_oeffnen: 'Open Document',

      eigeneDokumente_wird_geladen: 'Loading Own documents',
      eigeneDokumente_dateiname: 'Filename',
      eigeneDokumente_loeschen_confirm: 'Are you sure to delete the document {0}?',

      fileUpload_bezeichnung: 'Document name',
      fileUpload_auswaehlen: 'Select document',
      fileUpload_einfuegen: 'Insert document',
      fileUpload_ziehen: 'Drag document here',
      fileUpload_oder: 'or',
      fileUpload_wait: 'Uploading document',
      fileUpload_uploaded: 'Document uploaded successfully.',
      fileUpload_error: 'An error occurred while uploading the file. Please pay attention to the max. file size of 10 MB.',

      fileUploadButton_button: 'Upload document',
      fileUploadButton_neu: 'Upload new document',

      sicherheitsdatenblaetterList_loading: 'Loading safety data sheets',
      sicherheitsdatenblaetterList_artikel: 'Article',

      ergebnisAndChart_chart_besuch: 'Show chart of last results',
      ergebnisAndChart_entwicklung: 'Last results',

      ergebnisseTable_zeitpunkt: 'Date',
      ergebnisseTable_zeitraum: 'Period',
      ergebnisseTable_berater: 'Your Consultant',
      ergebnisseTable_cache_leeren: 'Clear cache',
      ergebnisseTable_aufbereiten: 'Results are calculated and processed. Please be patient',
      ergebnisseTable_leer: 'No results.',
      ergebnisseTable_sdb: 'SDS',
      ergebnisseTable_cache_leer: 'Cache has been cleared. Please reload results.',

      sicherheitsdatenblaetter: 'SDS / productinformations',
      sicherheitsdatenblaetter_sdb: 'Safety data sheets / product infos',
      sicherheitsdatenblaetter_allgemein: 'General documents',
      sicherheitsdatenblaetter_eigene: 'My documents',

      biotechFooter_kontakt: 'Contact',
      biotechFooter_impressum: 'Imprint',
      biotechFooter_datenschutz: 'Data protection',
      biotechFooter_agb: 'GTC',
      biotechFooter_bienenblog: 'Bee blog',

      biotechHeader_home: 'Home',
      biotechHeader_dashboard: 'Dashboard',
      biotechHeader_besuchsberichte: 'Visit reports',
      biotechHeader_sdb: 'SDS/documents',
      biotechHeader_logout: 'Logout',
      biotechHeader_logged_in_as: 'You are logged in as {0}. All activities are carried out as this user!',
      biotechHeader_support: 'Support request',

      captchaFormRow_label: 'security code',
      captchaFormRow_placeholder: 'Please enter the security code.',

      spinner_loading: 'Loading data',

      anzahlMaengelIcon_hover: 'Show TODO list',

      editMangelPrioritaet_hoch: 'high',
      editMangelPrioritaet_mittel: 'medium',
      editMangelPrioritaet_gering: 'low',

      imageCarousel_aufgenommen_am: 'recorded on',
      imageCarousel_aufgenommen_von: 'recorded from',
      imageCarousel_detailansicht: 'Detail view',
      imageCarousel_delete_sure: 'Are you sure to delete the image?',
      imageCarousel_abgeschlossen: 'completed',
      imageCarousel_erledigt: 'resolved',

      maengelTable_alert: 'There are no active deficiencies.',
      maengelTable_show_abgeschlossen: 'Show completed deficiencies',
      maengelTable_keine_bilder: 'No images available',
      maengelTable_bilder_hochladen: 'Upload image',
      maengelTable_bilder_hochladen_abschliessen: 'Upload image and complete deficiency.',
      maengelTable_bilder_hochladen_desc: 'Upload a new image that shows and documents the deficiency.',
      maengelTable_bilder_hochladen_abschliessen_desc: 'Upload a new image that documents the resolution of the deficiency. The deficiency will be completed after uploading.',
      maengelTable_bilder_hochladen_abschliessen_customer: 'Upload image and resolve.',
      maengelTable_bilder_hochladen_abschliessen_desc_customer: 'Upload a new image that documents the resolving of the deficiency. The deficiency will be resolved after uploading.',
      printMaengel_kein_bild_title: 'Upload error',
      printMaengel_kein_bild: 'Uploaded File {0} is not a jpg or png format. Please upload jpg or png format.',
      maengelTable_show_details: 'Show details',
      maengelTable_upload_erfolgreich: 'Image {0} was uploaded successfully.',
      maengelTable_upload_error: 'Error uploading image {0}. Please try again or contact a BioTech representative.',
      maengelTable_details: 'Edit details/deficienies',
      maengelTable_aufgenommen_am: 'Recorded the ',
      maengelTable_aufgenommen_von: 'Recorded by',
      maengelTable_abgeschlossen: 'Completed the',
      maengelTable_pos_beschreibung: 'Position',
      maengelTable_add_notiz: 'Add note',
      maengelTable_add_notiz_ph: 'Insert note',
      maengelTable_abschliessen: 'complete',
      maengelTable_schliessen: 'close',
      maengelTable_text1: 'Are you sure you want to set the deficiency to the status "resolved"?',
      maengelTable_text2: 'Are you sure that the deficiency has been completed?',
      maengelTable_confirm_delete: 'Are you sure to delete the deficiency {0}?',

      newPasswordFormRow_passwort_wiederholen: 'Repeat password',
      newPasswordFormRow_rule1: 'The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character ($@$!%*#?&). The password must be at least 8 characters long.',
      newPasswordFormRow_rule2: 'The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character ($@$!%*#?&). The password must be at least 16 characters long.',
      newPasswordFormRow_passwort_error: 'The two passwords do not match',

      maengelList_nr: 'No.',

      mangelDetails_entfernen: 'Remove deficiency from plan',

      objekteDetails_typ: 'Type',
      objekteDetails_artikel_no: 'Article number',

      planList_neu: 'new plan',
      planList_hochladen: 'Upload plan',
      planList_bemerkung_freigabe: 'Remark on release',
      planList_loading: 'Loading plans',
      planList_austauschen: 'If you want to replace the plan, please first create a new plan.',
      planList_p: 'Choose a name for your plan and upload it. Allowed file types are JPG, GIF, PNG or PDF. Maximum file size: 10 MB.',
      planList_bezeichnung: "Plan title",
      planList_ueberprueft: 'Plan reviewed and approved',
      planList_upload: 'Please select file or drag file here',
      planList_thumbnail: 'Thumbnail',
      planList_hochgeladen_am: 'uploaded the',
      planList_freigegeben_am: 'released the',
      planList_freigegeben_von: 'released by',
      planList_loeschen: 'Don you really want to delete the plan {0}? All settings related to this plan will be removed.',
      planList_error: 'There was an error while uploading the plan. Please make sure that the plan does not exceed the maximum file size of 10MB. Allowed file types are jpg, gif, png or pdf.',
      planList_datei: 'File',
      planList_ohne_file: 'use empty plan',

      positionDetails_zusatzleistung: 'Additional service',
      positionDetails_charts: 'Charts',
      positionDetails_installierteObjekte: 'Installed devices/indicators',
      positionDetails_seriennummer: 'Serialnumber',
      positionDetails_statusErgebnis: 'Status/result',
      positionDetails_pos_entfernen: 'Remove position from plan',

      searchPosition_keine_gefunden: 'No position found searching for',

      selectBereich_alle: 'All areas',
      selectBereich_keine_bezeichnung: 'no description',

      selectBereiche_mind: 'Please select at least one area',

      selectPlan_select: 'Please choose a plan',
      selectPlan_freigabe: 'Released the',

      selectPosition_select: 'Manage positions',

      showPositionInMap_select_plan: 'Select plan',

      person_quali: 'Qualifications',

      schulungsunterlagen_kompetent: 'Qualifications',
      schulungsunterlagen_kompetenz_anzeigen: 'Show qualifications',

      deviceHistory_aeltere: 'Show older messages',
      deviceHistory_vorherige: 'Previous results',
      deviceHistory_noch_keine: 'No previous results',
      deviceHistory_zeitpunkt: 'Time message',
      deviceHistory_befall: 'description of infestation',
      deviceHistory_akku: 'Battery level',

      deviceStatus_befall: 'Infestation detected.',
      deviceStatus_kritisch: 'Activity critical.',
      deviceStatus_ausgeloest: 'Device has been triggered.',
      deviceStatus_warnung: 'Activity alert.',
      deviceStatus_falsch: 'Triggered, no catch',
      deviceStatus_funkioniert: 'Device is working properly.',
      deviceStatus_inaktiv: 'Device is inactive.',
      deviceStatus_unterbrochen: 'Lost connection to server.',
      deviceStatus_schwach: 'Low battery.',
      deviceStatus_location: 'Location mode.',
      deviceStatus_wlan: 'Poor WiFi connection.',

      deviceStatusForChartLegende_deinstalliert: '{1} ({0}) was uninstalled the',
      deviceStatusForChartLegende_status: '{1} ({0})',
      deviceStatusForChartLegende_kein_ergebnis: 'No result available for {1} ({0}).',

      showHideTraplinkedStatus_24_7: 'BIOTECH247 status',

      showHideSensorStatus: 'sensor status',

      showTraplinkedReportImage_vergroessern: 'Enlarge picture',
      showTraplinkedReportImage_automatisch: 'Auto image',
      showTraplinkedReportImage_aufgenommene: 'Images',

      traplinkedErgebnisliste_24_7: 'Biotech247 results',

      traplinkedStatus_letzte_meldung: 'Last report',
      traplinkedStatus_24_7_name: 'Biotech247 name',
      traplinkedStatus_meldung: 'Alarm',
      traplinkedStatus_batterie: 'Battery status',
      traplinkedStatus_alt: 'History',

      main_ausgeloest: 'triggered',
      main_unterbrochen: 'disconnected',
      main_batterie: 'low battery',
      main_lokalisierung: 'localization mode',
      main_schlecht: 'bad connection',
      main_kritisch: 'activity critical',
      main_befallen: 'infested',
      main_befallen_leicht: 'lightly infested',
      main_befallen_stark: 'severely infested',
      main_akt_warnung: 'activity alert',
      main_ok: 'ok',
      main_neu: 'reset',
      main_bild: 'picture taken',
      main_online: 'online',
      main_konfiguriert: 'configured',
      main_gewartet: 'waiting',
      main_unerledigt: 'active',
      main_bearbeitet: 'edited',
      main_abgeschlossen: 'completed',

      support_h1: 'Request BIO-TECH employees',
      support_mon_pos: 'Monitoring position',
      support_mon_mangel: 'Deficiency',
      support_tel: 'Phone number',
      support_meine_tel: 'My phone number',
      support_meine_tel_err: 'Please enter your phone number for queries.',
      support_problemtyp: 'Problem type',
      support_thema: 'Theme',
      support_thema_placeholder: 'I need support from BIO-TECH to theme:',
      support_thema_err: 'Please describe your request in brief',
      support_problem_beschreibung: 'Problem description',
      support_problem_beschreibung_err: 'A detailed description helps us to react properly to your problem',
      support_problem_foto_auswaehlen: 'Select a photo or drag it here',
      support_problem_foto_ziehen: 'Drag a photo here',
      support_danke: 'Thank you, your request has been successfully submitted. A BIO-TECH employee will contact you as soon as possible.',
      support_error: 'Sorry, there was an error while saving your request. Please try again later or contact us by phone.',

      besuchsberichtErgebnisse_deinstalliert_am: 'deinstalled the',

      searchPosition: 'search position',

      selectObjekttypen_filtern: 'Filter indicators',

      selectBereiche_filtern: 'Filter areas',

      traplinkedImages_nav: 'Check Biotech247 images',
      traplinkedImages_gruen: 'OK',
      traplinkedImages_gelb: 'warning',
      traplinkedImages_rot: 'danger',
      traplinkedImages_alt: 'image to check',
      traplinkedImages_date_time: 'date/time:',
      traplinkedImages_support: 'create support request',
      traplinkedImages_ergebnis_eingeben: 'Please insert a result.',
      traplinkedImages_empty: 'Congratulations! There are no unrestricted pictures.',
      traplinkedImages_success: 'Your input has been successfully saved.',
      traplinkedImages_delete_confirm: 'Are you sure to delete the picture?',
      traplinkedImages_delete_confirm_checked: 'Are you sure to delete selected pictures?',
      traplinkedImages_ergebnis_checked: 'Are you sure to save the result for selected pictures?',
      traplinkedImages_success_title: 'Input saved.',
      traplinkedImages_success_delete: 'The image has been successfully deleted',
      traplinkedImages_success_delete_checked: 'The selected images has been successfully deleted',
      traplinkedImages_success_title_delete: 'Successfully deleted.',

      sensorStatus_name: 'Label',
      sensor_vorherige: 'Previous sensor results',
      sensor_chart_title: 'Sensor results',
      sensor_charts_warnung: 'warning',
      sensor_charts_gefahr: 'danger',
      sensor_charts_ok: 'OK',
      sensor_temp: 'Temperature °C',
      sensor_luftfeuchtigkeit: 'Humidity %',
      sensor_vorher: 'previous month',
      sensor_naechstes: 'next month',
      sensor_day: 'dayle view',
      sensor_week: 'weekly view',
      sensor_month: 'monthly view',
      sensor_vorher_week: 'previous week',
      sensor_naechstes_week: 'next week',
      sensor_vorher_day: 'previous day',
      sensor_vorher_24h: 'last 24h',
      sensor_naechstes_day: 'next day',
      sensor_taupunkt_anzeigen: 'show dew points',
      sensor_taupunkt_ausblenden: 'hide dew points',
      sensor_schwellenwert_anzeigen: 'show thresholds',
      sensor_schwellenwert_ausblenden: 'hide thresholds',
      sensor_hide_x: 'hide date/time',
      sensor_show_x: 'show date/time',
      sensor_alarm_nach: 'after',
      sensor_alarm_minuten: 'minutes',

      show_benutzer_of_customer_none: 'No users other than your own exist for the location.',

      check_passwort_sicherheit_desc: 'Your password does not meet security requirements. Please change your password.',
      check_passwort_sicherheit_change: 'Change password',

      benutzerModel_keinStandort: 'Are you sure you don\'t want to assign a location to the user?',

    }
  }
}
//////////////////////////////////////////////////////////////////
