<template>

    <div class="plan">

      <b-sidebar id="sidebar" title="Einstellungen" shadow width="340px" v-if="editPlanRight">

        <div class="accordion" role="tablist" id="my-accordion">

          <!-- Pläne verwalten -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   id="dropdownMenuButton1"
                   style="cursor: pointer"
                   v-b-toggle.accordion-1
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10"><h6 style="text-align: left; padding-left: 1rem">{{ $t("message.plan_plaene") }}</h6></div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <!-- Pläne verwalten - nur für Key User -->
                <router-link to="/PlanList"
                             class="btn btn-outline-primary"
                             href="#">
                  <b-icon icon="map"></b-icon>&nbsp;{{ $t("message.plan_plaene_verwalten") }}
                </router-link>

              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Pläne freigeben -->
          <b-card no-body class="mb-1" v-if="plan">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   style="cursor: pointer"
                   id="dropdownMenuButton2"
                   v-b-toggle.accordion-2
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10"><h6 style="text-align: left; padding-left: 1rem">{{ $t("message.plan_freigeben") }}</h6></div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <b-form-checkbox
                    id="checkbox-plan-freigegeben"
                    v-model="freigegeben"
                    name="checkbox-plan-freigegeben"
                    value="true"
                    unchecked-value="false">
                    {{ $t("message.plan_freigeben_ueberprueft") }}
                </b-form-checkbox>
                <small v-if="revisionsDatum">
                    {{ $t("message.plan_freigeben_von") }}: <print-user v-model="revisionUser.id" /> {{ $t("message.plan_freigeben_am") }}: {{ javaDateToDefaultDateFormat(revisionsDatum) }}
                </small>
                <b-form-textarea
                    id="textareaRevisionBemerkung"
                    v-model="revisionBemerkung"
                    :placeholder='$t("message.plan_bemerkungen") + " ..."'
                    class="mt-2"
                    rows="3"
                />
                <b-button variant="outline-primary"
                          size="sm"
                          @click="doPlanFreigeben()"
                          class="mt-2">
                  <b-icon icon="check2"></b-icon>&nbsp;{{ $t("message.default_speichern") }}
                </b-button>

              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Mängel einzeichnen die keine Position haben -->
          <b-card no-body class="mb-1" v-if="plan && maengelOhnePosition">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   style="cursor: pointer"
                   id="dropdownMenuButton3"
                   v-b-toggle.accordion-3
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10"><h6 style="text-align: left; padding-left: 1rem">{{ $t("message.plan_maengel_einzeichnen") }}</h6></div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <maengel-list :maengel="maengelOhnePosition" v-on:drag-mangel="setDragMangel" />

              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Filtern -->
          <b-card no-body class="mb-1" v-if="plan">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   style="cursor: pointer"
                   id="dropdownMenuButton4"
                   v-b-toggle.accordion-4
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10"><h6 style="text-align: left; padding-left: 1rem">{{ $t("message.positionen_filter") }}</h6></div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <select-objekttypen v-model="objekttypen"
                                    class="mb-2"
                                    v-on:set-selected-objekttypen="setSelectedObjekttypen">
                </select-objekttypen>
                <select-bereiche :plan="plan" v-on:set-areas="setAreas" />

              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Positionen verwalten -->
          <b-card no-body class="mb-1" v-if="plan">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   style="cursor: pointer"
                   id="dropdownMenuButton5"
                   v-b-toggle.accordion-5
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10">
                  <h6 style="text-align: left; padding-left: 1rem">
                      {{ $t("message.plan_positionen") }}
                  </h6>
                </div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <strong v-if="!areas || areas.length < 1">{{ $t("message.plan_positionen_alle") }}:</strong>
                <strong v-else-if="areas && areas.length > 0">{{ $t("message.plan_positionen_filter") }}:</strong>

                <position-list v-model="position"
                               class="mt-2"
                               :drawn-positions="drawnPositions"
                               :areas="areas"
                               :plan="plan"
                               :trigger="triggerPositionList"
                               v-on:drag-position="setDragPosition"
                />

              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Markierungen -->
          <b-card no-body class="mb-1" v-if="plan">
            <b-card-header header-tag="header" class="p-1" role="tab" style="border-bottom: 0">
              <div class="row"
                   style="cursor: pointer"
                   id="dropdownMenuButton6"
                   v-b-toggle.accordion-6
                   aria-haspopup="true"
                   aria-expanded="false">
                <div class="col-sm-10"><h6 style="text-align: left; padding-left: 1rem">{{ $t("message.plan_markierungen") }}</h6></div>
                <div class="col-sm-2"><b-icon icon="arrow-bar-down"></b-icon></div>
              </div>
            </b-card-header>
            <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
              <b-card-body>

                <b-button variant="outline-primary" @click="startPolygonDraw()" v-b-toggle.sidebar>
                  <b-icon icon="bounding-box"></b-icon>&nbsp;&nbsp;{{ $t("message.plan_markierung_neu") }}
                </b-button>

              </b-card-body>
            </b-collapse>
          </b-card>

        </div>

      </b-sidebar>

      <div class="row mt-4">

        <!-- Einstellungen öffnen -->
        <div class="col-md-2">
            <b-button v-b-toggle.sidebar variant="outline-primary" v-if="editPlanRight">
              <b-icon icon="gear"></b-icon>&nbsp;{{ $t("message.plan_einstellungen") }}
            </b-button>
        </div>
        <!-- Plan auswählen -->
        <div class="col-md-4" v-if="!showPositionLoadingSpinner">
          <select-plan v-model="plan" v-on:set-plaene="setPlaene" />
        </div>
        <!-- Position suchen -->
        <div class="col-md-2" v-if="plan && !showPositionLoadingSpinner && positionsList && positionsList.length > 0">
          <search-position v-model="plan"
                           v-on:zoom-position="zoomPosition"
                           :position-added="positionAdded"
                           :positions-list="positionsList"
                           :position-removed="positionRemoved"
          />
        </div>
        <!-- Bearbeitungsmodus starten -->
        <div class="col-md-2 text-right" v-if="editPlanRight && !showPositionLoadingSpinner">
          <b-button :variant="isBearbeitungsmodus ? 'outline-success' : 'outline-warning'"
                    v-b-tooltip.hover
                    :title="isBearbeitungsmodus ? 'Im aktiven Bearbeitungsmodus können Positionen verschoben werden.' : 'Im inaktiven Bearbeitungsmodus können keine Positionen verschoben werden.'"
                    @click="isBearbeitungsmodus = !isBearbeitungsmodus">
            <b-icon icon="gear"></b-icon>&nbsp;{{ $t("message.plan_bearbeitungsmodus") }} <span v-if="isBearbeitungsmodus">{{ $t("message.plan_aktiv") }}</span><span v-else>{{ $t("message.plan_inaktiv") }}</span>
          </b-button>
        </div>

        <!-- Plan drucken
        <div class="col-md-2 text-right" v-if="plan">
            <b-overlay
                    :show="printPlan"
                    rounded
                    opacity="0.6"
                    spinner-small>
              <b-button variant="outline-primary"
                        id="popover-target-1"
                        @click="print()">
                  <b-icon icon="printer"></b-icon> drucken
              </b-button>
              <b-popover target="popover-target-1" triggers="hover" placement="bottom">
                  <template #title>PDF exportieren</template>
                  Bitte wählen Sie einen sichtbaren Ausschnitt ihres Plan. <br/><br/>Der Ausschnitt wird als PDF exportiert und
                  kann über ihre PDF Software ausgedruckt oder archiviert werden.
              </b-popover>
            </b-overlay>
        </div>
        -->

      </div>
      <transition name="fade" mode="out-in">
          <div class="row mt-3" v-if="!hasPlaene">
              <div class="col">
                  <b-alert variant="warning" show>
                      <router-link to="/PlanList"
                                   class="btn btn-outline-primary mr-3"
                                   href="#">
                          <b-icon icon="map"></b-icon>&nbsp;{{ $t("message.plan_verwalten") }}
                      </router-link>
                      {{ $t("message.plan_empty") }}
                  </b-alert>
              </div>
          </div>
      </transition>
      <transition name="fade" mode="out-in">
          <div class="row mb-4 mt-4" v-if="plan">
            <div class="col-md-12">
              <div class="content_title">
                <h1>{{ $t("message.plan") }} - {{ plan.bezeichnung }}</h1>
              </div>
            </div>

          </div>
      </transition>

      <div id="planWrapper" class="pt-3">

        <!-- anzeigen welche Position eingezeichnet werden soll -->
        <transition name="fade" mode="out-in">
          <b-alert show v-if="dragPosition" dismissible v-on:dismissed="removeDragPosition">
            Position in Plan einzeichen: {{ dragPosition.positionsNr }} {{ dragPosition.positionsBesch }}
          </b-alert>
        </transition>
        <transition name="fade" mode="out-in">
          <b-alert show v-if="dragMangel" dismissible v-on:dismissed="removeDragMangel">
            Mangel in Plan einzeichen: {{ $t('message.maengelList_nr') }} {{ dragMangel.nummer }}: {{ dragMangel.descriptionShort }}
          </b-alert>
        </transition>

        <div id="map"></div>

        <!-- Popup um eine Mausbewegung zu provozieren wenn Icon gesetzt wird -->
        <div id="popup" class="ol-popup" style="display: none">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content">{{ $t("message.plan_position_saved") }}</div>
        </div>

        <!-- Loading anzeigen -->
        <transition name="fade" mode="out-in">
          <div class="row" id="positionLoadingSpinner" v-if="showPositionLoadingSpinner">
            <div class="col"></div>
            <div class="col">

              <b-card
                  :title="$t('message.plan_position_loading') + ' ...'"
                  style="max-width: 20rem;"
                  class="mb-2">
                <b-card-text class="text-center">
                  <SpinnerImage />
                </b-card-text>
              </b-card>

            </div>
            <div class="col"></div>
          </div>
        </transition>

      </div>

      <!-- Modal für Polygon -->
      <b-modal id="modal-polygon"
               :title="polygonModalTitle"
               size="lg"
               :hide-footer="true">

        <form>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">{{ $t("message.plan_bezeichnung") }}</label>
            <div class="col-sm-10">
              <b-form-input v-model="polygonBezeichnung"
                            :placeholder="$t('message.plan_bezeichnung_placeholder')"
                            max-length="250"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">{{ $t("message.plan_farbe") }}</label>
            <div class="col-sm-10">
              <input type="color"
                     id="html5colorpicker"
                     v-model="polygonBackground"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="formControlRange" class="col-sm-2 col-form-label">{{ $t("message.plan_transparenz") }}</label>
            <div class="col-sm-10">
              <input type="range"
                     class="form-control-range"
                     id="formControlRange"
                     v-model="polygonTransparenz"
              />
            </div>
          </div>
          <!-- Rahmenfarbe -->
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">{{ $t("message.plan_rahmen_farbe") }}</label>
            <div class="col-sm-10">
              <input type="color"
                     id="html5colorpickerRahmen"
                     v-model="polygonRahmenColor"
              />
            </div>
          </div>
        </form>

        <div class="form-group row">
          <div class="col-sm-10">
            <button type="button"
                    v-if="polygonEditId"
                    @click="savePolygonChanges()"
                    class="btn btn-primary">
              <b-icon icon="check2-square"></b-icon>&nbsp;{{ $t("message.default_speichern") }}
            </button>
            <button type="button"
                    v-else
                    @click="addDrawInteraction()"
                    class="btn btn-primary">
              <b-icon icon="bounding-box"></b-icon>&nbsp;&nbsp;{{ $t("message.plan_starten") }}
            </button>
            <!-- Benutzer löschen wenn kein Key User -->
            <b-button variant="outline-danger"
                      v-if="polygonEditId"
                      size="sm"
                      @click="deletePolygon(polygonEditId)"
                      class="ml-1">
              <b-icon icon="trash"></b-icon>&nbsp;{{ $t("message.default_loeschen") }}
            </b-button>
          </div>
        </div>

      </b-modal>

      <!-- Modal für Position Details -->
      <b-modal id="modal-position-details"
               :title="positionModalTitle"
               size="lg"
               :hide-footer="true"
               v-on:hidden="hiddenPositionModal">

        <position-details v-model="position"
                          v-if="position"
                          v-on:position-modal-title="setPositionModalTitle"
                          v-on:remove-position-from-plan="removePositionFromPlan"
                          v-on:mangel-prioritaet-changed="mangelPrioritaetChanged"
        />
        <mangel-details v-model="mangel"
                        v-else-if="mangel"
                        v-on:remove-mangel-from-plan="removeMangelFromPlan"
                        v-on:mangel-prioritaet-changed="mangelPrioritaetChanged"
        />


      </b-modal>

    </div>

</template>

<script>

  /** passe das Plan Fenster an die Fenstergröße an */
  function changeWindowSize() {
    var height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
    $('#planWrapper').css('height', (height - 300) + 'px');
  }
  window.addEventListener('resize', changeWindowSize);

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import bootbox from "bootbox";
  import SelectPlan from '@/components/plan/SelectPlan';
  import SelectBereiche from '@/components/plan/SelectBereiche';
  import PositionList from '@/components/plan/PositionList';
  import PositionDetails from "@/components/plan/PositionDetails";
  import SpinnerImage from "@/components/layout/SpinnerImage";
  import SelectObjekttypen from "@/components/plan/SelectObjekttypen";
  import SearchPosition from "@/components/plan/SearchPosition";
  // import openlayer css for style
  import "ol/ol.css";
  // This is library of openlayer for handle map
  import Map from "ol/Map";
  import View from "ol/View";
  import ImageLayer from 'ol/layer/Image';
  import Projection from 'ol/proj/Projection';
  import Static from 'ol/source/ImageStatic';
  // Draw für Polygone zeichnen
  import {Draw, Modify} from 'ol/interaction';
  import VectorSource from 'ol/source/Vector';
  import {getCenter} from 'ol/extent';
  import VectorLayer from 'ol/layer/Vector';
  import {Circle as CircleStyle, Fill, Stroke, Style, Icon, Text} from 'ol/style';
  import Polygon from 'ol/geom/Polygon';
  import Feature from 'ol/Feature';
  import Overlay from 'ol/Overlay';
  // Point für Position Icon
  import Point from 'ol/geom/Point';
  import { PlanHelper } from '@/components/plan/PlanHelper'
  // Drag und Drop
  import {
    Pointer as PointerInteraction,
    defaults as defaultInteractions,
  } from 'ol/interaction';
  import PrintUser from '@/components/benutzer/PrintUser';
  import MaengelList from "@/components/plan/MaengelList";
  import MangelDetails from "@/components/plan/MangelDetails";
  import $ from 'jquery';
  import { jsPDF } from "jspdf";

  export default {
    name: 'Plan',
    components: {
      SelectPlan,
      PositionList,
      PositionDetails,
      SpinnerImage,
      SelectObjekttypen,
      SearchPosition,
      PrintUser,
      SelectBereiche,
      MaengelList,
      MangelDetails
    },
    data () {
      return {
        /** der Plan der angezeigt und bearbeitet wird */
        plan: null,
        /** hat der Kunde schon Pläne hochgeladen? */
        hasPlaene: true, // mal auf true setzen, damit nicht angezeigt wird, dass keine Pläne vorhanden sind während geladen wird
        /** die Area die bearbeitet werden soll */
        area: null,
        /** Liste von Areas nach denen gefiltert wird */
        areas: null,
        /** die Position die bearbeitet werden soll */
        position: null,
        /** die Bezeichnung der aktuellen Position */
        positionModalTitle: this.$t('message.plan_position_details'),
        /** die Open Layer Karte */
        map: null,
        /** Map views always need a projection. */
        imageLayerProjection: null,
        /** Layer mit dem Bild der Karte */
        imageLayer: null,
        /** OpenLayer View */
        view: null,
        // draw: null,
        // snap: null,
        /** Source - Datenquelle für Polygone für Areas */
        vectorSource: null,
        /** Layer für Vector zum zeichnen von Polygonen */
        vectorLayer: null,
        /** die Position die aktuell vom Benutzer  in den Plan gezogen wird */
        dragPosition: null,
        /** der Mangel der vom Benutzer in den Plan gezogen wird */
        dragMangel: null,
        /** aktuelle Position der Maus auf der Karte */
        mouseCoordinate: null,
        /** temporäres Zwischenspeichern des iconFeature eines neu erzeugten Icons um bei Mousemove die Position zu setzen */
        iconFeature: null,
        /** Liste mit Positions IDs die eingezeichnet sind */
        drawnPositions: [],
        positionsList: [],
        /** Style für den default Icon */
        iconStyleDefault: null,
        /** Feature das gerade bearbeitet wird */
        currentFeature: null,
        /** Loading Spinner beim Laden der Positionen anzeigen */
        showPositionLoadingSpinner: false,
        /** die Positionsliste mit Trigger neu holen */
        triggerPositionList: 0,
        /** ein Popup über der Karte */
        popupOverlay: null,
        /** nur die ausgewählten Objekttypen sollen angezeigt werden */
        selectedObjekttypen: null,
        /** Objekttypen die am Plan dargestellt werden, wird zur Filterung von Objekttypen benötigt */
        objekttypen: [],
        /** Offset des Icons */
        iconAnchorY: 46,
        iconAnchorX: 13,
        /** Bearbeitungsmodus wurde gestartet - Positionen können verschoben werden */
        isBearbeitungsmodus: false,
        /** zum Abbrechen von Ajax requests */
        cancelTokenSource: null,
        /** eine neue Position wurde im Plan eingezeichnet */
        positionAdded: null,
        /** eine Positon wurde aus dem Plan entfernt */
        positionRemoved: null,
        /** Plan wurde freigegeben */
        freigegeben: 'false', // String da value der Checkbox
        /** Anmerkung zur Plan Freigabe */
        revisionBemerkung: null,
        /** User der den Plan freigegeben hat */
        revisionUser: null,
        /** freigegeben am */
        revisionsDatum: null,
        /** zu dieser Position zoomen */
        zoomPositionId: null,
        /** Mängel die keine Position haben zum Einzeichnen in den Plan */
        maengelOhnePosition: null,
        /** Mangel der in den Detail angezeigt wird */
        mangel: null,
        /** Typ zum Zeichnen von Polygonen */
        drawType: 'Polygon',
        /** globales draw Objekt zum Zeichnen von Polygonen */
        draw: null,
        /** Polygon einzeichnen - Modal Titel */
        polygonModalTitle: this.$t('message.plan_markierung_zeichnen'),
        /** Bezeichnung des Polygons bei einzeichnen oder ändern */
        polygonBezeichnung: null,
        /** Transparenz des Polygons */
        polygonTransparenz: null,
        /** Hintergrundfarbe des Polygons */
        polygonBackground: '#ff0000',
        /** Farbe des Rahmens */
        polygonRahmenColor: '#ff0000',
        /** RGBA der Hintergrundfarbe */
        polygonRgba: null,
        /** RGBA der Hintergrundfarbe des Rahmens */
        polygonRgbaStroke: null,
        /** Polygon das gerade geändert wird */
        polygonEditId: null,
        /** Polygone können damit geändert werden: modify Objekt: */
        polygonModify: null,
        /** Overlay anzeigen wenn Plan gedruckt wird */
        printPlan: null
      }
    },
    computed: {

      /** darf der Benutzer den Plan ändern? */
      editPlanRight: function () {

        return this.editPlan();

      }

    },
    async mounted () {

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);

      var height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
      console.log('window height: ' + height);
      var backimg = "url(" + require('@/assets/images/BIOTECH-Logo-RGB-300ppi-tra.png') + ')';
      $('#planWrapper').css('height', (height - 300) + 'px').css('backgroundImage', backimg);

      this.iconStyleDefault = {
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: require('@/assets/images/icons/map-marker-BLACK.svg'),
          crossOrigin: 'anonymous',
        }),
      };

      /** Popup über der Karte initialisieren */
      this.initPopup();

      // wurde eine Plan ID per GET übergeben?
      if (this.$route.params.id) {

        // hole den Plan:
        console.log('Zeige Plan: ', this.$route.params);
        this.readPlan(this.$route.params.id);

      }
      if (this.$route.params.position_id) {

        console.log('Position zoomen: ' + this.$route.params.position_id);
        this.zoomPositionId = this.$route.params.position_id;

      }

      // hole Mängel ohne Position:
      this.findMaengelOhnePosition();

    },
    watch: {

      /** wenn Bearbeitungsmodus aktiviert wird, dann können Polygone geändert werden */
      isBearbeitungsmodus: function (newVal) {

        if (newVal) {

          // Polygon Bearbeitung erlauben:
          this.polygonModify = new Modify({source: this.vectorSource});
          this.map.addInteraction(this.polygonModify);
          let that = this;
          this.polygonModify.on('modifyend', function (event) {

            console.log('modifyend: ' + event);
            let feature = event.features.array_[0];
            if (feature && feature.values_ && feature.values_.polygon_id)  {
              let geometry = feature.getGeometry();
              that.savePolygonCoordinatesChanges(feature.values_.polygon_id, geometry.flatCoordinates);
            }

          })

        } else {
          this.map.removeInteraction(this.polygonModify);
          this.polygonModify = null;
        }

      },
      /** Farbe des Rahmens des Polygons */
      polygonRahmenColor: function (newVal) {

        console.log('edit polygonBackground Rahmen' + newVal);
        if (this.polygonEditId) {

          let style  = this.currentFeature.getStyle();
          let stroke = style.getStroke();
          const rgba = this.hexTorgb(newVal);

          this.polygonRgbaStroke = rgba.join(',');

          // Rand Farbe setzen:
          const color = 'rgba(' + rgba[0] + ',' + rgba[1] + ',' + rgba[2] + ',1)';
          stroke.setColor(color);
          style.setStroke(stroke);

          this.currentFeature.setStyle(style);

        }

      },
      /**
       * der Hintergrund des Polygons wird geändert
       * @param newVal
       */
      polygonBackground: function (newVal) {

        console.log('edit polygonBackground' + newVal);
        if (this.polygonEditId) {

          let style  = this.currentFeature.getStyle();
          let fill   = style.getFill();
          let color  = fill.getColor();

          // rgba(a,b,c,d)
          const index = color.lastIndexOf(',');
          let transparent = color.substring(index, color.length);
          let rgba = this.hexTorgb(newVal);

          rgba.push(transparent.replace(',', '').replace(')', ''));
          this.polygonRgba = rgba.join(',');

          // Hintergrund Farbe setzen:
          color = 'rgba(' + rgba[0] + ',' + rgba[1] + ',' + rgba[2] + transparent;
          console.log('polygonTransparenz setzen: ' + color);
          fill.setColor(color);
          style.setFill(fill);

          this.currentFeature.setStyle(style);

        }

      },
      /**
       * Transparenz eines Polygons wird geändert
       * @param newVal
       */
      polygonTransparenz: function (newVal) {

        if (this.polygonEditId) {

          let style = this.currentFeature.getStyle();
          let fill  = style.getFill();
          let color = fill.getColor();

          // rgba(a,b,c,d)
          let index = color.lastIndexOf(',');
          color = color.substring(0, index+1) + (newVal/100) + ')'
          console.log('polygonTransparenz setzen: ' + color);
          this.polygonRgba = color.replace('rgba(', '').replace(')', '');

          fill.setColor(color);
          style.setFill(fill);
          this.currentFeature.setStyle(style);

        }

      },
      /** Plan wurde ausgewählt */
      plan: function (newVal, oldVal) {

        if (!oldVal || (newVal.id != oldVal.id)) {

          console.log('Plan geändert auf: ' + newVal.bezeichnung);

          // Revisionsdaten setzen:
          if (newVal.revisionsDatum) {
            this.freigegeben        = 'true';
            this.revisionsDatum     = newVal.revisionsDatum;
          } else {
            this.freigegeben        = 'false';
            this.revisionsDatum     = null;
          }
          this.revisionUser         = newVal.revisionUser;
          this.revisionBemerkung    = newVal.revisionBemerkung;
          this.isBearbeitungsmodus  = false;

          // cache leeren:
          var that = this;
          if (this.map) {
            this.map.getLayers().forEach(layer => {
              if (layer.get('name') && layer.get('name') == 'flag_vectorLayer') {
                that.map.removeLayer(layer)
              }
            });
          }

          /** breche vorherige Requests zum Plan ab */
          if (this.cancelTokenSource) {
            // Cancel request
            this.cancelTokenSource.cancel();
          }

          /** erzeuge einen neuen Source token um Request abbrechen zu können */
          this.cancelTokenSource = axios.CancelToken.source();

          this.getPlanData(newVal);

        }

      }

    },
    methods: {

      /** Mangel doch nicht mehr einzeichnen */
      removeDragMangel: function () {
        this.dragMangel = null
        document.body.style.cursor = 'default'
      },
      removeDragPosition: function () {
        this.dragPosition = null
        document.body.style.cursor = 'default'
      },
      /**
       * hole Mängel ohne Position um diese im Plan einzuzeichnen
       */
      findMaengelOhnePosition: function () {

        this.maengelOhnePosition = null;
        var that          = this;
        const url         = process.env.VUE_APP_SERVER_URL + '/datenbroker/findMaengelOhnePosition';
        const customer    = this.$store.getters.customer;
        const params      = {
          customer: JSON.stringify(customer)
        };

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then( response => {

          if (response.data && response.data.length > 0) {
            that.maengelOhnePosition = response.data;
          }

        });

      },
      /**
       * der aktuell geöffnete Plan wird freigegeben
       */
      doPlanFreigeben: function () {

        var that          = this;
        var url           = process.env.VUE_APP_SERVER_URL + '/plan/savePlanRevision';
        var params        = {
          'plan_id': this.plan.id,
          'revisionBemerkung': this.revisionBemerkung,
          'freigegeben': this.freigegeben
        };

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          if (response.data.id) {
            that.revisionUser   = response.data.revisionUser;
            that.revisionsDatum = response.data.revisionsDatum;
            bootbox.alert(that.$t('message.plan_freigabe_ok'));
          } else {
            bootbox.alert(that.$t('message.plan_freigabe_error'));
          }

        });

      },
      /** Zoome zu einer Position */
      zoomPosition: function (position) {

        console.log('Zoom to position ' + position);

        if (position.coordinates) {
          this.zoomToCoordinates(position.coordinates);
        } else {

          PlanHelper.getPositionCoordinates(position).then(response => {
            if (response) {
              this.zoomToCoordinates(response)
            }
          })

        }

        this.zoomPositionId = null

      },
      zoomToCoordinates: function (coordinates) {

        this.view.animate({
          center: coordinates,
          zoom:   5
        });

      },
      /** Plan holen mit dessen ID */
      readPlan: function (id) {

        const customer = this.$store.getters.customer
        PlanHelper.readPlan(id, customer.cardcode, customer.biotechCountry.name).then(plan => {
          this.plan = plan
        })

      },
      /**
       * Areas zum Filtern wurden gesetzt
       */
      setAreas: function (arr) {
        this.areas = arr;
        // ok, dann filtere gleich nach diesen Areas
        this.filterPositionen();
      },
      /** Tasks auswählen - nur die Positionen mit den ausgewählten Tasks werden angezeigt */
      setSelectedObjekttypen: function (obj) {
        this.selectedObjekttypen = obj;
        // ok, dann filtere gleich nach diesen Objekttypen
        this.filterPositionen();
      },
      /**
       * nach Areas und Objekttypen filtern
       */
      filterPositionen: function () {

        // hole alle Icons/Positionen die eingezeichnet sind:
        if (this.vectorSource) {

          let features = this.vectorSource.getFeatures();
          for (let i = 0; i < features.length; i++) {
            // console.log('prüfe Position ob Area angezeigt werden soll ' + features[i].values_.area_id);
            if (features[i].values_ && (features[i].values_.area_id || features[i].values_.objekttyp)) {

              // soll diese Area ausgeblendet werden?
              let showarea = false;
              if (this.areas && this.areas.length > 0) {
                for (var j = 0; j < this.areas.length; j++) {
                  // wenn die area_id null ist, dann hat die Position keine Area, dann trotzdem anzeigen,
                  // da diese ja nicht nach Areas gefiltert werden kann
                  if (this.areas[j].id == features[i].values_.area_id || features[i].values_.area_id == null) {
                    showarea = true;
                    break;
                  }
                }
              }
              let showobjekttyp = false;
              if (this.selectedObjekttypen && this.selectedObjekttypen.length > 0) {
                // soll der Objekttyp ausgeblendet werden?
                for (var j = 0; j < this.selectedObjekttypen.length; j++) {
                  if (this.selectedObjekttypen[j].typ == features[i].values_.objekttyp) {
                    showobjekttyp = true;
                    break;
                  }
                }
              }
              const showit = showobjekttyp && showarea;
              if (!showit) {

                features[i].setGeometry(new Point([-10000, -10000]));

              } else {

                features[i].setGeometry(new Point(features[i].values_.myCoordinate));

              }

            }
          }

        } else {
          console.log('this.vectorSource ist noch null!');
        }

      },
      /** Priorität des Mangels wurde in Detailansicht geändert, an Parent weitergeben */
      mangelPrioritaetChanged: function (mangel) {

        let src = require('@/assets/images/icons/map-MANGEL-NICHT_PRUEFBAR.svg');
        let color = '#17a2b8';
        if (mangel && mangel.mangelPrioritaet.name == 'MITTLERE_GEFAHR') {
          src = require('@/assets/images/icons/map-MANGEL-GELB.svg');
          color = '#eda512';
        } else if (mangel && mangel.mangelPrioritaet.name == 'HOHE_GEFAHR') {
          src = require('@/assets/images/icons/map-MANGEL-ROT.svg');
          color = '#e3150f';
        }
        console.log('neues Mangel Icon nach ändern der Priorität: ' + src);

        // Icon ändern
        var features = this.vectorSource.getFeatures();
        for (let i = 0; i < features.length; i++) {
          if (
              features[i].values_ &&
              (
                  features[i].values_.mangel_id === mangel.id
                  ||
                  ( features[i].values_.isMangel && mangel.position && features[i].values_.position_id == mangel.position.id )
              )

          ) {

            // here I'm changing it
            const textstyle = features[i].getStyle().getText();
            const rotation  = features[i].getStyle().getImage().rotation_;

            // geänderten Icon hinzufügen:
            var icon  = {
              image: new Icon({
                anchor: [this.iconAnchorX, this.iconAnchorY],
                anchorXUnits: 'pixels',
                anchorYUnits: 'pixels',
                src: src,
                rotation: rotation ? rotation : 0
              }),
            };
            features[i].setStyle(new Style(icon));
            const fill = new Fill({
              color: color
            });
            textstyle.setFill(fill);
            features[i].getStyle().setText(textstyle);
            break;

          }
        }

      },
      /** Mangel aus Plan entfernen */
      removeMangelFromPlan: function (mangel) {

        // wieder zu den einzuzeichnenden Mängeln hinzufügen
        if (!this.maengelOhnePosition) this.maengelOhnePosition = [];
        this.maengelOhnePosition.push(mangel);
        // Fenster schließen
        this.$bvModal.hide('modal-position-details');
        // Icon entfernen:
        var features = this.vectorSource.getFeatures();
        for (let i = 0; i < features.length; i++) {
          if (features[i].values_ && features[i].values_.mangel_id === mangel.id) {
            // here I'm removing it
            this.vectorSource.removeFeature(features[i]);
          }
        }

      },
      /** Position aus Plan entfernen */
      removePositionFromPlan: function (position_id) {

        this.showSpinner();
        var that          = this;
        var url           = process.env.VUE_APP_SERVER_URL + '/plan/removePositionFromPlan';
        var params        = {
          'position_id': position_id,
          'plan_id':     this.plan.id
        };

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function () {

          that.hideSpinner();
          that.hidePositionDetailModal();
          // Icon entfernen:
          var features = that.vectorSource.getFeatures();
          for (var i = 0; i < features.length; i++) {
            console.log('prüfe Position ' + features[i].values_.position_id);
            if (features[i].values_ && features[i].values_.position_id === position_id) {
              // here I'm removing it
              console.log('entferne Position ' + position_id);
              that.vectorSource.removeFeature(features[i]);
            }
          }
          // aus der Liste der eingezeichneten Positionen entfernen:
          var newlist = [];
          for (var i = 0; i < that.drawnPositions.length; i++) {
            if (that.drawnPositions[i] != position_id) {
              newlist.push(that.drawnPositions[i]);
            }
          }
          that.drawnPositions = newlist;
          newlist = [];
          for (var i = 0; i < that.positionsList.length; i++) {
            if (that.positionsList[i].id != position_id) {
              newlist.push(that.positionsList[i]);
            }
          }
          that.positionsList = newlist;
          // die Positionen in der Sidebar müssen neu geladen werden
          that.triggerPositionList++;
          // Position aus Suche entfernen, triggern
          that.positionRemoved = position_id;

        });

      },
      /**
       * Positions Modal wurde geschlossen
       */
      hiddenPositionModal: function () {

        console.log('Position Modal hidden');
        this.position       = null;
        this.currentFeature = null;

      },
      /**
       * Text des Icons setzen
       */
      setPositionIconText: function (iconFeature, positionsnr, alarmLevel) {

        console.log('setPositionIconText, Positions Nr: ' + positionsnr);
        iconFeature.getStyle().setText(PlanHelper.createPositionIconText(positionsnr, alarmLevel))

      },
      /**
       * setzt den title des Position Modals
       */
      setPositionModalTitle: function (val) {
        this.positionModalTitle = val;
      },
      /**
       * Pläne wurden in select plan gesetzt
       * wenn keine verfügbar, gebe Meldung an Benutzer
       */
      setPlaene: function (list) {

        this.hasPlaene = false;
        if (list && list.length > 0) {
          this.hasPlaene = true;
        }

      },
      /** setzt den Mangel der vom Benutzer in den Plan gezogen wird */
      setDragMangel: function (obj) {

        this.dragMangel   = obj
        this.dragPosition = null

        document.body.style.cursor = 'crosshair'

      },
      /**
       * setzt die Position die vom Benutzer in den Plan eingezeichnet wird
       */
      setDragPosition: function (obj) {

        console.log('Start Position einzeichnen: ', obj.id)
        this.dragPosition = obj
        this.dragMangel   = null

        document.body.style.cursor = 'crosshair'

      },
      /**
       * berechnet die Daten des Plans, wie den URL und die Größe
       */
      getPlanData: function (plan) {

        PlanHelper.getPlanData(plan, this.cancelTokenSource).then(data => {

          // Karte vorbereiten
          if (this.initiateMap(data)) {

            // hole Areas und zeichne sie ein:
            this.getPolygonCoordinates()
            // hole die Positionen und zeichne sie ein:
            this.getPositionCoordinates()
            // hole die Mängel und zeichne sie ein:
            this.getMaengelCoordinates()

          }

        })

      },
      setVectorLayer: function () {

        this.vectorSource = new VectorSource()
        this.vectorLayer  = PlanHelper.createVectorLayer(this.vectorSource)

      },
      /**
       * eingezeichneten Objekttyp zur Liste der Objekttypen hinzufügen
       */
      setObjekttyp: function (objekttyp) {

        if (!objekttyp) {
          console.log('Objekttyp zum Hinzufügen ist leer?');
          return;
        }
        if (this.objekttypen == null) {
          this.objekttypen = [];
        }
        var exists = false;
        for (var i = 0; i < this.objekttypen.length; i++) {
          if (this.objekttypen[i].id == objekttyp.id) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          this.objekttypen.push(objekttyp);
        }

      },
      /**
       * fügt einen neuen Icon für einen Mangel hinzu
       * @param mangel
       */
      addMangelIcon: function (mangel, rotation, hasActiveMaengel, koordinaten, position_id) {

        // hat der Benutzer dies in den Einstellungen deaktiviert?
        if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.isHideMaengelInPlaenen) {
          console.log('Mängel sollen in Plänen ausgeblendet bleiben!');
          return;
        }

        console.log('addMangelIcon: koordinaten = ' + koordinaten);
        let coordinates = [0, 0];
        if (koordinaten && Array.isArray(koordinaten)) {
          coordinates = koordinaten
        } else if (koordinaten) {
          const splitted = koordinaten.split(',')
          coordinates = [splitted[0], splitted[1]];
        }

        // Achtung die Koordinaten müssen Number sein!
        if (typeof coordinates[0] != 'number') {
          coordinates[0] = parseFloat(coordinates[0]);
        }
        if (typeof coordinates[1] != 'number') {
          coordinates[1] = parseFloat(coordinates[1]);
        }

        let color = '#17a2b8';
        let src = require('@/assets/images/icons/map-MANGEL-NICHT_PRUEFBAR.svg');
        if (
            ( hasActiveMaengel && hasActiveMaengel.name == 'MITTLERE_GEFAHR' ) ||
            ( mangel && mangel.mangelPrioritaet.name == 'MITTLERE_GEFAHR')
        ) {
          src = require('@/assets/images/icons/map-MANGEL-GELB.svg');
          color = '#eda512';
        } else if (
            ( hasActiveMaengel && hasActiveMaengel.name == 'HOHE_GEFAHR' ) ||
            ( mangel && mangel.mangelPrioritaet.name == 'HOHE_GEFAHR')
        ) {
          src = require('@/assets/images/icons/map-MANGEL-ROT.svg');
          color = '#e3150f';
        }
        if (!rotation) rotation = 0;

        var icon  = {
          image: new Icon({
            anchor: [this.iconAnchorX, this.iconAnchorY],
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            src: src,
            rotation: rotation
          }),
        };

        var iconFeature = new Feature({
          geometry:     new Point(coordinates),
          mangel_id:    mangel ? mangel.id : null,
          position_id:  position_id, // falls vorhanden, dann kann Position Detail geöffnet werden
          isMangel:     true,
          // merke dir die Position um ihn an/ausblenden zu können:
          myCoordinate: coordinates,
        });

        iconFeature.setStyle(new Style(icon));
        this.vectorSource.addFeature(iconFeature);

        var fill = new Fill({
          color: color
        });
        var textstyle = new Text({
          fill: fill,
          stroke: new Stroke({
            color: '#fff',
            width: 4
          }),
          text: this.$t('message.plan_mangel_nr') + ' ' + mangel.nummer,
          font: '12px Verdana',
          offsetX: 0,
          offsetY: 12
        });

        iconFeature.getStyle().setText(textstyle);

        if (mangel && !koordinaten) {

          console.log('iconFeature für nächste Mausbewegung gesetzt: ' + iconFeature);
          let iconfeatures = [iconFeature];
          this.iconFeature = iconfeatures;

          // ein Popup aufrufen, damit die Maus bewegt wird
          $('.ol-popup').show();
          var text          = this.$t('message.plan_mangel_erfolgreich').replace('{0}', mangel.descriptionShort).replace('{1}', this.plan.bezeichnung);
          this.popupOverlay.setPosition(this.map.getView().getCenter());
          var content       = document.getElementById('popup-content');
          content.innerHTML = text;

        }

        this.hideSpinner()

      },
      /**
       * fügt eine neue Position ein
       * @param position_id ID ist optional und wird bei drag und drop über das dragPosition Element übergeben
       * @param coordinaten sind optional und werden bei drag und drop über die Mouse Position ermitteln nach dem drag
       */
      addPositionIcon: function (
          position_id,
          koordinaten,
          position_nr,
          alarm_level,
          objekt_typen,
          deletedDate,
          area_id,
          position,
          hasActiveMaengel
      ) {

        var id          = position_id  ? position_id  : (this.dragPosition ? this.dragPosition.id : '');
        var coordinates = koordinaten  ? koordinaten  : [0, 0];
        var positionsnr = position_nr  ? position_nr  : (this.dragPosition && this.dragPosition.positionsNr ? this.dragPosition.positionsNr : '');
        var alarmLevel  = alarm_level  ? alarm_level  : (this.dragPosition && this.dragPosition.alarmLevel  ? this.dragPosition.alarmLevel.name : '');
        var objekttypen = objekt_typen ? objekt_typen : (this.dragPosition && this.dragPosition.objekttypen ? this.dragPosition.objekttypen : null);
        // wieder auf null setzen, damit nicht nochmals ein falsches rübergezogen wird
        this.dragPosition = null;

        // console.log('addPositionIcon: id = ' + id + ' coordinates = ' + coordinates + ' positionsnr = ' + positionsnr + ' alarmLevel = ' + alarmLevel + ' objekttypen = ' + objekttypen + ' deletedDate = ' + deletedDate);

        // zeichne für jeden objekttypen einen eigenen ICON:
        var iconfeatures = [];
        var anzahl = objekttypen && objekttypen.length > 0 ? objekttypen.length : 1;
        for (var i = 0; i < anzahl; i++) {

            // zur Liste der eingezeichneten Objekttypen für Filterung hinzufügen:
            console.log('set objekttyp ' + objekttypen[i].id);
            this.setObjekttyp(objekttypen[i]);

            var typ                 = objekttypen && objekttypen.length > 0 ? objekttypen[i].typ : null;
            var alarmLevelObjekt    = objekttypen && objekttypen.length > 0 && objekttypen[i].alarmLevel ? objekttypen[i].alarmLevel.name : ''; // alarmLevel kann null sein, dann '' anzeigen damit icon schwarz wird

            console.log(
              'iconFeature: id = ' + id +
              ' coordinates = ' + coordinates +
              ' positionsnr = ' + positionsnr +
              ' alarmLevel = ' + alarmLevel +
              ' typ = ' + typ +
              ' alarmLevelObjekt = ' + alarmLevelObjekt +
              ' area_id = ' + area_id
            )

            // jeden weiteren Icon rotieren um alle sichtbar zu machen
            var rotation = 0.8 * i;

            var src   = PlanHelper.getIconSrc(typ, alarmLevelObjekt, this.$store.getters.customer)
            var icon  = {
                image: new Icon({
                  anchor: [this.iconAnchorX, this.iconAnchorY],
                  anchorXUnits: 'pixels',
                  anchorYUnits: 'pixels',
                  src: src,
                  rotation: rotation
                }),
            };

            if (deletedDate && deletedDate != 'null') {
              continue;
            }
            this.positionsList.push(position);
            this.drawnPositions.push(id);

            if (!area_id) {
              console.error('Position hat keine Area', positionsnr);
            }

            var iconFeature = new Feature({
              geometry:     new Point(coordinates),
              position_id:  id,
              positionsNr:  positionsnr,
              alarmLevel:   alarmLevelObjekt,
              objekttypen:  objekttypen,
              objekttyp:    typ,
              // merke dir die Position um ihn an/ausblenden zu können:
              myCoordinate: coordinates,
              area_id:      area_id
            });

            iconFeature.setStyle(new Style(icon));
            this.vectorSource.addFeature(iconFeature);

            if (!koordinaten || !coordinates || coordinates.length < 2 || (coordinates.length > 1 && coordinates[0] == 0 && coordinates[1] == 0)) {
              console.log('iconFeature für nächste Mausbewegung gesetzt: ' + iconFeature);
              iconfeatures.push(iconFeature);
            }

            this.setPositionIconText(iconFeature, positionsnr, alarmLevelObjekt);

        } // end each objekttypen

        /* Mängel Icon einzeichnen */
        if (hasActiveMaengel) {

          // jeden weiteren Icon rotieren um alle sichtbar zu machen
          var rotation = 0.8 * anzahl;

          this.addMangelIcon(null, rotation, hasActiveMaengel, coordinates, id);

          if (!koordinaten) {
            console.log('iconFeature für nächste Mausbewegung gesetzt: ' + iconFeature);
            iconfeatures.push(iconFeature);
          }

        }

        if (!koordinaten) {

          // setze nach dem nächsten Mousemove das Icon korrekt:
          this.iconFeature = iconfeatures;

          // ein Popup aufrufen, damit die Maus bewegt wird
          $('.ol-popup').show();
          var text          = this.$t('message.plan_position_gespeichert').replace('{0}', positionsnr).replace('{1}', this.plan.bezeichnung);
          this.popupOverlay.setPosition(this.map.getView().getCenter());
          var content       = document.getElementById('popup-content');
          content.innerHTML = text;

        }

        // soll zur Position gezoomt werden?
        if (this.zoomPositionId && this.zoomPositionId == id) {
          this.zoomPosition({
            id: id,
            coordinates: coordinates
          })
        }
        this.hideSpinner()

      },
      /**
       * Modal mit Positions Details ausblenden
       */
      hidePositionDetailModal: function () {

        console.log('Hide Positions Modal')
        this.position = null
        this.$bvModal.hide('modal-position-details')

      },
      /**
       * Modal mit Positions Details anzeigen
       */
      showPositionDetailModal: function (positions_id, mangel_id) {

        console.log('Zeige Positions/Mangel Modal ' + positions_id + ' ' + mangel_id);
        this.position = positions_id;
        this.mangel   = mangel_id;

        if (positions_id) {
          this.positionModalTitle = this.$t('message.plan_position_details');
        } else if (mangel_id) {
          this.positionModalTitle = this.$t('message.plan_mangel_details');
        }

        this.$bvModal.show('modal-position-details');

      },
      /** Karte initialisieren */
      initiateMap: function (plandata) {

          console.log('init map ...')

          if (!plandata) {
            console.error('initiateMap: Keine Plan Daten vorhanden.')
            return
          }

          // Werte zurücksetzen:
          if (this.vectorLayer) {
            var features = this.vectorLayer.getSource().getFeatures();
            features.forEach((feature) => {
              this.vectorLayer.getSource().removeFeature(feature);
            });
            this.vectorLayer.getSource().clear();
          }
          if (this.imageLayer) {
            this.imageLayer.setSource(null);
          }

          $('#map').html('');
          this.drawnPositions           = [];
          this.positionsList            = [];
          this.objekttypen              = [];
          this.position                 = null;
          this.positionModalTitle       = this.$t('message.plan_position_details');
          this.map                      = null;
          this.imageLayerProjection     = null;
          this.imageLayer               = null;
          this.view                     = null;
          this.vectorSource             = null;
          this.dragPosition             = null;
          this.mouseCoordinate          = null;
          this.iconFeature              = null;
          this.currentFeature           = null;

          var that = this;

          // Map views always need a projection.  Here we just want to map image
          // coordinates directly to map coordinates, so we create a projection that uses
          // the image extent in pixels.
          var dimension   = plandata.dimension;
          var extent = [0, 0, dimension.width, dimension.height];
          /* die Projektion bestimmt das Koordinaten System - das ist in meinem Fall in Pixeln */
          this.imageLayerProjection = new Projection({
            code: 'xkcd-image',
            units: 'pixels',
            extent: extent
          });

          var staticImage = new Static({
            url: plandata.url,
            projection: that.imageLayerProjection,
            imageExtent: extent,
          });
          staticImage.on('imageloadend', function () {
            console.log('imageloadend: ' + plandata.url);
          });
          staticImage.on('imageloaderror', function (e) {
            console.log('imageloaderror: ' + plandata.url);
            console.log('imageloaderror: ' + e);
            console.log("Plan kann nicht geladen werden " + plandata.url + 'Size: ' + dimension.width + ' ' + dimension.height);
          });

          // der Image Layer der den Plan beinhaltet, wenn der Plan geladen wird,
          // wird das wieder überschrieben:
          this.imageLayer = new ImageLayer({
            source: staticImage
          });

          // die View um den Zoomlevel, etc. festzulegen:
          this.view = new View({
            projection: that.imageLayerProjection,
            center: getCenter(extent),
            zoom: 3,
            maxZoom: 8
          });

          // die Source und den Layer für den Vector Layer initialisieren:
          this.setVectorLayer();

          class Drag extends PointerInteraction {
            constructor() {
              super({
                handleDownEvent: handleDownEvent,
                handleDragEvent: handleDragEvent,
                handleMoveEvent: handleMoveEvent,
                handleUpEvent: handleUpEvent,
              });

              /**
               * @type {import("../src/ol/coordinate.js").Coordinate}
               * @private
               */
              this.coordinate_ = null;

              /**
               * @type {string|undefined}
               * @private
               */
              this.cursor_ = 'pointer';

              /**
               * @type {Feature}
               * @private
               */
              this.feature_ = null;

              /**
               * @type {string|undefined}
               * @private
               */
              this.previousCursor_ = undefined;
            }
          } // end drag

          /**
           * @return {boolean} `false` to stop the drag sequence.
           */
          function handleUpEvent () {

            console.log('handleUpEvent Koordinaten: ' + that.mouseCoordinate, this.feature_);

            if (that.editPlanRight && that.isBearbeitungsmodus) {

              if (this.feature_ && this.feature_.values_ && this.feature_.values_.mangel_id) {

                // Mangel verschieben:
                let geometry = this.feature_.getGeometry();
                that.mouseCoordinate = geometry.flatCoordinates;
                console.log('Feature Koordinaten für Mangel: ' + geometry.flatCoordinates[0] + ' ' + geometry.flatCoordinates[1]);

                // speichere die Position des Mangels in der Datenbank:
                that.saveMangel(this.feature_.values_.mangel_id, that.mouseCoordinate);
                this.feature_.setGeometry(geometry);

              } else if (this.feature_ && this.feature_.values_ && this.feature_.values_.position_id) {

                // Position verschieben
                let geometry = this.feature_.getGeometry();
                that.mouseCoordinate = geometry.flatCoordinates;
                console.log('Feature Koordinaten für Position: ' + geometry.flatCoordinates[0] + ' ' + geometry.flatCoordinates[1]);

                // speichere die Position des Icons in der Datenbank:
                that.savePosition(this.feature_.values_.position_id, that.mouseCoordinate, this.feature_.values_.positionsNr);

                // auch alle andere Icons an dieser Position müssen verschoben werden
                if (that.vectorSource) {
                  var ft = that.vectorSource.getFeatures();
                  if (ft) {
                    for (var i = 0; i < ft.length; i++) {

                      var feature = ft[i];
                      if (feature.values_ && feature.values_.position_id && feature.values_.position_id == this.feature_.values_.position_id) {
                        feature.setGeometry(geometry);
                      }

                    }
                  }
                }

              } else if (this.feature_ && this.feature_.values_ && this.feature_.values_.polygon_id) {

                // Polygon zeichnen
                let geometry = this.feature_.getGeometry();
                that.mouseCoordinate = geometry.flatCoordinates;
                console.log('Feature Koordinaten für Polygon: ' + geometry.flatCoordinates);
                that.savePolygonCoordinatesChanges(this.feature_.values_.polygon_id, geometry.flatCoordinates);

              } // end if Polygon

            } // end if editPlanRight

            this.coordinate_ = null;
            this.feature_ = null;
            return false;

          }

          /**
           * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
           */
          function handleDragEvent (evt) {

            var deltaX = evt.coordinate[0] - this.coordinate_[0];
            var deltaY = evt.coordinate[1] - this.coordinate_[1];

            var geometry = this.feature_.getGeometry();
            geometry.translate(deltaX, deltaY);

            this.coordinate_[0] = evt.coordinate[0];
            this.coordinate_[1] = evt.coordinate[1];

            // console.log("handleDragEvent Koordinaten: " + this.coordinate_[0] + ' ' + this.coordinate_[1]);

          }

          /**
           * @param {import("../src/ol/MapBrowserEvent.js").default} evt Map browser event.
           * @return {boolean} `true` to start the drag sequence.
           */
          function handleDownEvent (evt) {

            console.log('handleDownEvent ' + evt);

            if (that.editPlanRight && that.isBearbeitungsmodus) {

              var map = evt.map;

              var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
              })

              if (feature) {

                this.coordinate_ = evt.coordinate;
                this.feature_ = feature;

              }

              return !!feature;

            }

            return null;

          }

          /**
           * @param {import("../src/ol/MapBrowserEvent.js").default} evt Event.
           */
          function handleMoveEvent (evt) {

            // keine Aktion beim einzeichnen von Position oder Mangel
            if (that.dragMangel || that.dragPosition) return

            // console.log('handleMoveEvent', evt)
            if (this.cursor_) {

              console.log('set cursor', this.cursor_)
              const map = evt.map
              const feature = map.forEachFeatureAtPixel(evt.pixel, feature => {
                return feature
              })
              const element = evt.map.getTargetElement()
              if (feature) {
                if (element.style.cursor != this.cursor_) {
                  this.previousCursor_ = element.style.cursor
                  element.style.cursor = this.cursor_
                }
              } else if (this.previousCursor_ !== undefined) {
                element.style.cursor = this.previousCursor_
                this.previousCursor_ = undefined
              }

              // console.log("handleMoveEvent Koordinaten: " + that.mouseCoordinate);

            }

          }

          // create map mit dem ImageLayer und dem Vector Layer
          this.map = new Map({
            interactions: defaultInteractions().extend([new Drag()]),
            layers: [ this.imageLayer, this.vectorLayer ],
            target: 'map',
            view: this.view,
            crossOrigin: 'Anonymous',
          })

          // display popup on click
          this.map.on('click', function (evt) {

            console.log('click event', evt)

            // Klick um Position einzuzeichnen:
            if (that.dragPosition) {

              that.showSpinner()
              const coordinates = evt.coordinate
              console.log('click event - Position einzeichnen:', coordinates)
              // Positions Details holen und einzeichnen:
              that.dragPosition.coordinates = coordinates
              that.getPositionDetails(that.dragPosition, true)
              that.savePosition(that.dragPosition.id, coordinates, that.dragPosition.positionsNr)
              // wieder auf null setzen, ist jetzt eingezeichnet
              that.dragPosition = null
              document.body.style.cursor = 'default'
              return

            }
            // Click um Mangel einzuzeichnen:
            if (that.dragMangel) {

              that.showSpinner()
              const coordinates = evt.coordinate
              console.log('click event - Mangel einzeichnen:', coordinates)
              that.addMangelIcon(that.dragMangel, null, null, coordinates, null)
              that.saveMangel(that.dragMangel.id, coordinates)
              // wieder auf null setzen, ist jetzt eingezeichnet
              that.dragMangel = null
              document.body.style.cursor = 'default'
              return

            }

            const feature = that.map.forEachFeatureAtPixel(evt.pixel, feature => {
              return feature
            })
            if (feature) {

              const values = feature.values_

              // Klick auf Position Icon:
              console.log('click on value = ' + values)
              if (values.position_id) {

                console.log('Position Feature mit ID ' + values.position_id + ' clicked');
                that.currentFeature = feature;
                that.showPositionDetailModal(values.position_id);

              } else if (values.mangel_id) {

                console.log('Mangel Feature mit ID ' + values.mangel_id + ' clicked');
                that.currentFeature = feature;
                that.showPositionDetailModal(null, values.mangel_id);

              } else if (values.polygon_id && !that.isBearbeitungsmodus) {

                console.log('Polygon mit ID ' + values.polygon_id + ' clicked');
                that.currentFeature = feature;
                that.editPolygon();

              }

            }

          })

          this.map.addOverlay(this.popupOverlay)

          console.log('Plan initialisiert.')

          return this.map

      },
      /**
       * nur die Koordinaten einer Polygon Änderung speichern
       */
      savePolygonCoordinatesChanges: function (area_polygon_id, flatCoordinates) {

        const url     = process.env.VUE_APP_SERVER_URL + '/plan/savePolygon';
        const params  = {
          area_polygon_id: area_polygon_id,
          coordinates: flatCoordinates.join(',')
        };
        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          if (response && response.data && response.data.id) {

            console.log('Polygon Koordinaten erfolgreich gespeichert ' + response.data);

          }

        });

      },
      /** initialisiere Popup über der Karte um Positionen mit Mausbewegung einzuzeichnen */
      initPopup: function () {

        var popupContainer = document.getElementById('popup');
        var popupCloser    = document.getElementById('popup-closer');
        var that           = this;

        /**
         * Create an overlay to anchor the popup to the map.
         */
        this.popupOverlay = new Overlay({
          element: popupContainer,
          autoPan: true,
          autoPanAnimation: {
            duration: 250,
          },
        });

        /**
         * Add a click handler to hide the popup.
         * @return {boolean} Don't follow the href.
         */
        popupCloser.onclick = function () {
          that.popupOverlay.setPosition(undefined);
          popupCloser.blur();
          return false;
        };

      },
      /**
       * speichere Mangel in der Datenbank
       */
      saveMangel: function (mangel_id, coordinates) {

        var that     = this;
        const url    = process.env.VUE_APP_SERVER_URL + '/plan/saveMangel';
        const params = {
          mangel_id:    mangel_id,
          coordinates:  JSON.stringify(that.encodeUtf8(coordinates)),
          plan_id:      this.plan.id
        };

        axios ({
          method: 'GET',
          params: params,
          url:    url
        }).then( response => {

          if (response && response.data && response.data.id) {
            console.log('Mangel Koordinaten erfolgreich gespeichert ' + coordinates);
            // entferne den Mangel aus der Mängelliste
            let newlist = [];
            for (let i = 0; i < that.maengelOhnePosition.length; i++) {
              if (that.maengelOhnePosition[i].id != mangel_id) {
                newlist.push(that.maengelOhnePosition[i]);
              }
            }
            that.maengelOhnePosition = newlist;
          }

        });

      },
      /**
       * speichere Position in der Datenbank
       */
      savePosition: function (id, coordinates, positionsNr) {

        console.log('speichere Position in Datenbank ', id)

        this.showSpinner()
        const that   = this
        const url    = process.env.VUE_APP_SERVER_URL + '/plan/savePosition'
        const params = {
          position_id:  id,
          coordinates:  JSON.stringify(that.encodeUtf8(coordinates)),
          plan_id:      this.plan.id,
          positionsNr:  positionsNr
        }

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(response => {

          if (response && response.data && response.data.id) {
            console.log('Positions Koordinaten erfolgreich gespeichert ', coordinates)
            // entferne die Position aus der Positionsliste
            that.drawnPositions.push(response.data.position)
            // trigger setzten, das Position gesetzt wurde
            that.positionAdded = response.data.position
          }

        }).catch(error => {
          // handle error
          console.log('Fehler beim Speichern der Positions-Koordinaten', error)
          alert('Es gab einen Fehler beim Speichern der Positions-Koordinaten. Bitte versuchen Sie es erneut.')
        }).finally(function () {
          // always executed
          that.hideSpinner()
        })

      },
      componentToHex: function (c) {
        var hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
      },
      /**
       * RGB nach Hex umwandeln
       * @param r
       * @param g
       * @param b
       * @returns {string}
       */
      rgbToHex: function (r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
      },
      /**
       * Hex Color zu RGB
       * @param hex
       * @returns {number[]}
       */
      hexTorgb: function (hex) {
        return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
      },
      /**
       * Polygon wurde geändert, speichere in Datenbank
       */
      savePolygonChanges: function () {

        console.log('save Polygon ...');

        this.showSpinner();
        let that      = this;
        const url     = process.env.VUE_APP_SERVER_URL + '/plan/savePolygon';
        const params  = {
          area_polygon_id:  this.polygonEditId,
          color:            this.polygonRgba,
          colorStroke:      this.polygonRgbaStroke,
          bezeichnung:      this.polygonBezeichnung
        };

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          if (response && response.data && response.data.id) {

            console.log('Polygon erfolgreich gespeichert ' + response.data);
            that.hideSpinner();
            // nun setze den korrekten Style des Polygons:
            that.currentFeature.set('bezeichnung',  response.data.bezeichnung);
            that.currentFeature.set('color',        response.data.color);
            that.currentFeature.set('colorStroke',  response.data.colorStroke);

            that.$bvModal.hide('modal-polygon');

          }

        });

      },
      /**
       * draw initialieren zum Zeichnen von Polygonen
       */
      addDrawInteraction: function () {

        this.$bvModal.hide('modal-polygon');

        let transp = 0.25;
        if (this.polygonTransparenz) {
          transp = this.polygonTransparenz/100;
        }

        let rgba        = this.hexTorgb(this.polygonBackground);
        let rgbastroke  = this.hexTorgb(this.polygonRahmenColor);
        rgba.push(transp);
        rgbastroke.push(1); // Rahmen keine Transparenz setzen
        this.polygonRgba        = rgba.join(',');
        this.polygonRgbaStroke  = rgbastroke.join(',');

        let style = {
          'stroke-color': 'rgba(' + this.polygonRgbaStroke + ')',
          'stroke-width': 1.5,
          'fill-color': 'rgba(' + this.polygonRgba + ')',
        }

        this.draw = new Draw({
          source: this.vectorSource,
          type: this.drawType,
          style: style,
        });
        this.map.addInteraction(this.draw);

        // event wird bei snap ausgelöst
        let that = this;
        this.draw.on('drawend', function(event) {
          console.log('change snap event called!' + event);
          let feature = event.feature;
          that.savePolygonCoordinates(feature, style);
        });

      },
      /**
       * startet das Zeichnen einer Area/Polygon bei Klick auf Button
       * öffnet Modal für Bezeichnung, Farben, etc.
       */
      startPolygonDraw: function () {

        this.polygonModalTitle = this.$t('message.plan_neue_markierung');
        this.polygonEditId = null; // es wird nichts geändert
        this.$bvModal.show('modal-polygon');

      },
      /**
       * ein Polygon löschen aus Map und Datenbank
       */
      deletePolygon: function (polygonEditId) {

        let that = this;
        const bezeichung = this.currentFeature.values_.bezeichnung ? this.currentFeature.values_.bezeichnung : '';
        const text = this.$t('message.plan_markierung_loeschen').replace('{0}', bezeichung);
        bootbox.confirm(text, function (result) {

          if (result) {

            that.showSpinner();
            const url = process.env.VUE_APP_SERVER_URL + '/plan/deletePolygon';
            const params = {
              area_polygon_id: polygonEditId
            }
            axios ({
              method:   'post',
              params:   params,
              url:      url
            }).then( () => {

              that.vectorSource.removeFeature(that.currentFeature);
              that.hideSpinner();
              that.currentFeature = null;
              that.$bvModal.hide('modal-polygon');

            });

          }

        });

      },
      /**
       * Polygon angeklickt, öffne Modal für Änderungen
       */
      editPolygon: function () {

        console.log('editPolygon: ' + this.currentFeature);
        this.$bvModal.show('modal-polygon');
        this.polygonModalTitle = this.$t('message.plan_markierung_aendern');
        this.polygonBezeichnung = this.currentFeature.values_.bezeichnung;

        let color = this.currentFeature.values_.color;
        color = color.split(',');
        const transparenz = Number(color[3]);
        this.polygonTransparenz = transparenz * 100;
        let hex = this.rgbToHex(Number(color[0]), Number(color[1]), Number(color[2]));
        this.polygonBackground = hex;

        let colorStroke = this.currentFeature.values_.colorStroke;
        colorStroke = colorStroke.split(',');
        hex = this.rgbToHex(Number(colorStroke[0]), Number(colorStroke[1]), Number(colorStroke[2]));
        this.polygonRahmenColor = hex;

        this.polygonEditId = this.currentFeature.values_.polygon_id;

      },
      /** liest die Koordinaten einer Area/Polygon */
      savePolygonCoordinates: function (feature, style) {

        console.log('save Area Coordinates' + feature);
        const coordinates = feature.getGeometry().getCoordinates();

        let that      = this;
        const url     = process.env.VUE_APP_SERVER_URL + '/plan/savePolygon';
        const params  = {
          plan_id: this.plan.id,
          coordinates: JSON.stringify(that.encodeUtf8(coordinates)),
          color: this.polygonRgba,
          colorStroke: this.polygonRgbaStroke,
          bezeichnung: this.polygonBezeichnung
        };

        // speichere die Koordinaten in der Datenbank:
        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          if (response && response.data && response.data.id) {

            console.log('Polygon erfolgreich gespeichert ' + response.data);

            // nun setze den korrekten Style des Polygons:
            const polygonStyle = new Style({
              fill: new Fill({
                color: style['fill-color'],
              }),
              stroke: new Stroke({
                color: style['stroke-color'],
                width: style['stroke-width'],
              })
            });
            feature.setStyle(polygonStyle);
            feature.set('bezeichnung', response.data.bezeichnung);
            feature.set('polygon_id',  response.data.id);
            feature.set('color',       response.data.color);
            feature.set('colorStroke', response.data.colorStroke);
            // zeichnen beenden:
            that.map.removeInteraction(that.draw);
            that.draw.finishDrawing();

          }

        });

      },
      /** holt die Mängel und stellt sie dar */
      getMaengelCoordinates: function () {

        const customer = this.$store.getters.customer
        PlanHelper.getMaengelCoordinates(customer, this.plan).then(data => {

          console.log('getMaengelCoordinates: ' + data)
          if (data && data.length > 0) {

            // es wird ein Array mit einzelnen Mängeln zurückgeliefert,
            // jeder Mangel hat eine ID und Koordinaten
            for (let i = 0; i < data.length; i++) {

              const koordinaten = data[i].koordinaten
              const mangel      = data[i]
              this.addMangelIcon(mangel, 0, null, koordinaten)

            }

          }

        }).catch(error => {
          console.log('Fehler Mangel laden', error)
          bootbox.alert(this.$t('message.plan_maengel_load_error'))
        })

      },
      /** holt die Positionen und stellt sie dar */
      getPositionCoordinates: function () {

        this.showPositionLoadingSpinner = true
        const customer = this.$store.getters.customer
        const that = this
        PlanHelper.getPositions(customer, this.plan, this.cancelTokenSource).then(data => {

          if (data && data.length > 0) {

            // Array mit Promises um nach der Schleife darauf zu warten:
            var promises = []

            // es wird ein Array mit einzelnen Positionen zurückgeliefert,
            // jede Position hat eine ID und Koordinaten
            for (var i = 0; i < data.length; i++) {

              // liefert ein Promise:
              var pdPromise = that.getPositionDetails(data[i])
              promises.push(pdPromise)

            }

            // nun warte auf die Promises:
            const fetchAsyncData = async (promises) => {
              await Promise.all(promises)
              // console.log(res);
              that.showPositionLoadingSpinner = false
            }
            fetchAsyncData(promises)

          } else {
            // keine Positionen für den Plan vorhanden:
            console.log('Keine Positionen für den Plan vorhanden.')
            that.showPositionLoadingSpinner = false
          }

        }).catch(function () {
          bootbox.alert(this.$t('message.plan_position_load_error'))
          this.showPositionLoadingSpinner = false
        })

      },
      /**
       * holt Positions Details und zeichnet den Icon der Position
       * @param position Position die eingezeichnet werden soll
       * @param checkObjekte es wird überprüft ob die Position bereits Objekte hat, wenn nein wird Fehlermeldung angezeigt
       * @returns {Promise<unknown>}
       */
      getPositionDetails: function (position, checkObjekte) {

        const coordinates = position.coordinates
        const id          = position.id
        let positionsNr   = position.positionsNr

        const that = this
        return new Promise((resolve) => {

          // hole Alarmlevel und Objekttypen
          PlanHelper.getPositionResult(id, this.cancelTokenSource, false).then(response => {

            const data              = response.data
            const alarmLevel        = data.alarmLevel
            const objekttypen       = data.objekttypen

            // Achtung, wenn die Position noch keine Objekte hat, dann kann sie auch nicht
            // eingezeichnet werden:
            if (checkObjekte && (!objekttypen || objekttypen.length < 1)) {
              that.hideSpinner()
              bootbox.alert(that.$t('message.plan_position_load_error_no_objekt'))
              return null
            }

            // wurde die Position bereits im Datenbroker gelöscht?
            const deletedDate       = data.deletedDate
            const area_id           = data.area ? data.area.id : null
            const hasActiveMaengel  = data.hasActiveMaengel

            if (!positionsNr) {
              positionsNr = data.positionsNr
            }

            that.addPositionIcon(id, coordinates, positionsNr, alarmLevel, objekttypen, deletedDate, area_id, position, hasActiveMaengel)
            resolve(response)

          }).catch(() => {
            that.hideSpinner()
            resolve()
          })

        })

      },
      /** holt die Areas und stellt sie dar */
      getPolygonCoordinates: function () {

        PlanHelper.getPolygone(this.plan).then(data => {

          if (data && data.length > 0) {

            // es wird ein Array mit einzelnen Poligonen zurückgeliefert,
            // jedes Poligon hat eine ID und und Koordinaten
            for (let i = 0; i < data.length; i++) {

              const style = new Style({
                fill: new Fill({
                  color: 'rgba(' + data[i].color + ')',
                }),
                stroke: new Stroke({
                  color: 'rgba(' + data[i].colorStroke + ')',
                  width: 1.5,
                })
              })

              const coordinatesPolygon = data[i].coordinates
              const id                 = data[i].id

              // The polygon here must be an array of coordinates
              const polygon = new Polygon([coordinatesPolygon])
              // Polygon feature class mit der ID aus der Datenbank und den Polygon
              let feature = new Feature({
                geometry: polygon,
                polygon_id: id,
                bezeichnung: data[i].bezeichnung,
                color: data[i].color,
                colorStroke: data[i].colorStroke
              })
              feature.setStyle(style)
              this.vectorSource.addFeature(feature)

            }

          }

        })

      },
      /**
       * aktuell sichbaren Plan ausdrucken
       */
      print: function () {

        if (this.plan) {

          this.printPlan = true;
          document.body.style.cursor = 'wait';

          // mögliche export formate - evt. dem Benutzer auswählen lassen
          const dims = {
            a0: [1189, 841],
            a1: [841, 594],
            a2: [594, 420],
            a3: [420, 297],
            a4: [297, 210],
            a5: [210, 148],
          };

          const format = 'a4';
          const resolution = 150;
          const dim = dims[format];
          const width = Math.round((dim[0] * resolution) / 25.4);
          const height = Math.round((dim[1] * resolution) / 25.4);
          const size = this.map.getSize();
          const viewResolution = this.map.getView().getResolution();
          let that = this;

          this.map.once('rendercomplete', function () {

            const mapCanvas = document.createElement('canvas');
            mapCanvas.width = width;
            mapCanvas.height = height;
            const mapContext = mapCanvas.getContext('2d');

            Array.prototype.forEach.call(

              document.querySelectorAll('.ol-layer canvas'),
              function (canvas) {

                console.log('canvas', canvas);

                if (canvas.width > 0) {

                  const opacity = canvas.parentNode.style.opacity;
                  mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                  const transform = canvas.style.transform;
                  // Get the transform parameters from the style's transform matrix
                  const matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                  // Apply the transform to the export map context
                  CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                  );

                  mapContext.drawImage(canvas, 0, 0);

                }
              }

            );

            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);
            const pdf = new jsPDF('landscape', undefined, format);
            pdf.addImage(
              mapCanvas.toDataURL('image/jpeg'),
              'JPEG',
              0,
              0,
              dim[0],
              dim[1]
            );

            pdf.save('map.pdf');
            // Reset original map size
            that.map.setSize(size);
            that.map.getView().setResolution(viewResolution);

            document.body.style.cursor = 'auto';
            that.printPlan = false;

          });

          // Set print size
          const printSize = [width, height];
          this.map.setSize(printSize);
          const scaling = Math.min(width / size[0], height / size[1]);
          this.map.getView().setResolution(viewResolution / scaling);

        }

      }

    } // end methods
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    #planWrapper {
        position: relative;
        height: 800px;
    }
    #map {
        position: absolute;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }
    #nav {
        padding: 30px;
    }
    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }
    #nav a.router-link-exact-active {
        color: #42b983;
    }
    #positionLoadingSpinner {
        margin-top: 3em;
    }
    #positionLoadingSpinner .card-title {
        font-size: 1em;
    }
    .ol-popup {
      position: absolute;
      background-color: white;
      box-shadow: 0 1px 4px rgba(0,0,0,0.2);
      padding: 15px;
      border-radius: 10px;
      border: 1px solid #cccccc;
      bottom: 12px;
      left: -50px;
      min-width: 280px;
    }
    .ol-popup:after, .ol-popup:before {
      top: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    .ol-popup:after {
      display: none;
    }
    .ol-popup:before {
      display: none;
    }
    .ol-popup-closer {
      text-decoration: none;
      position: absolute;
      top: 2px;
      right: 8px;
    }
    .ol-popup-closer:after {
      content: "✖";
    }

</style>
