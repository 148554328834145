<template>

  <span id="selectPlanWrapper">
    <div v-if="plaene && plaene.length > 0">
        <div class="input-group">
            <b-form-select v-model="selected" :options="options" />
            <div class="input-group-append" v-if="selected && selected.revisionBemerkung">
              <span class="input-group-text"
                    id="basic-addon2"
                    v-b-tooltip.hover
                    title="Notiz zum Plan anzeigen"
                    @click="showRevisionBemerkung()"
                    style="cursor: pointer">
                  <b-icon icon="chat-left-text"></b-icon>
              </span>
            </div>
        </div>
    </div>
  </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import bootbox from "bootbox";

    // Define a new component
    export default {
        name: 'SelectPlan',
        props: ['value'],
        /*
          Defines the data used by the component
        */
        data: function(){
            return {
              selected: null,
              options: [
                { value: null, text: '-- ' + this.$t('message.selectPlan_select') + ' --', disabled: true }
              ],
              plaene: null,
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          selected: function (newVal, oldVal) {
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.emitme();
            } else if (newVal) {
              this.emitme();
            }
          },
          value: function (newVal) {
            console.log('zeige Plan in Selectbox ' + newVal.id);
            if (this.plaene) {
              for (var i = 0; i < this.plaene.length; i++) {
                if (newVal.id === this.plaene[i].id) {
                  this.selected = this.plaene[i];
                  break;
                }
              }
            }
          }

        },
        methods: {

          init: function () {

            if (this.$store.getters.customer) {

              console.log('hole Pläne von Customer: ' + this.$store.getters.customer.cardcode);
              var that    = this;
              var url     = process.env.VUE_APP_SERVER_URL + '/plan/index';
              var params  = {
                'cardcode':    this.$store.getters.customer.cardcode,
                'mandant':     this.$store.getters.customer.biotechCountry.name
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(function (response) {

                that.plaene = response.data;
                if (that.plaene && that.plaene.length > 0) {
                  for (var i = 0; i < that.plaene.length; i++) {
                    var revision = '';
                    if (that.plaene[i].revisionsDatum) {

                      revision = ' | ' + that.$t('message.selectPlan_freigabe') + ': ' + that.javaDateToDateInputFieldDate(that.plaene[i].revisionsDatum)
                    }
                    that.options.push (
                        {
                          value: that.plaene[i],
                          text: that.javaDateToDateInputFieldDate(that.plaene[i].dateCreated) + ' | ' + that.plaene[i].bezeichnung + revision
                        }
                    );
                  }
                }
                // wenn es nur einen gibt, gleich diesen anzeigen:
                if (!that.value && that.plaene && that.plaene.length == 1) {
                  that.selected = that.plaene[0];
                  that.emitme();
                }
                if (that.value) {
                  for (var i = 0; i < that.plaene.length; i++) {
                    if (that.value.id === that.plaene[i].id) {
                      that.selected = that.plaene[i];
                      break;
                    }
                  }
                }
                that.$emit('set-plaene', that.plaene);

              });

            }

          },
          /**
           * an Parent zurückliefern
           */
          emitme: function () {

            this.$emit('input', this.selected);

          },
          /**
           * Revisions Bemerkung in Model anzeigen
           */
          showRevisionBemerkung: function () {

            if (this.selected && this.selected.revisionBemerkung) {
              bootbox.alert(this.selected.revisionBemerkung);
            }

          }

        }

    }

</script>
