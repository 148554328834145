<!-- Details über eine Position -->
<template>

    <transition name="fade" mode="out-in">
        <div v-if="artikel">
            <b-table :tbody-tr-class="rowClass" :items="artikel" :fields="fields">
              <template v-slot:cell(datum)="row">
                {{ javaDateToDateInputFieldDate(row.item.datum) }}
              </template>
              <template v-slot:cell(sicherheitsdatenblaetter)="row">
                <Sicherheitsdatenblaetter v-if="row.item.artikel" :article_id="row.item.artikel.id" />
              </template>
            </b-table>
        </div>
    </transition>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import Sicherheitsdatenblaetter from '@/components/ergebnisliste/Sicherheitsdatenblaetter'

    // Define a new component
    export default {
        name: 'ArtikelDetails',
        components: {
          Sicherheitsdatenblaetter
        },
        props: [
          /* die ID der Position dessen objekte angezeigt werden sollen */
          'value'
        ],
        /*
          Defines the data used by the component
        */
        data: function(){
            return {
              artikel: null,
              fields: [
                {
                  label: this.$t('message.printMaengel_datum'),
                  key: 'datum',
                  sortable: true
                },
                {
                  label: this.$t('message.default_bezeichnung'),
                  key: 'artikel.itemName',
                  sortable: true
                },
                {
                  label: this.$t('message.printErgebnisliste_menge'),
                  key: 'anzahl',
                  sortable: true
                },
                {
                  label: this.$t('message.printErgebnisliste_einheit'),
                  key: 'artikel.salUnitMsr',
                  sortable: true
                }
                ,
                {
                  label: this.$t('message.printErgebnisliste_bemerkung'),
                  key: 'bemerkung',
                  sortable: true
                }
                ,
                {
                  label: this.$t('message.sicherheitsdatenblaetter'),
                  key: 'sicherheitsdatenblaetter',
                  sortable: false,
                  class: 'text-right'
                }
              ],
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          value: function () {
            this.init();
          }

        },
        methods: {

          rowClass: function (item, type) {
            if (!item || type !== 'row') return
            if (item.class === 'B') return 'background-gray'
            if (item.class === 'A') return 'background-lightgray'
          },
          init: function () {

            if (this.value) {

              console.log ('init Artikel details ' + this.value);

              this.positionen   = [];
              this.selected     = null;
              var that          = this;
              var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getArtikelDetails';
              var params        = {
                'position_id': this.value.id
              };

              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(function (response) {

                that.artikel = response.data;
                console.log('artikel aus Datenbroker: ' + that.artikel);
                if (that.artikel && that.artikel.length > 0) {
                  that.$emit('has-artikel', that.artikel.length);
                } else {
                  that.$emit('has-artikel', null);
                }

              });

            } else {
              this.artikel = null;
            }

          }

        }

    }

</script>
