import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './assets/css/bootstrap.css';
import './assets/css/open-iconic-master/font/css/open-iconic-bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/utilo.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { store } from './store/store';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import './assets/css/flag-icons/css/flag-icons.min.css';
import { messages } from './messages.js';
import CustomerList from '@/components/customer/CustomerList'

Vue.config.productionTip = false
Vue.use(Vuex)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueI18n)
Vue.component('customer-list', CustomerList)

/* Flag ob Spinner bereits offen ist */
// var isSpinnerOpen = false;

/** Globale Variablen und Funktionen */
Vue.mixin({
  data: function() {
    return {
      /** Administrator */
      ROLE_ADMIN:    'ROLE_ADMIN',
      /** SUBUSER eines Key User oder ein einzelner Kunde */
      ROLE_CUSTOMER: 'ROLE_CUSTOMER',
      /** KeyUser */
      ROLE_KEY_USER: 'ROLE_KEY_USER'
    }
  },
  methods: {

    /**
     * Umami Tracking
     */
    doTracking: function (url) {

      if (process.env.VUE_APP_SERVER_URL == 'https://www.biotech-analytics.com/BioTechAnalyticsServer') {
        try {
          // eslint-disable-next-line
          // umami.trackView(url, 'biotech-analytics.com', '21258f6d-9224-4d75-ab27-d75b87bea193');
          umami.track({ website: '21258f6d-9224-4d75-ab27-d75b87bea193', url: url });
        } catch (e) {
          console.log('umami nicht verfügbar!');
        }
      }

    },
    /**
     * liefert den Text für einen Benutzer aus einem Ergebnis oder Traplinked Status
     * @param value
     */
    getErgebnisText: function (value) {

      console.info('getErgebnisText', value);

      let text = '';
      if (value == 'TRIGGERED' || value == 'TRAP_TRIGGERED')        text = this.$t('message.main_ausgeloest');
      else if (value == 'INACTIVE')                                 text = this.$t('message.plan_inaktiv');
      else if (value == 'HEARTBEAT_FAILED')                         text = this.$t('message.main_unterbrochen');
      else if (value == 'LOW_BATTERY' || value == 'BATTERY_LOW')    text = this.$t('message.main_batterie');
      else if (value == 'LOCATION_MODE')                            text = this.$t('message.main_lokalisierung');
      else if (value == 'UNSTABLE_WIFI')                            text = this.$t('message.main_schlecht');
      else if (value == 'ACTIVITY_CRITICAL' || value == 'OFFLINE')  text = this.$t('message.main_kritisch');
      else if (value == 'INFESTED')                                 text = this.$t('message.main_befallen');
      else if (value == 'LIGHT_INFESTATION')                        text = this.$t('message.main_befallen_leicht');
      else if (value == 'SERVERE_INFESTATION')                      text = this.$t('message.main_befallen_stark');
      else if (value == 'ACTIVITY_WARNING')                         text = this.$t('message.main_akt_warnung');
      else if (value == 'NULL' || value == 'ACTIVE')                text = this.$t('message.main_ok');
      else if (value == 'REARMED')                                  text = this.$t('message.main_neu');
      else if (value == 'IMAGE_RECEIVED')                           text = this.$t('message.main_bild');
      else if (value == 'CATCH_DETECTED')                           text = this.$t('message.main_befallen');
      else if (value == 'ONLINE')                                   text = this.$t('message.main_online');
      else if (value == 'CONFIGURED')                               text = this.$t('message.main_konfiguriert');
      else if (value == 'MAINTAINED')                               text = this.$t('message.main_gewartet');
      else if (value == 'FALSE_TRIGGERED')                          text = this.$t('message.deviceStatus_falsch');

      return text;

    },
    /** oeffnet den Loading Spinner */
    showSpinner: function () {

      $('#spinner').show();
      document.body.style.cursor = 'wait';

    },
    /** schließt den Loading Spinner */
    hideSpinner: function () {

      $('#spinner').hide();
      document.body.style.cursor = 'default';

    },
    /** nacht UTF-8 konvertieren */
    encodeUtf8: function (s) { return unescape(encodeURIComponent(s)); },
    /** überprüft eine E-Mail-Adresse */
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email)
    },
    javaDateToMoment: function (javadate) {

      // wenn es eine Zahl ist, dann ist es ein Timestamp:
      const isNumeric = (num) => (typeof(num) === 'number' || typeof(num) === 'string' && num.trim() !== '') && !isNaN(num);
      if (isNumeric(javadate)) {
        let num = parseInt(javadate) / 1000;
        return moment.unix(num);
      }

      var mom = moment(javadate);
      return mom;

    },
    javaDateToDateInputFieldDate: function (javadate) {
      return this.javaDateToMoment(javadate).format('YYYY-MM-DD');
    },
    javaDateToDefaultDateFormat: function (javadate) {
      var mom = this.javaDateToMoment(javadate);
      return mom.format('DD.MM.YYYY');
    },
    javaDateToDefaultTimeFormat: function (javadate) {
      return this.javaDateToMoment(javadate).format('DD.MM.YYYY HH:mm');
    },
    logoutUser: function () {

      this.$store.commit('setUserRole', null);
      this.$store.commit('setUser', null);
      this.setCustomerAndUserRights(null);
      this.$store.commit('setAnzahlCustomer', null);
      // gecachte Standorte löschen
      this.$store.commit('setStandorte', null); // this.$store.getters.standorte

    },
    /**
     * setzt den Customer und holt die Benutzerrechte für den store
     */
    setCustomerAndUserRights: function (customer) {

      var that = this;
      this.$store.commit('setCustomer', customer);
      if (customer === null) {
        this.$store.commit('setBenutzerrechte', null);
      } else {

        // Benutzerrechte für den einen Standort setzen:
        const params  = {
          cardcode:         customer.cardcode,
          mandant:          customer.biotechCountry.name,
          biotechUser_id:   this.$store.getters.user.id
        };
        const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/getBenutzerrechte';
        axios({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {
          if (response.data && response.data.id) {
            that.$store.commit('setBenutzerrechte', response.data);
          }
        });

      }

      // Areas des Benutzers holen
      this.$store.commit('setShowAreas', null);
      if (this.$store.getters.user) {
        const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/getAllowedAreas';
        const params  = {
          biotechUser_id:   this.$store.getters.user.id
        };
        if (customer) {
          params.cardcode = customer.cardcode;
          params.mandant  = customer.biotechCountry.name;
        }
        axios({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {
          if (response.data && response.data.showAreas) {
            that.$store.commit('setShowAreas', response.data.showAreas);
          }
        });
      }

    },
    /**
     * dürfen Mängel bearbeitet werden?
     * @returns {boolean}
     */
    editMaengel: function () {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }
      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.editMaengel === false) {
        return false;
      }

      return true;

    },
    /**
     * zeigt den Text für den Status des Mangels
     * @param mangel
     */
    getAlertText: function (mangel) {

      if (mangel.status.name === 'OFFEN') {
        return this.$t('message.main_unerledigt');
      }
      if (mangel.status.name === 'ERLEDIGT') {
        return this.$t('message.main_bearbeitet');
      }
      if (mangel.status.name === 'ABGESCHLOSSEN') {
        return this.$t('message.main_abgeschlossen');
      }

    },
    /**
     * true wenn User Mängel sehen darf
     * @returns {boolean}
     */
    showMaengel: function () {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }

      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.showMaengel === false) {
        return false;
      }

      return true;

    },
    /**
     * true wenn User Lieferscheine sehen darf
     * @returns {boolean}
     */
    showLieferscheine: function () {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }

      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.showLieferscheine === false) {
        return false;
      }

      return true;

    },
    /**
     * prüft ob User Plan ändern darf
     * @returns {boolean}
     */
    editPlan: function () {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }

      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.editPlan === false) {
        return false;
      }

      return true;

    },
    /**
     * prüft ob User Mangel Fotos sehen darf
     * @returns {boolean}
     */
    showMaengelFotos: function () {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }

      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.showMaengelFotos === false) {
        return false;
      }

      return true;

    },
    /**
     * prüft ob User Area sehen darf
     * @returns {boolean}
     */
    showAreas: function (area_id) {

      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true;
      }

      if (!this.$store.getters.customer) {
        return false;
      }

      if (this.$store.getters.benutzerrechte && this.$store.getters.benutzerrechte.showAreas) {

        const arr = this.$store.getters.benutzerrechte.showAreas.split(',');
        for (var i = 0; i < arr.length; i++) {
          if (area_id == arr[i]) {
            return true;
          }
        }
        return false;

      }

      return true;

    },
    /**
     * Bild verkleinern und als base64 zurückliefern
     * resizeImage({
     *     file: $image.files[0],
     *     maxSize: 500
     * }).then(function (resizedImage) {
     *     console.log("upload resized image")
     * }).catch(function (err) {
     *     console.error(err);
     * });
     */
    resizeImageToBase64: function (settings) {

      var file = settings.file;
      var maxSize = settings.maxSize;
      var reader = new FileReader();
      var image = new Image();
      var canvas = document.createElement('canvas');

      // var dataURItoBlob = function (dataURI) {
      //   var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
      //     atob(dataURI.split(',')[1]) :
      //     unescape(dataURI.split(',')[1]);
      //   var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      //   var max = bytes.length;
      //   var ia = new Uint8Array(max);
      //   for (var i = 0; i < max; i++)
      //     ia[i] = bytes.charCodeAt(i);
      //   return new Blob([ia], { type: mime });
      // };

      var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataUrl; // base64
        // return dataURItoBlob(dataUrl);
      };

      return new Promise(function (ok, no) {

        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = function (readerEvent) {
          image.onload = function () { return ok(resize()); };
          image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);

      });

    }

  }

});

// Uebersetzungen:
let lang = navigator.language || navigator.userLanguage;
lang = lang.substring(0, 2);
if (lang != 'en' && lang != 'de') {
  lang = 'de'; // default
}
console.log('Browser language', lang);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
})

window.vm = new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
