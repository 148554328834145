<!-- Liste von Objekten an einer Position -->
<template>

    <transition name="fade" mode="out-in">
        <span class="objektliste" v-if="objektListe && objektListe.length > 0">

            <ul style="margin: 0; padding: 0">
                <li v-for="objekt in objektListe" :key="objekt.id">{{ objekt.bezeichnung }}</li>
            </ul>

        </span>
    </transition>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'ObjekteListe',
      components: { },
      props: ['position', 'objekte'],
      data () {
          return {
            objektListe: null
          }
      },
      watch: {

        position: function () {
          this.init();
        }

      },
      created() {
        this.init();
        if (this.objekte) {
          this.objektListe = this.objekte;
        }
      },
      methods: {

        /** holt alle Objekte einer Position */
        init: function() {

          console.log('get Objekte von Position ' + this.position.id);
          this.sicherheitsdatenblaetter = [];
          var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getObjekte';
          var that = this;
          var params = {
            position_id: this.position.id
          };
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && !response.data.error) {

              that.objektListe = response.data.objekte;
              var obj = {
                position: that.position,
                objekte: that.objektListe
              }
              that.$emit('set-objekte', obj);

            }

          }).catch(error => {
            console.log(error);
          });

        }

      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    span.objektliste ul {
        list-style-type: none;
    }

</style>
