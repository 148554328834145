<template>

    <span class="traplinkedNotificationWrapper" v-if="value">
      <div class="row">
        <div class="col-md-6">

          <b-form-checkbox
              v-if="customer.cardcode != 'ALLE'"
              :id="'checkbox-traplinkedNotification-' + value.name + '-customer-' + (customer ? customer.id : '')"
              v-model="isChecked"
              name="'checkbox-traplinkedNotification-' + value.name"
              value="true"
              unchecked-value="false">
                {{ value.label }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group
                size="sm"
                :id="'checkbox-traplinkedNotification-' + value.name + '-customer-' + (customer ? customer.id : '')">
              <b-button :variant="isChecked ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isChecked = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isChecked = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-traplinkedNotification-' + value.name + '-customer-' + (customer ? customer.id : '')">
              {{ value.label }}
             </label>
          </div>
          <!-- bei Sensoren kann die Histerese hinzugefügt werden -->
          <div v-if="value.name == 'SENSOR_ALARM' && (isChecked === 'true' || isChecked === true)" class="ml-4 mb-1">
              <label :for="'histerese-' + value.name + '-customer-' + (customer ? customer.id : '')">
                {{ $t('message.sensor_alarm_nach') }}
                <b-form-input
                    :id="'histerese-' + value.name + '-customer-' + (customer ? customer.id : '')"
                    v-model="histerese"
                    style="max-width: 100px; display: inline-flex;"
                    type="number"
                    min="0"
                    step="1"
                    class="ml-2 mr-2 form-control"
                    @blur="saveHisterese(histerese, null)"
                /> {{ $t('message.sensor_alarm_minuten') }}.
              </label>
          </div>

        </div>
        <div class="col-md-6">

          <b-form-checkbox
              v-if="customer.cardcode != 'ALLE'"
              :id="'checkbox-traplinkedNotification-app-' + value.name + '-customer-' + (customer ? customer.id : '')"
              v-model="isCheckedApp"
              name="'checkbox-traplinkedNotification-app-' + value.name"
              value="true"
              unchecked-value="false">
              {{ value.labelApp }}
          </b-form-checkbox>
          <div v-else>
            <b-button-group
                size="sm"
                :id="'checkbox-traplinkedNotification-app-' + value.name + '-customer-' + (customer ? customer.id : '')">
              <b-button :variant="isCheckedApp ? 'success' : 'outline-success'"
                        v-b-tooltip.hover.left
                        :title="$t('message.customerNotifications_fuer_alle_aktivieren')"
                        @click="isCheckedApp = true">
                <b-icon icon="check-circle"></b-icon>
              </b-button>
              <b-button variant="outline-danger"
                        v-b-tooltip.hover.right
                        :title="$t('message.customerNotifications_fuer_alle_deaktivieren')"
                        @click="isCheckedApp = false">
                <b-icon icon="dash-circle"></b-icon>
              </b-button>
            </b-button-group>
            &nbsp;<label :for="'checkbox-notificationNeuerMangel-app-' + (customer ? customer.id : '')">
               {{ value.labelApp }}
             </label>
          </div>
          <!-- bei Sensoren kann die Histerese hinzugefügt werden -->
          <div v-if="value.name == 'SENSOR_ALARM' && (isCheckedApp === 'true' || isCheckedApp === true)" class="ml-4 mb-1">
              <label :for="'histereseApp-' + value.name + '-customer-' + (customer ? customer.id : '')">
                {{ $t('message.sensor_alarm_nach') }}
                <b-form-input
                    :id="'histereseApp-' + value.name + '-customer-' + (customer ? customer.id : '')"
                    v-model="histereseApp"
                    style="max-width: 100px; display: inline-flex;"
                    type="number"
                    min="0"
                    step="1"
                    @blur="saveHisterese(null, histereseApp)"
                    class="ml-2 mr-2 form-control"
                /> {{ $t('message.sensor_alarm_minuten') }}.
              </label>
          </div>

        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
      name: 'TraplinkedNotifications',
      props: [
        'value',
         /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
        'customer',
      ],
      data () {
        return {
          isChecked: null,
          isCheckedApp: null,
          histerese: null,
          histereseApp: null,
          /** wird auf true gesetzt wenn init durchgelaufen ist */
          initFinished: false,
        }
      },
      watch: {

        isChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(newVal)
          }
        },
        isCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal)
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(null, newVal)
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      created() {

        this.isChecked    = this.value.checked
        this.isCheckedApp = this.value.checkedApp
        this.histerese    = this.value.histerese
        this.histereseApp = this.value.histereseApp
        this.init()

      },
      methods: {

        /** hole die Daten neu aus der Datenbank */
        init: function () {

          const that = this
          const url = process.env.VUE_APP_SERVER_URL + '/notification/getTraplinkedNotification'
          const params = {
            cardcode:               this.customer ? this.customer.cardcode : null,
            mandant:                this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:         this.$store.getters.user.id,
            traplinkedNotification: this.value.name,
          }
          axios({
            method: 'GET',
            params: params,
            url: url,
          }).then( response => {

            console.log('Traplinked Notififation Ausnahme gelesen', response.data)
            that.isChecked     = response.data.isChecked
            that.isCheckedApp  = response.data.isCheckedApp
            that.initFinished  = true

          }).catch(error => {
            console.log(error)
          })

        },
        /**
         * Histerese Wert speichern
         * @param newVal
         */
        saveHisterese: function (newVal, newValApp) {

          console.log('saveHisterese', newVal, newValApp)
          if (newVal != this.value.histerese || newValApp != this.value.histereseApp) {

            const that = this
            const url     = process.env.VUE_APP_SERVER_URL + '/notification/saveHisterese'
            const params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
              histerese:            newVal,
              histereseApp:         newValApp,
            }
            axios({
              method: 'GET',
              params: params,
              url: url
            }).then(() => {

              console.log('Histerese gespeichert.')
              if (that.customer.cardcode == 'ALLE') {

                let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
                let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
                if (newVal === false || newValApp === false) {
                  title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                  text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
                }
                that.$bvToast.toast(text, {
                  title: title,
                  variant: 'success',
                  autoHideDelay: 3000,
                })

              }
              that.hideSpinner()

            }).catch(error => {
              console.log(error)
              that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
                title: 'Error',
                variant: 'success',
                autoHideDelay: 5000
              })
              that.hideSpinner()
            })

          }

        },
        change: function (newVal, newValApp) {

          this.showSpinner()
          const that = this
          console.log('isChecked geändert auf ' + newVal + ' ' + newValApp)
          if (newVal === 'true' || newValApp === 'true' || newVal === true || newValApp === true) {

            const url     = process.env.VUE_APP_SERVER_URL + '/notification/saveTraplinkedNotification'
            const params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
            }
            if (newVal === 'true' || newVal === true) {
              params.notification = this.value.name
            } else if (newValApp === 'true' || newValApp === true) {
              params.notificationApp = this.value.name
            }

            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then(function () {

              console.log('Traplinked Notififation Ausnahme gespeichert.')
              if (that.customer.cardcode == 'ALLE') {

                let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
                let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
                if (newVal === false || newValApp === false) {
                  title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                  text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
                }
                that.$bvToast.toast(text, {
                  title: title,
                  variant: 'success',
                  autoHideDelay: 3000,
                })

              }
              that.hideSpinner()

            }).catch(error => {
              console.log(error)
              that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
                title: 'Error',
                variant: 'success',
                autoHideDelay: 5000
              })
              that.hideSpinner()
            })

          } else {

            const url     = process.env.VUE_APP_SERVER_URL + '/notification/deleteTraplinkedNotification'
            const params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
            };
            if (newVal === 'false' || newVal === false) {
              params.notification = this.value.name
            } else if (newValApp === 'false' || newValApp === false) {
              params.notificationApp = this.value.name
            }
            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then(function () {

              console.log('Traplinked Notififation gelöscht.')
              if (that.customer.cardcode == 'ALLE') {

                let title = that.$t('message.customerNotifications_fuer_alle_aktivieren')
                let text = that.$t('message.customerNotifications_fuer_alle_aktivieren_ok')
                if (newVal === false || newValApp === false) {
                  title = that.$t('message.customerNotifications_fuer_alle_deaktivieren')
                  text = that.$t('message.customerNotifications_fuer_alle_deaktivieren_ok')
                }
                that.$bvToast.toast(text, {
                  title: title,
                  variant: 'success',
                  autoHideDelay: 3000,
                })

              }
              that.hideSpinner()

            }).catch(error => {
              console.log(error)
              that.$bvToast.toast(that.$t('message.customerNotifications_fuer_alle_deaktivieren_fail'), {
                title: 'Error',
                variant: 'success',
                autoHideDelay: 5000
              })
              that.hideSpinner()
            })

          }

        }

      }

    }

</script>
