<!-- hole den Geschäftspartner = Customer des KeyUser -->
<template>

    <span v-if="keyUser && keyUser.customer">
      {{ keyUser.customer.kunde }} <small>({{ keyUser.customer.cardcode }})</small>
    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
      name: 'Geschaeftspartner',
      props: ['value'],
      data () {
        return {
          /** der KeyUser vom Datenbroker */
          keyUser: null,
        }
      },
      watch: {
        value: function () {
          this.init()
        }
      },
      created () {
        this.init()
      },
      methods: {

        init: function () {

          this.keyUser = null
          const that = this
          const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getKeyUser'
          const params  = {
            keyUserId: this.value.keyUserId,
          }

          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(response => {

            console.log('getKeyUser', response.data)
            that.keyUser = response.data

          }).catch(error => {
            console.log(error)
          })

        }

      }

    }

</script>
