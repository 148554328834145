<template>

    <div>
        <span class="alarmeJeObjektkategorie" v-if="chartdata">
           <horizontal-bar-chart
                   v-if="loaded"
                   v-on:set-chart="setChart"
                   :chartdata="chartdata"
                   :options="options" />
        </span>
        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage />
        </div>
    </div>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
    import SpinnerImage from "@/components/layout/SpinnerImage";

    export default {
      name: 'BarChartAlarmeJeObjektkategorieTraplinked',
      components: {
        HorizontalBarChart,
        SpinnerImage
      },
      props: ['traplinkedbericht', 'filter'],
      data () {
        return {
          loaded: false,
          /** Daten für den Bar Chart */
          chartdata: null,
          options: {
            legend: {
              display: false
            },
            onClick: this.chartClickEvent,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: this.$t('message.charts_alarme_je_objektkategorie')
            },
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: function (value) { if (Number.isInteger(value)) { return value + '%'; } },
                  stepSize: 10
                },
                stacked: true
              }],
              yAxes: [
                { stacked: true }
              ]
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';
                  if (label) {
                    label += ': ' + tooltipItem.xLabel;
                  }
                  return label + ' %';
                }
              }
            }
          },
          /** Chart.js Chart */
          chart: null,
          /** Filter der Charts */
          filterung: {
            /** Filter nach einer Objektkategorie */
            filterObjektkategorie: null,
            /** Filter nach einer Area */
            filterArea: null
          }
        }
      },
      watch: {
        traplinkedbericht: function () {
          this.init();
        },
        filter: {
          handler: function (newObj) {
            console.log('Filter in BarChartAlarmeJeObjektkategorieTraplinked geändert ' + newObj);
            this.filterung = newObj;
            this.init();
          },
          deep: true
        }
      },
      created () {

        this.init();

      },
      methods: {

        /**
         * Chart des Parents zurückliefern um Klick Events zu behandeln
         */
        setChart: function (obj) {
          console.log('set chart BarChartAlarmeJeObjektkategegorieTralinked: ' + obj);
          this.chart = obj;
        },
        /**
         * Klick auf den Chart, Filter setzen
         * @param event click Event
         */
        chartClickEvent: function (event) {

          console.log('chartClickEvent ' + event);
          var activeElement = this.chart.getElementAtEvent(event);
          if (activeElement) {
            console.log('index = ' + activeElement[0]._index);
            var label = this.chart.config.data.labels[activeElement[0]._index];
            console.log('label = ' + label);
            if (label) {
              this.filterObjektkategorie(label);
            }
          }

        },
        /**
         * nach einer Objektkategorie filtern
         * @param label Objekttyp Bezeichnung
         */
        filterObjektkategorie: function (label) {

          if (label) {
            console.log('Filter Objektkategorie gesetzt: ' + label);
            this.filterung.filterObjektkategorie = label;
            this.$emit('filter-objektkategorie', label);
          }

        },
        init: async function () {

          this.loaded = false;
          if (this.traplinkedbericht) {

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfBesuchsbericht';
            var params  = {
              traplinkedbericht_id:     this.traplinkedbericht.id,
              type:                     'BAR_CHART_ALARME_JE_OBJEKTKATEGORIE_TRAPLINKED',
              filterArea:               this.filterung ? this.filterung.filterArea : null,
              areasAllowed:             this.$store.getters.benutzerrechte ? this.$store.getters.benutzerrechte.showAreas : null,
              filterObjektkategorie:    this.filterung ? this.filterung.filterObjektkategorie : null
            };

            var that = this;
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              if (response.data) {

                that.chartdata = response.data.chartdata;
                if (that.chartdata) {

                  var datasets = that.chartdata.datasets;
                  if (!datasets) {
                    that.chartdata = null;
                  }

                } // end if that.chartdata

              }
              that.loaded = true;

            }).catch(error => {
              console.log(error);
              that.loaded = true;
            });
          }

        }

      }

    }

</script>
