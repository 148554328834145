<template>

    <span class="alarmeTraplinkedDevice" v-if="value">

        <span class="alarmeTaskResult" v-if="chartdata">

          <!-- aktueller Status -->
          <div class="mb-3" v-if="traplinkedDevices && traplinkedDevices.length > 0">

            <span v-for="(traplinkedDevice, index) in traplinkedDevices" :key="traplinkedDevice.id">
              <device-status-for-chart-legende v-model="traplinkedDevices[index]" :position-id="value" />
            </span>

          </div>

           <bar-chart
                   v-if="loaded"
                   v-on:set-chart="setChart"
                   :chartdata="chartdata"
                   :options="options"
           />

          <!-- Legende -->
          <!--
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageGruen + ')', marginBottom: 0 }" class="mt-2 ml-4">
            <li style="font-size: 0.9rem">OK: Falle nicht ausgelöst, aktiv oder neu gespannt</li>
          </ul>
          -->
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageGelb + ')', marginBottom: 0 }" class="ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.charts_falle_gelb') }}</li>
          </ul>
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageRot + ')' }" class="ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.charts_falle_rot') }}</li>
          </ul>

        </span>

        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage />
        </div>

        <!-- Ergebnisliste bei Klick auf Icon -->
        <b-modal :id="'modal-ergebnisse-' + value.id" title="Ergebnisliste" hide-footer size="lg" class="modal-ergebnisse">
          <monatsbericht-ergebnisse v-model="ergebnisliste" :hide-headers="true" />
        </b-modal>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import BarChart from '@/components/charts/BarChart';
    import SpinnerImage from "@/components/layout/SpinnerImage";
    import MonatsberichtErgebnisse from "@/components/ergebnisliste/MonatsberichtErgebnisse";
    import DeviceStatusForChartLegende from "@/components/traplinkedbericht/DeviceStatusForChartLegende";

    export default {
      name: 'BarChartAlarmeTraplinkedDeviceList',
      components: {
        BarChart,
        SpinnerImage,
        MonatsberichtErgebnisse,
        DeviceStatusForChartLegende
      },
      props: [
          /** die ID der Position */
          'value',
          /** Liste mit Traplinked Devices zum Anzeigen in der Legende */
          'traplinkedDevices'
      ],
      data () {
        return {
          loaded: false,
          /** Daten für den Bar Chart */
          chartdata: null,
          options: null,
          areasAllowed: null,
          /** Ergebnisliste auf Klick auf den Icon */
          ergebnisliste: null,
          listImageGruen: require('@/assets/images/list-style-plan-gruen.gif'),
          listImageGelb:  require('@/assets/images/list-style-plan-gelb.gif'),
          listImageRot:   require('@/assets/images/list-style-plan-rot.gif'),
        }
      },
      watch: {
        value: function () {
            this.init();
        }
      },
      created: function () {

        this.init();

      },
      methods: {

        init: async function () {

          this.loaded = false;
          if (this.value) {

            // ich brauche hier die Areas von allen Standorten des Benutzers:
            this.areasAllowed = null;
            if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
              this.areasAllowed = this.$store.getters.showAreas.join(',');
            }

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfBesuchsbericht';
            var params  = {
              position_id:   this.value,
              type:          'LINE_STACKED_CHART_TRAPLINKED_DEVICE_LIST',
              areasAllowed:  this.areasAllowed
            };

            var that = this;
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              console.log('response: ' + response.data);

              if (response.data) {

                that.chartdata = response.data.chartdata;
                if (that.chartdata) {

                  var datasets = that.chartdata.datasets;
                  if (datasets) {

                    var hasTicks = Object.keys(that.chartdata.ticks).length > 0;
                    // kalkuliere max y Wert, falls Required Text:
                    var max = 0;
                    if (that.chartdata.ticks && hasTicks) {
                      for (const [key, value] of Object.entries(that.chartdata.ticks)) {
                        console.log(key, value);
                        if (key > max) max = key;
                      }
                    }

                    var tooltips = null;
                    var ticks = null;
                    if (that.chartdata.ticks && hasTicks) {

                      console.log("chart hat ticks: " + that.chartdata.ticks.length);
                      // wenn bei den ticks kein -1 dabei ist, dann beginAtZero auf true setzen
                      var hasMinusEinsTick = false;
                      for (const [key, value] of Object.entries(that.chartdata.ticks)) {
                        if (key == -1 && value) {
                          hasMinusEinsTick = true;
                          break;
                        }
                      }

                      ticks =
                          {
                            beginAtZero: hasMinusEinsTick ? false : true,
                            callback: function (value) {
                              if (that.chartdata && that.chartdata.ticks) {
                                console.log("get from ticks" + that.chartdata.ticks[value]);
                                return that.chartdata.ticks[value];
                              }
                              console.log("get value = " + value + " " + that.chartdata.ticks);
                              return value;
                            },
                            min: hasMinusEinsTick ? -1 : 0,
                            max: max
                          };

                      tooltips = {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            console.log('tooltipItem: ' + tooltipItem);
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
                            label += ": " + that.chartdata.ticks[tooltipItem.yLabel];
                            return label;
                          }
                        }
                      };

                    }

                    that.options =
                        {
                          responsive: true,
                          title: {
                            display: false
                          },
                          legend: {
                            display: false
                          },
                          onClick: that.chartClickEvent,
                          hover: {
                            mode: 'index'
                          },
                          scales: {
                            xAxes: [{
                              display: true,
                              scaleLabel: {
                                display: false
                              }
                            }],
                            yAxes: [{
                              display: true,
                              scaleLabel: {
                                display: true,
                                labelString: ''
                              },
                            }]
                          }
                        };

                    if (hasTicks) {
                      that.options.tooltips = tooltips;
                      that.options.scales.yAxes[0].ticks = ticks;
                    } else {
                      that.options.scales.yAxes[0].ticks = {
                        beginAtZero: true,
                        min: 0
                      }
                    }

                  } else {
                    that.chartdata = null;
                    that.setDoNotShowChart();
                  }

                } else { // end if that.chartdata
                  that.setDoNotShowChart();
                }

              } else { // end if response.data
                that.setDoNotShowChart();
              }
              that.loaded = true;

            }).catch(error => {
              console.log(error);
              that.loaded = true;
              that.setDoNotShowChart();
            });
          }

        },
        /** Chart ausblenden, da keine Ergebnisse vorhanden sind */
        setDoNotShowChart: function () {
          this.$emit('do-not-show-chart', true);
        },
        /**
         * Chart des Parents zurückliefern um Klick Events zu behandeln
         */
        setChart: function (obj) {
          console.log("set chart: " + obj);
          this.chart = obj;
        },
        /**
         * Klick auf den Chart, Filter setzen
         * @param event click Event
         */
        chartClickEvent: function (event) {

          console.log('chartClickEvent ' + event);
          var activeElement = this.chart.getElementAtEvent(event);
          if (activeElement && activeElement[0]) {
            console.log('index = ' + activeElement[0]._index);
            console.log('datasetIndex = ' + activeElement[0]._datasetIndex);
            var datasetIndex = activeElement[0]._datasetIndex;
            var label = this.chart.config.data.labels[activeElement[0]._index];
            var index = activeElement[0]._index;
            console.log('label = ' + label);
            if (label) {

              // hole die Ergebnisse zu diesem Punkt vom Datenbroker
              // datasetIndex 0 = ROT, datasetIndex 1 = GELB
              this.getErgebnisse(datasetIndex, index, label);

            }
          }

        },
        /**
         * holt die Ergebnisse des Monats mit dem index und datasetIndex 0 = ROT, datasetIndex 1 = GELB
         */
        getErgebnisse: function (datasetIndex, index, label) {

          console.log('getErgebnisse für index = ' + index + ' datasetIndex = ' + datasetIndex + ' label = ' + label);

          this.showSpinner();
          this.ergebnisliste = null;
          var url            = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataErgebnisse';
          var params         = {
            position_id:          this.value,
            areasAllowed:         this.areasAllowed,
            datasetIndex:         datasetIndex,
            index:                index,
            label:                label
          };

          var that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            that.ergebnisliste = response.data;
            that.hideSpinner();
            if (that.ergebnisliste && that.ergebnisliste.length > 0) {
              that.$bvModal.show('modal-ergebnisse-' + that.value.id);
            }

          });

        }

      },


    }

</script>

<style scoped>
  .modal-ergebnisse .modal-lg {
      max-width: 1250px;
  }
</style>