<template>

    <div id="content" role="main" class="container">

      <div class="row mt-5 mb-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.team_h1") }}</h1>
          </div>
        </div>

      </div>
      <span v-if="vertriebsmitarbeiter">
        <div class="row mb-3">
          <div class="col-md-12">
            <h2 class="text-left">{{ $t("message.team_vertrieb") }}</h2>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <vertriebsmitarbeiter :person="vertriebsmitarbeiter" />
          </div>

        </div>
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>
      </span>
      <span v-if="personal && personal.length > 0">
        <div class="row mb-3 mt-5">

          <div class="col-md-12">
            <h2 class="text-left">{{ $t("message.team_kundenbetreuer") }}</h2>
          </div>

        </div>
        <div class="row">

            <div class="col-md-4" v-for="person in personal" :key="person.id">
              <person :person="person" />
            </div>

        </div>
      </span>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import person from '@/components/team/person'
  import vertriebsmitarbeiter from '@/components/team/vertriebsmitarbeiter'

  export default {
    name: 'Team',
    components: {
      person,
      vertriebsmitarbeiter
    },
    data () {
      return {
        personal: null,
        vertriebsmitarbeiter:  null
      }
    },
    created () {

      this.init()

    },
    methods: {

      init: function () {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name)

        this.showSpinner()
        const url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPersonal'
        const that     = this
        const customer = this.$store.getters.customer
        const params   = {
          customer: JSON.stringify(customer)
        }
        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          that.hideSpinner()
          that.personal = response.data.mitarbeiter
          that.vertriebsmitarbeiter = response.data.vertriebsmitarbeiter

        }).catch(error => {
          console.log(error)
          that.hideSpinner()
        });

      }

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  h2 {
    font-size: 1.5em;
  }

</style>
