<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.lieferscheine_h1") }}</h1>
          </div>
        </div>

      </div>
      <div class="row mt-5">

        <div class="col-md-12">

          <div id="filter" class="row pb-2">

            <div class="col-md-8">

              <b-input-group size="sm">
                <b-form-input v-model="keyword"
                              type="search"
                              id="filterInput"
                              :placeholder="$t('message.positionen_filter')"
                />
                <b-input-group-append>
                  <b-button :disabled="!keyword" @click="keyword = ''"><b-icon-x></b-icon-x></b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <div class="col-md-3"></div>
            <div class="col-md-1 text-right">

              <b-form-group horizontal label="" class="">
                <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
              </b-form-group>

            </div>

          </div>
          <div id="lieferscheinTable">

            <div class="card-box">
              <b-table small
                       hover
                       :items="items"
                       :fields="fields"
                       :current-page="currentPage"
                       :per-page="perPage"
                       :keyword="keyword">

                <template v-slot:cell(text)="data">
                  <a :href="data.item.url" target="_blank">
                      <b-icon-box-arrow-in-up-right></b-icon-box-arrow-in-up-right> {{ $t("message.lieferscheine_pdf") }}
                  </a>
                </template>

              </b-table>
            </div>

            <b-row>
              <b-col md="12" class="my-1">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              size="sm"
                              class="my-0"
                />
              </b-col>
            </b-row>

          </div>

        </div>

      </div>

    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {
    name: 'Lieferscheine',
    components: {
    },
    data () {
      return {

        sortBy: 'datum',
        sortDesc: true,
        /** Filter Query String */
        keyword: '',
        fields: [
          {
            label: this.$t("message.printMaengel_datum"),
            key: 'datum',
            sortable: true
          },
          {
            label: this.$t("message.lieferscheine_kw"),
            key: 'kw',
            sortable: true
          },
          {
            label: this.$t("message.printErgebnisliste_auftrag_nr"),
            key: 'scnummer',
            sortable: true
          },
          {
            label: this.$t("message.lieferscheine_thema"),
            key: 'thema',
            sortable: true
          },
          {
            label: '',
            key: 'text',
            sortable: false,
            class: 'text-right'
          }
        ],
        lieferscheine: null,
        //totalRows: null,
        currentPage: null,
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20

      }
    },
    computed: {

      // a computed getter
      items: function () {
        if (this.lieferscheine) {

          var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

          var selectedRows = lowercase
                  ? this.lieferscheine.filter(item =>

                          (item.datum && item.datum.toLowerCase().includes(lowercase)) ||
                          (item.kw && item.kw.toLowerCase().includes(lowercase)) ||
                          (item.scnummer && item.scnummer.toLowerCase().includes(lowercase)) ||
                          (item.thema && item.thema.includes(lowercase)) ||
                          (item.text && item.text.toLowerCase().includes(lowercase))

                  ) : this.lieferscheine;

          return selectedRows;

        }
        return null;
      },
      totalRows: function () {
        return this.items ? this.items.length : 0;
      }
    },
    created () {

      this.init();

    },
    methods: {

      init: function() {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

        this.showSpinner();
        var that    = this;
        var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getLieferscheine';
        var customer = this.$store.getters.customer;
        var params   = {
          customer: JSON.stringify(customer)
        };
        axios({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          that.lieferscheine = response.data;
          //that.totalRows     = that.lieferscheine ? that.lieferscheine.length : 0;
          that.currentPage = 1; //Pagination auf 1 Seite setzen
          that.hideSpinner();

        }).catch(error => {
          console.log(error);
          that.hideSpinner();
        });

      }

    }

  }

</script>
