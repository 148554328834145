<template>

    <div>
        <span class="alarmeJeBereich" v-if="chartdata">
           <bar-chart
                   v-if="loaded"
                   :chartdata="chartdata"
                   v-on:set-chart="setChart"
                   :options="options" />
        </span>
        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage />
        </div>
    </div>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import BarChart from '@/components/charts/BarChart.vue'
    import SpinnerImage from "@/components/layout/SpinnerImage";

    export default {
      name: 'BarChartAlarmeJeBereiche',
      components: {
        BarChart,
        SpinnerImage
      },
      props: ['besuchsbericht', 'customer', 'keyUser', 'subUser', 'filter', 'auftrag'],
      data () {
        return {
          loaded: false,
          /** Daten für den Bar Chart */
          chartdata: null,
          options: {
            legend: {
              display: false
            },
            onClick: this.chartClickEvent,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: this.$t('message.charts_alarme_je_bereich')
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: function (value) { if (Number.isInteger(value)) { return value + '%'; } },
                  stepSize: 10
                },
                stacked: true
              }],
              xAxes:[
                { stacked: true }
              ]
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';
                  if (label) {
                    label += ': ' + tooltipItem.yLabel;
                  }
                  return label + ' %';
                }
              }
            }
          },
          /** Chart.js Chart */
          chart: null,
          /** Filter der Charts */
          filterung: {
            /** Filter nach einer Objektkategorie */
            filterObjektkategorie: null,
            /** Filter nach einer Area */
            filterArea: null
          }
        }
      },
      watch: {
        besuchsbericht: function () {
            this.init();
        },
        customer: function () {
          this.init();
        },
        keyUser: function () {
          this.init();
        },
        subUser: function () {
          this.init();
        },
        filter: {
          handler: function (newObj) {
            console.log('Filter changed, reload chart for BarChartAlarmeJeBereich ' + newObj);
            this.filterung = newObj;
            this.init();
          },
          deep: true
        }
      },
      created () {

        this.init();

      },
      methods: {

        /**
         * Klick auf den Chart, Filter setzen
         * @param event click Event
         */
        chartClickEvent: function (event) {

          console.log('chartClickEvent ' + event);
          var activeElement = this.chart.getElementAtEvent(event);
          if (activeElement) {
            console.log('index = ' + activeElement[0]._index);
            var label = this.chart.config.data.labels[activeElement[0]._index];
            console.log('label = ' + label);
            if (label) {
              this.doFilterArea(label);
            }
          }

        },
        /**
         * nach einer Objektkategorie filtern
         * @param label Objekttyp Bezeichnung
         */
        doFilterArea: function (label) {

          if (label) {
            this.filterung.areaToFilter = label;
            this.$emit('filter-area', label);
          }

        },
        /**
         * Chart des Parents zurückliefern um Klick Events zu behandeln
         */
        setChart: function (obj) {
          this.chart = obj;
        },
        init: async function () {

          // Chart nicht anzeigen wenn z. B. keine Einzelaufträge angezeigt werden sollen,
          // aber derzeit ein Einzelauftrag ausgewählt wurde,
          // das gleiche für Monitoringaufträge
          if (this.filter && this.filter.auftragsart && this.besuchsbericht && this.auftrag && this.auftrag.herkunft) {

            if (
                (this.auftrag.herkunft == 'Servicevertrag'        && this.filter.auftragsart.monitoringbesuch == 'false') ||
                (this.auftrag.herkunft == 'Einzelauftrag'         && this.filter.auftragsart.einzelauftrag == 'false')    ||
                (this.filter.auftragsart.einzelauftrag == 'false' && this.filter.auftragsart.monitoringbesuch == 'false')
            ) {
              console.log('Chart BarChartAlarmeJeObjektkategorie nicht anzeigen da Serviceverträge gefiltert sind.');
              this.chartdata = null;
              this.loaded    = true;
              return;
            }

          }

          this.loaded = false;
          if (this.customer) this.$store.commit('setChartAvailableBarChartAlarmeJeBereiche', true);
          if (this.besuchsbericht || this.customer || this.keyUser || this.subUser) {

            // ein SubUser kann ein normaler SubUser oder ein KeyUser sein der von einem anderen KeyUser hinzugefügt wurde
            var subUserId = null;
            if (this.keyUser) {
              subUserId = this.keyUser.id;
            } else if (this.subUser) {
              subUserId = this.subUser.id;
            }

            // ich brauche hier die Areas von allen Standorten des Benutzers:
            let areasAllowed = null;
            if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
                areasAllowed = this.$store.getters.showAreas.join(',');
            }

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfBesuchsbericht';
            var params  = {
              besuchsbericht_id:        this.besuchsbericht ? this.besuchsbericht.id : null,
              customer_id:              this.customer ? this.customer.id : null,
              key_user_id:              this.keyUser ? this.keyUser.keyUserId : null,
              sub_user_id:              subUserId,
              mandant:                  this.keyUser ? this.keyUser.biotechCountry.name : null,
              type:                     'BAR_CHART_ALARME_JE_BEREICH',
              filterObjektkategorie:    this.filterung ? this.filterung.filterObjektkategorie : null,
              filterArea:               this.filterung ? this.filterung.areaToFilter : null,
              areasAllowed:             areasAllowed,
              auftragsart:              this.filterung ? JSON.stringify(this.filterung.auftragsart) : null
            };

            var that = this;
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              console.log("Chart Date AlarmeJeBereich verfügbar ...");
              if (response.data) {

                that.chartdata = response.data.chartdata;
                if (that.chartdata) {

                  var datasets = that.chartdata.datasets;
                  if (datasets) {

                    if (datasets[0].data[0] > 0 || datasets[1].data[0] > 0 || datasets[2].data[0] > 0 || datasets[3].data[0] > 0) {
                      console.log("alarme je bereich verfügbar.");
                      if (that.customer) that.$store.commit('setChartAvailableBarChartAlarmeJeBereiche', true);
                    } else {
                      that.chartdata = null;
                      if (that.customer) that.$store.commit('setChartAvailableBarChartAlarmeJeBereiche', false);
                    }

                  }

                } // end if that.chartdata
                if (response.data.dataStart) {
                  // setze Title des Charts
                  that.options.title.text = that.$t('message.charts_alarme_je_bereich_seit') + that.javaDateToDefaultDateFormat(response.data.dataStart);
                }

              }
              that.loaded = true;

            }).catch(error => {
              console.log(error);
              that.loaded = true;
            });
          }

        }

      }

    }

</script>
