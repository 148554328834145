<!-- Positionen eines Kunden auflisten -->
<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.positionen_h1") }}</h1>
            <h2 class="h4">{{ today }}</h2>
          </div>
        </div>

      </div>

      <div id="positionsliste" class="row">
        <div class="col-md-12 mb-3" v-if="totalRows < anzahl && !keyword">
            {{ $t("message.positionen_loading") }}<br/><br/>
          <b-progress :value="totalRows"
                      :max="anzahl"
                      show-value
                      animated>
          </b-progress>
        </div>

        <div class=" col-md-12 positionslisteWrapper" v-if="positionen && positionen.length > 0">

          <!-- export als PDF, Excel und Druck -->
          <div class="row mb-3" v-if="totalRows >= anzahl || keyword">
            <div class="col text-right">
              <b-button variant="outline-secondary"
                        size="sm"
                        class="mr-2"
                        @click="generateReport('excel')">
                <font-awesome-icon icon="file-excel" />&nbsp;{{ $t("message.positionen_excel") }}
              </b-button>
              <b-button variant="outline-secondary"
                        size="sm"
                        class="mr-2"
                        @click="generateReport('pdf')">
                <font-awesome-icon icon="file-pdf" />&nbsp;{{ $t("message.positionen_pdf") }}
              </b-button>
              <b-button variant="outline-secondary"
                        size="sm"
                        class="mr-2"
                        @click="print()">
                <font-awesome-icon icon="print" />&nbsp;{{ $t("message.positionen_drucken") }}
              </b-button>
            </div>
          </div>

          <!-- Positions Ergebnis Tabelle Filtern -->
          <div id="filter" class="row border-top mb-3 pt-3">

            <div class="col-md-11">

              <b-input-group size="sm">
                <b-form-input
                  v-model="keyword"
                  type="search"
                  id="filterInput"
                  :placeholder='$t("message.positionen_filter")'>
                </b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <div class="col-md-1 text-right">

              <b-form-group horizontal label="" class="">
                <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
              </b-form-group>

            </div>

          </div>

          <!-- Tabelle mit Positionen -->
          <b-table small
                   striped
                   :fields="fields"
                   :items="items"
                   :keyword="keyword"
                   :current-page="currentPage"
                   :per-page="perPage"
                   hover
                   :responsive="true">

            <template v-slot:cell(objekte)="row">

              <span class="objektliste" v-if="row.item.objekte && row.item.objekte.length > 0">

                  <ul style="margin: 0; padding: 0" class="list-unstyled">
                      <li v-for="objekt in row.item.objekte" :key="objekt.id">
                        {{ objekt.bezeichnung }}
                        <br/><small>(Objekttyp: {{ objekt.objekttyp.bezeichnung }})</small>
                      </li>
                  </ul>

              </span>

            </template>
            <!-- Link auf Plandarstellung -->
            <template v-slot:cell(positionsNr)="row">
                <span v-if="row.item.positionsNr && row.item.id && row.item.objekte && row.item.objekte.length > 0">
                  <ShowPositionInMap :position-id="row.item.id" :position-nr="row.item.positionsNr" />
                </span>
                <span v-else-if="row.item.positionsNr && row.item.id">
                  {{ row.item.positionsNr }}
                </span>
            </template>

          </b-table>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination :total-rows="totalRows"
                            :per-page="perPage"
                            v-model="currentPage"
                            class="my-0" />
            </b-col>
          </b-row>

        </div>

        <!-- Ergebnisse werden geladen, oder kein Ergebnis vorhanden -->
        <div v-else class="container">
          <!-- Loading spinner -->
          <div class="alert alert-info" role="alert" v-if="loading">
              {{ $t("message.positionen_daten_laden") }}
            <div class="progress">
              <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div class="text-center mt-5" v-else>
              <b-alert variant="success" show v-if="!positionen || positionen.length < 1">
                  {{ $t("message.positionen_empty") }}
              </b-alert>
          </div>

        </div>

      </div>

      <!-- Dummy Form um pdf und excel zu holen -->
      <form ref="form" action="" id="form_id" target="_blank" method="post" style="position: fixed; left: -1000px; top: -1000px;">
        <input name="type" id="form_type" value="">
        <input name="liste" id="form_liste" value="">
        <input name="customer" id="form_customer" value="">
      </form>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import moment from 'moment'

  export default {
    name: 'Positionen',
    components: {
      ShowPositionInMap: () => import('@/components/plan/ShowPositionInMap'),
    },
    data () {
      return {
        /** Tabellenspalten der Positionen  */
        fields: null,
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20,
        /** Gesamtanzahl der Zeilen für Pagination */
        //totalRows: 0,
        /** Pagination der Ergenistabelle */
        currentPage: 1,
        positionen: [],
        /** Filter Query String für Suche nach Position */
        keyword: '',
        /** loading Spinner wird angezeigt */
        loading: false,
        anzahl: 0,
        /** heutiges Datum */
        today: null
      }
    },
    mounted () {

      this.init();
      /** Tabellenspalten */
      this.fields = [
        {
          key: 'area.beschreibung',
          label: this.$t('message.printPositionen_bereich'),
          sortable: true,
          order: 10
        },
        {
          key: 'positionsNr',
          label: this.$t('message.printPositionen_position_nummer'),
          sortable: true,
          order: 20
        },
        {
          key: 'positionsBesch',
          label: this.$t('message.printPositionen_position'),
          sortable: true,
          order: 30
        },
        {
          key: 'objekte',
          label: this.$t('message.printPositionen_objekte'),
          sortable: false,
          order: 40
        }
      ];
      this.today = moment().format('DD.MM.YYYY');

    },
    computed: {

      // a computed getter
      items: function () {

        var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

        var selectedRows = lowercase
                ? this.positionen && this.positionen.filter( item =>

                    (item.areaBeschreibung && item.areaBeschreibung.toLowerCase().includes(lowercase)) ||
                    (item.area && item.area.beschreibung && item.area.beschreibung.toLowerCase().includes(lowercase)) ||
                    (item.positionsBesch && item.positionsBesch.toLowerCase().includes(lowercase)) ||
                    (item.positionsNr && item.positionsNr.toLowerCase().includes(lowercase)) ||
                    (item.objekte && item.objektBezeichnungen && item.objektBezeichnungen.toLowerCase().includes(lowercase)) ||
                    (item.objekte && item.objekttypBezeichnungen && item.objekttypBezeichnungen.toLowerCase().includes(lowercase))

                )
                : this.positionen;

        return selectedRows;

      },
      totalRows: function () {
        return this.items.length;
      }

    },
    methods: {

      init: function() {

        this.countPositionen();

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

      },
      /**
       * zeige Druck Page
       */
      print: function() {

        var items       = JSON.stringify(this.items);

        let routeData = this.$router.resolve({path: '/printPositionen/:name', query: {
            items:      items,
            kundenname: this.$store.getters.customer.kunde,
            adresse:    this.$store.getters.customer.zipcode + " " + this.$store.getters.customer.ort + ", " + this.$store.getters.customer.adresse,
            cardcode:   this.$store.getters.customer.cardcode
          }});
        console.log('open ' + routeData.href);
        window.open(routeData.href, '_blank');

      },
      /** holt alle Objekte einer Position */
      getObjekteOfPosition: async function (item) {

        var responseObjekte = [];
        var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getObjekte';

        var params = {
          position_id: item.id
        };
        await axios({
          method: 'GET',
          params: params,
          url: url
        }).then(function (response) {

          if (response.data && response.data.objekte)  {

            responseObjekte = response.data.objekte;
            console.log('Objekte von Position geholt: ' + responseObjekte.length)
            item.objekte = responseObjekte;

            // erstelle einen String um nach Objekten suchen zu können
            let str = '';
            let strObjekttypen = '';
            for (var j = 0; j < responseObjekte.length; j++) {
              str = str + item.objekte[j].bezeichnung + ' ';
              strObjekttypen = strObjekttypen + item.objekte[j].objekttyp.bezeichnung + ' ';
            }

            item.objektBezeichnungen    = str;
            item.objekttypBezeichnungen = strObjekttypen;

            console.log('Objekte gesetzt: ' + item.objekte.length + ' String zur Suche ' + item.objektBezeichnungen);

          }

        }).catch(error => {
          console.log(error);
        });

        return responseObjekte;

      },
      /**
       * PDF oder Excel der Ergebnisliste anzeigen
       */
      generateReport: async function (type) {

        this.showSpinner();
        // es kann sein, dass die Objekte zu den Positionen noch nicht gelesen wurden, da noch nicht auf
        // die entsprechende Seite geblättert wurde, hole diese falls sie leer sind:
        for (var i = 0; i < this.items.length; i++) {
          if (!this.items[i].objekte || this.items[i].objekte.length < 1) {

            console.log('hole Objekte ...');
            this.items[i].objekte = await this.getObjekteOfPosition(this.items[i]);
            console.log('Objekte in liste gesetzt: ' + this.items[i].objekte.length);

          }
        }

        console.log('Objekte der Positionen gecheckt.');
        var serverurl       = process.env.VUE_APP_SERVER_URL;
        var liste           = JSON.stringify(this.items);
        liste               = encodeURI(liste);
        var kunde           = JSON.stringify(this.$store.getters.customer);
        kunde               = encodeURI(kunde);
        var url             = serverurl + '/report/generatePositionenReport';

        this.form_action = url;
        document.getElementById('form_id').action      = url;
        document.getElementById('form_type').value     = type;
        document.getElementById('form_liste').value    = liste;
        document.getElementById('form_customer').value = kunde;

        this.hideSpinner();

        this.$refs.form.submit();

      },
      countPositionen: function() {

        var customer = this.$store.getters.customer;
        if (customer) {

          this.showSpinner();
          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/countPositionen';
          var that     = this;
          var params   = {
            customer_id: customer.id,
            areas:       this.$store.getters.benutzerrechte ? this.$store.getters.benutzerrechte.showAreas : null
          };
          axios ({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            that.hideSpinner();
            that.anzahl = response.data.anzahl;
            that.getPositionen();

          }).catch(error => {
            console.log(error);
            that.hideSpinner();
          });

        }

      },
      getPositionen: async function() {

        var customer = this.$store.getters.customer;
        if (customer && this.anzahl) {

          const maxperrequest = 10;

          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionen';
          var that     = this;

          var iterationen = this.anzahl / maxperrequest;
          var offset = 0;
          for (var i = 0; i < iterationen; i++) {

            offset     = (i * maxperrequest);
            var params = {
              customer_id: customer.id,
              areas:       this.$store.getters.benutzerrechte ? this.$store.getters.benutzerrechte.showAreas : null,
              max:         maxperrequest,
              offset:      offset
            };
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then( async response => {

              for (var i = 0; i < response.data.length; i++) {
                if (response.data[i]) that.positionen.push(response.data[i]);
              }
              // Objekte der Position holen:
              for (var i = 0; i < that.positionen.length; i++) {
                if (!that.positionen[i].objekte || that.positionen[i].objekte.length < 1) {

                  that.positionen[i].objekte = await that.getObjekteOfPosition(that.positionen[i]);
                  console.log('Objekte in liste gesetzt: ' + that.positionen[i].objekte);

                }
              }
              // that.$forceUpdate() klappt nicht, daher Array neu schreiben:
              let cCopy = [...that.positionen]; // erzeugt kopie des arrays
              that.positionen = cCopy;

            }).catch(error => {
              console.log(error);
            });

          }

        }

      }

    }

  }

</script>