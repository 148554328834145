<template>

    <span class="maengelTable">

        <b-alert variant="success" show v-if="empty">
            {{ $t('message.maengelTable_alert') }}
        </b-alert>

        <transition name="fade" mode="out-in">
          <div v-if="maengel">

            <div class="maengelTableWrapper">

              <!-- export als PDF, Excel und Druck -->
              <div class="row mb-3" v-if="items && items.length > 0">
                <div class="col text-right">
                  <b-button variant="outline-secondary"
                            size="sm"
                            class="mr-2"
                            @click="generateReport('excel')">
                    <font-awesome-icon icon="file-excel" />&nbsp;{{ $t('message.positionen_excel') }}
                  </b-button>
                  <b-button variant="outline-secondary"
                            size="sm"
                            class="mr-2"
                            @click="generateReport('pdf')">
                    <font-awesome-icon icon="file-pdf" />&nbsp;{{ $t('message.positionen_pdf') }}
                  </b-button>
                  <b-button variant="outline-secondary"
                            size="sm"
                            class="mr-2"
                            @click="print()">
                    <font-awesome-icon icon="print" />&nbsp;{{ $t('message.positionen_drucken') }}
                  </b-button>
                </div>
              </div>

              <!-- Ergebnis Tabelle Filtern -->
              <div id="filter"
                   class="row border-top mb-3 pt-3"
                   v-if="(items && items.length > 1) || hasAbgeschlosseneMaengel">

                  <div class="col-md-12" v-if="hasAbgeschlosseneMaengel && customer">
                    <div class="text-right mb-3">
                      <b-form-checkbox
                              id="checkbox-abgeschlossene"
                              v-model="showAbgeschlossen"
                              name="checkbox-abgeschlossene"
                              value="ABGESCHLOSSEN"
                              unchecked-value="ERLEDIGT">
                        {{ $t('message.maengelTable_show_abgeschlossen') }}
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div class="col-md-11">

                      <b-input-group size="sm">
                          <b-form-input
                                  v-model="keyword"
                                  type="search"
                                  id="filterInput"
                                  :placeholder="$t('message.positionen_filter')" />
                          <b-input-group-append>
                              <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                          </b-input-group-append>
                      </b-input-group>

                  </div>
                  <div class="col-md-1 text-right">

                      <b-form-group horizontal label="" class="">
                          <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                      </b-form-group>

                  </div>

              </div>

              <b-table striped
                       small
                       :fields="fields"
                       :items="items"
                       :keyword="keyword"
                       :current-page="currentPage"
                       :per-page="perPage"
                       hover
                       :responsive="true">

                  <template v-slot:cell(nummer)="row">

                    <span v-if="row.item.id > 0">
                      {{ row.item.nummer }}
                    </span>
                    <span v-else-if="row.item.id == -1">

                      <!-- zeige Plan Mangel als höchste Nummer an -->
                      {{ planMangelNummer }}

                    </span>

                  </template>

                  <template v-slot:cell(thumb)="row">
                      <span v-if="showMaengelFotosRight && row.item.id > 0">
                          <span v-if="row.item.bilder && row.item.bilder.length > 0">
                              <b-img @click="selected(row.item)"
                                     thumbnail
                                     :src="row.item.bilder[0].url"
                                     fluid
                                     :alt="row.item.descriptionShort"
                                     style="max-height: 200px; max-width: 200px; cursor: pointer">
                              </b-img>
                          </span>
                          <span v-if="!row.item.bilder || row.item.bilder.length < 1">
                              <div class="alert alert-secondary" role="alert">
                                  {{ $t('message.maengelTable_keine_bilder') }}
                              </div>
                          </span>
                      </span>
                      <span v-if="row.item.id == -1">
                        <!-- zeige einen Plan als Mangel Bild -->
                        <router-link to="/Plan"
                                     href="#">

                            <img src="../../assets/images/plan-dummy.jpg"
                                 alt="Plan für Biotech Analytics"
                                 class="img-thumbnail img-fluid"
                                 style="max-height: 200px; max-width: 200px; cursor: pointer"
                            />

                        </router-link>
                      </span>
                  </template>

                  <template v-slot:cell(dateCreated)="row">
                      {{ javaDateToDefaultDateFormat(row.item.dateCreated) }}
                  </template>

                  <template v-slot:cell(status)="row">
                      <b-alert :variant="getAlertVariant(row.item)" show style="margin-bottom: 0; text-align: center; padding: 0.25rem;">{{ getAlertText(row.item) }}</b-alert>
                  </template>

                  <template v-slot:cell(descriptionDetail)="row">
                      <span v-html="row.item.descriptionDetail.replace('\n','<br/>').substr(0, 1000)"></span>
                  </template>

                  <template v-slot:cell(actions)="row">
                      <b-button @click="selected(row.item)" v-if="row.item.id > 0">
                         <b-icon icon="pip" class="circleIcon mr-2"></b-icon> {{ $t('message.maengelTable_show_details') }}
                      </b-button>
                  </template>

                  <!-- Link auf Plandarstellung von Position weg -->
                  <template v-slot:cell(position)="row">
                    <span v-if="row.item.position && row.item.position.id">
                      <ShowPositionInMap :position-id="row.item.position.id"
                                         :position-nr="row.item.position.positionsNr"
                      />
                    </span>
                  </template>

                  <!-- Mangel Pritorität und dessen default Wert -->
                  <template #cell(mangelPrioritaet)="row">
                    <mangel-prioritaet v-model="row.item" />
                  </template>

              </b-table>

              <b-row v-if="items && items.length > 1">
                  <b-col md="12" class="my-1">
                      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
                  </b-col>
              </b-row>

            </div>

          </div>
        </transition>

        <!-- Details Modal -->
        <b-modal id="bv-modal-details" hide-footer size="lg">

            <template v-slot:modal-title>
              {{ $t('message.maengelTable_details') }}
            </template>

            <div class="row" v-if="mangel">

                <div class="col-md-6" v-if="showMaengelFotosRight">

                    <span v-if="mangel.bilder && mangel.bilder.length > 0">
                        <image-carousel
                                v-on:image-deleted="imageDeleted"
                                :show-image="showImage"
                                :mangel="mangel">
                        </image-carousel>
                    </span>
                    <span v-if="!mangel.bilder || mangel.bilder.length < 1">
                        <div class="alert alert-secondary" role="alert">
                            {{ $t('message.maengelTable_keine_bilder') }}
                        </div>
                    </span>

                    <!-- Bild hochladen -->
                    <b-card class="mt-3" bg-variant="light" :header="$t('message.maengelTable_bilder_hochladen_desc')">
                      <b-card-text>
                        <b-form-file
                            v-model="fileUpload"
                            multiple
                            accept="image/jpeg, image/png, image/gif"
                            :state="Boolean(fileUpload)"
                            :placeholder="$t('message.maengelTable_bilder_hochladen')"
                            drop-placeholder="Drop File ...">
                        </b-form-file>
                      </b-card-text>
                    </b-card>
                    <!-- Bild hochladen und Mangel abschließen -->
                    <b-card class="mt-2"
                            bg-variant="warning"
                            :header="mangel.status.name === 'OFFEN' && this.$store.getters.user_role && (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER || this.$store.getters.user_role.authority == this.ROLE_KEY_USER) ? $t('message.maengelTable_bilder_hochladen_abschliessen_desc_customer') : $t('message.maengelTable_bilder_hochladen_abschliessen_desc')">
                      <b-card-text>
                        <b-form-file
                            v-model="fileUploadAbschliessen"
                            multiple
                            accept="image/jpeg, image/png, image/gif"
                            :state="Boolean(fileUploadAbschliessen)"
                            :placeholder="mangel.status.name === 'OFFEN' && this.$store.getters.user_role && (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER || this.$store.getters.user_role.authority == this.ROLE_KEY_USER) ? $t('message.maengelTable_bilder_hochladen_abschliessen_customer') : $t('message.maengelTable_bilder_hochladen_abschliessen')"
                            drop-placeholder="Drop File ...">
                        </b-form-file>
                      </b-card-text>
                    </b-card>

                </div>
                <div :class="[showMaengelFotosRight ? 'col-md-6' : 'col-md-12']">

                    <form>
                        <b-list-group>
                            <b-list-group-item>
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'dateCreated_' + mangel.id">{{ $t('message.maengelTable_aufgenommen_am') }}</label>
                                    <div class="">
                                        <input type="text"
                                               class="form-control"
                                               :id="'dateCreated_' + mangel.id"
                                               name="dateCreated"
                                               :value="javaDateToDefaultDateFormat(mangel.dateCreated)"
                                               readonly
                                        />
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="mangel.reportedBy || mangel.customerEmail">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'reportedBy_' + mangel.id">{{ $t('message.maengelTable_aufgenommen_von') }}</label>
                                    <div class="">
                                        <input type="text"
                                               v-if="mangel.reportedBy"
                                               class="form-control"
                                               :id="'reportedBy_' + mangel.id"
                                               name="reportedBy"
                                               :value="mangel.reportedBy.name"
                                               readonly
                                        />
                                        <input type="text"
                                               v-else
                                               class="form-control"
                                               :id="'reportedBy_' + mangel.id"
                                               name="reportedBy"
                                               :value="mangel.customerEmail"
                                               readonly
                                        />
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item>
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'status_' + mangel.id">{{ $t('message.printMaengel_status') }}</label>
                                    <div class="">
                                        <b-alert :variant="getAlertVariant(mangel)" show style="margin-bottom: 0">{{ getAlertText(mangel) }}</b-alert>
                                    </div>
                                </div>
                            </b-list-group-item>
                            <!-- Priorität -->
                            <b-list-group-item>
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'prioritaet_' + mangel.id">{{ $t('message.customerNotifications_prioritaet') }}</label>
                                    <div class="">
                                        <edit-mangel-prioritaet v-model="mangel"
                                                                v-on:mangel-prioritaet-changed="mangelPrioritaetChanged"
                                        />
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="mangel.dateFinished && mangel.status.name === 'ABGESCHLOSSEN'">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'dateFinished_' + mangel.id">{{ $t('message.maengelTable_abgeschlossen') }}</label>
                                    <div class="">
                                        <input type="text"
                                               class="form-control"
                                               :id="'dateFinished_' + mangel.id"
                                               name="dateFinished"
                                               :value="javaDateToDateInputFieldDate(mangel.dateFinished)"
                                               readonly
                                        />
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="mangel.position">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'positionsBesch_' + mangel.id">{{ $t('message.maengelTable_pos_beschreibung') }}</label>
                                    <div class="">
                                      <span v-if="mangel.position.positionsNr">{{ mangel.position.positionsNr }} </span>
                                      <span v-if="mangel.position.positionsBesch"></span>{{ mangel.position.positionsBesch }}
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-else-if="mangel.positionsBesch || mangel.positionsNr">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'positionsBesch_' + mangel.id">{{ $t('message.maengelTable_pos_beschreibung') }}</label>
                                    <div class="">
                                      <span v-if="mangel.positionsNr">{{ mangel.positionsNr }} </span>
                                      <span v-if="mangel.positionsBesch"></span>{{ mangel.positionsBesch }}
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="mangel.descriptionShort">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'descriptionShort_' + mangel.id">{{ $t('message.printMaengel_kurzbeschreibung') }}</label>
                                    <div class="">
                                        <b-form-textarea
                                            :id="'descriptionShort_' + mangel.id"
                                            v-model="mangel.descriptionShort"
                                            name="descriptionShort"
                                            readonly
                                            rows="3"
                                            max-rows="6">
                                        </b-form-textarea>
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="mangel.descriptionDetail">
                                <div class="form-group" style="margin-bottom: 0">
                                    <label class="col-form-label"
                                           :for="'descriptionDetail_' + mangel.id">{{ $t('message.printMaengel_beschreibung') }}</label>
                                    <div class="">
                                        <b-form-textarea
                                                :id="'descriptionDetail_' + mangel.id"
                                                v-model="mangel.descriptionDetail"
                                                name="descriptionDetail"
                                                readonly
                                                rows="3"
                                                max-rows="6">
                                        </b-form-textarea>
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item>

                                <div class="row" v-if="editMaengelRight">
                                    <div class="col-sm-6">
                                        <b-button v-on:click="showNoteModal(mangel)"
                                                  v-if="mangel.status.name === 'OFFEN' || mangel.status.name === 'ERLEDIGT'">
                                            <span class="oi oi-pencil"></span> {{ $t('message.maengelTable_add_notiz') }}
                                        </b-button>
                                        <!-- Mangel als PDF exportieren -->
                                        <b-button variant="outline-secondary"
                                                  size="sm"
                                                  class="ml-2"
                                                  @click="generateReport('pdf', mangel)">
                                          <font-awesome-icon icon="file-pdf" />&nbsp;{{ $t('message.positionen_pdf') }}
                                        </b-button>
                                    </div>
                                    <div class="col-sm-6 text-right"
                                         v-if="mangel.status.name === 'OFFEN' || mangel.status.name === 'ERLEDIGT'">

                                        <!-- Key User darf den Mangel löschen -->
                                        <b-button v-if="$store.getters.user_role.authority == ROLE_KEY_USER || $store.getters.user_role.authority == ROLE_ADMIN"
                                                  variant="outline-danger"
                                                  class="mr-2"
                                                  size="sm"
                                                  v-on:click="confirmDeleteMangel(mangel)">
                                          <span class="oi oi-trash"></span> {{ $t('message.default_loeschen') }}
                                        </b-button>

                                        <b-button variant="warning"
                                                  v-if="showErledigtButton(mangel)"
                                                  v-on:click="mangelErledigt(mangel, 'ERLEDIGT')">
                                            <span class="oi oi-check"></span> {{ $t('message.customerNotifications_status_bearbeitet') }}
                                        </b-button>

                                        <b-button variant="warning"
                                                  v-if="showAbgeschlossenButton(mangel)"
                                                  v-on:click="mangelErledigt(mangel, 'ABGESCHLOSSEN')">
                                            <span class="oi oi-check"></span> {{ $t('message.maengelTable_abschliessen') }}
                                        </b-button>

                                    </div>
                                </div>

                            </b-list-group-item>
                        </b-list-group>
                    </form>

                </div>

            </div>
            <div class="row">
                <div class="col-md-12 text-right">
                    <b-button class="mt-3"
                              @click="$bvModal.hide('bv-modal-details')">
                      {{ $t('message.maengelTable_schliessen') }}
                    </b-button>
                </div>
            </div>
        </b-modal>

        <!-- The modal for the note -->
        <b-modal id="note-modal"
                 ref="note-modal"
                 hide-footer
                 :title="$t('message.maengelTable_add_notiz')">

            <p>
                <b-form-textarea
                        id="textarea"
                        v-model="notiz"
                        :placeholder="$t('message.maengelTable_add_notiz_ph') + ' ...'"
                        rows="3"
                        max-rows="6">
                </b-form-textarea>
            </p>
            <b-button class="mt-3" block @click="addNote()">
              <span class="oi oi-check"></span> {{ $t('message.default_speichern') }}
            </b-button>

        </b-modal>

      <!-- Dummy Form um pdf und excel zu holen -->
      <form ref="form" action="" id="form_id" target="_blank" method="post" style="position: fixed; left: -1000px; top: -1000px;">
        <input name="type"      id="form_type"      value="" type="text" ref="form_type" />
        <input name="liste"     id="form_liste"     value="" type="text" ref="form_liste" />
        <input name="customer"  id="form_customer"  value="" type="text" ref="form_customer" />
      </form>

    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import bootbox from 'bootbox'
    import imageCarousel from '@/components/maengelliste/ImageCarousel'
    import mangelPrioritaet from '@/components/maengelliste/mangelPrioritaet'
    import editMangelPrioritaet from '@/components/maengelliste/editMangelPrioritaet'

    // Define a new component
    export default {
        name: 'MaengelTable',
        components: {
          imageCarousel,
          ShowPositionInMap: () => import('@/components/plan/ShowPositionInMap'),
          mangelPrioritaet,
          editMangelPrioritaet,
        },
        props: {

            /** Array mit Mängeln */
            maengellist: {
              type: Array,
              required: false,
              default: null
            },
            /**
             * Mängel werden vom Kunden gelesen,
             * dabei können auch abgeschlossenen Mängel eingeblendet werden
             */
            customer: {
                type: Object,
                required: false,
                default: null
            },
            abgeschlossen: {
                type: String,
                required: false,
                default: null
            }

        },
        /*
          Defines the data used by the component
        */
        data: function () {
          return {
            /** Text einer Kundennotiz die hinzugefügt wird */
            notiz: null,
            maengel: [],
            anzahl: 0,
            fields: [
              {
                key: 'nummer',
                label: this.$t('message.printMaengel_nummer'),
                sortable: true
              },
              {
                key: 'dateCreated',
                label: this.$t('message.printMaengel_datum'),
                sortable: true
              },
              {
                key: 'position',
                label: this.$t('message.printPositionen_position'),
                sortable: false
              },
              {
                key: 'descriptionShort',
                label: this.$t('message.printMaengel_kurzbeschreibung'),
                sortable: true
              },
              {
                key: 'descriptionDetail',
                label: this.$t('message.printMaengel_beschreibung'),
                sortable: true
              },
              {
                key: 'zustaendig',
                label: this.$t('message.printMaengel_zustaendig'),
                sortable: true
              },
              {
                key: 'status',
                label: this.$t('message.printMaengel_status'),
                sortable: true
              },
              {
                key: 'mangelPrioritaet',
                label: this.$t('message.customerNotifications_prioritaet'),
                sortable: false
              },
              {
                key: 'thumb',
                label: this.$t('message.printMaengel_foto'),
              },
              {
                key: 'actions',
                label: '',
                class: 'text-right'
              }
            ],
            /** Filter Query String für Suche nach Position */
            keyword: '',
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            /** Gesamtanzahl der Zeilen für Pagination */
            //totalRows: 0,
            /** Pagination der Ergenistabelle */
            currentPage: 1,
            /** der Mangel der bearbeitet wird */
            mangel: null,
            /** auf true setzen falls keine Mängel vorhanden sind */
            empty: false,
            showAbgeschlossen: 'ERLEDIGT',
            /** checkbox zur Anzeige von erledigten Mängel nur anzeigen wenn auch erledigte vorhanden sind */
            hasAbgeschlosseneMaengel: false,
            /** ein Mangel Bild wird hochgeladen, ist ein Array da multiple aktiviert ist */
            fileUpload: [],
            /** Mangel Bilder die hochgeladen wurden und zugleich der Mangel abgeschlossen wird */
            fileUploadAbschliessen: [],
            resizedImages: null,
            /** wenn gesetzt, dann soll dieses Bild in den Bildern angezeigt werden */
            showImage: null,
          }
        },
        computed: {

          /** dürfen Mängel verändert werden? */
          editMaengelRight: function () {

            return this.editMaengel();

          },
          /** dürfen Mängel verändert werden? */
          showMaengelFotosRight: function () {

            return this.showMaengelFotos();

          },
          items: function () {

            var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

            var selectedRows = lowercase
                ? this.maengel.filter( item =>

                    (item.dateCreated         && item.dateCreated.toLowerCase().includes(lowercase)) ||
                    (item.descriptionShort    && item.descriptionShort.toLowerCase().includes(lowercase)) ||
                    (item.descriptionDetail   && item.descriptionDetail.toLowerCase().includes(lowercase))

                )
                : this.maengel;

            return selectedRows;

          },
          totalRows: function () {
            return this.items.length;
          },
          /** bei einem Plan Mangel, die höchste Nummer ermitteln und um eins erhöhen */
          planMangelNummer: function () {

            if (this.maengel) {
              let nummer = 0;
              for (var i = 0; i < this.maengel.length; i++) {
                if (this.maengel[i].nummer > nummer) nummer = this.maengel[i].nummer;
              }
              return nummer + 1;
            }
            return 0;

          }

        },
        watch: {

          /**
           * Bilder wurden zum Upload ausgewählt
           */
          fileUpload: function () {
            this.uploadImages()

          },
          fileUploadAbschliessen: function () {
            this.uploadImages(true)
          },
          customer: function () {
            // Mängel neu holen
            this.init()
          },
          /** abgeschlossen Button zeigen bei Mangel Details */
          abgeschlossen: function (val) {
            // Mängel neu holen
            this.showAbgeschlossen = val
          },
          showAbgeschlossen: function () {
            this.init()
          }

        },
        mounted: function () {

          if (this.abgeschlossen) this.showAbgeschlossen = this.abgeschlossen;
          this.hasAbgeschlosseneMaengel = false;
          this.init();

        },
        methods: {

          /**
           * ein Bild eines Mangels wurde gelöscht
           * @param obj
           * {
           *   mangel: that.mangel,
           *   image: image,
           * }
           */
          imageDeleted: function (obj) {

            outer: for (let i = 0; i < this.maengel.length; i++) {
              if (obj.mangel.id === this.maengel[i].id) {
                for (let j = 0; j < this.maengel[i].bilder.length; j++) {
                  if (obj.image.id === this.maengel[i].bilder[j].id) {
                    this.maengel[i].bilder.splice(j, 1)
                    break outer
                  }
                }
              }
            }

          },
          /**
           * Bilder zum Mangel hochladen
           */
          uploadImages: function (abschliessen) {

            this.resizedImages = []
            const that = this
            let fileuploads
            if (abschliessen) {
              fileuploads = this.fileUploadAbschliessen
            } else {
              fileuploads = this.fileUpload
            }
            fileuploads.forEach( file => {

              // Check if the file is an image
              if (!file.type.startsWith('image/')) {

                let text = that.$t('message.printMaengel_kein_bild')
                text = text.replace('{0}', file.name)

                that.$bvToast.toast(text, {
                  title: that.$t('message.printMaengel_kein_bild_title'),
                  variant: 'danger',
                  autoHideDelay: 5000
                })

              } else {

                const img = new Image()
                const reader = new FileReader()
                let status = 'OFFEN'
                if (abschliessen && this.showErledigtButton(this.mangel)) {
                  status = 'ERLEDIGT'
                } else if (abschliessen) {
                  status = 'ABGESCHLOSSEN'
                }
                reader.onload = (e) => {
                  img.onload = () => {
                    that.resizeImageAndUpload(img, file, status)
                  }
                  img.src = e.target.result
                }

                reader.readAsDataURL(file)

              }

            })

            if (abschliessen) {

              if (this.showErledigtButton(this.mangel)) {
                this.mangelErledigt(this.mangel, 'ERLEDIGT')
              } else if (this.showAbgeschlossenButton(this.mangel)) {
                this.mangelErledigt(this.mangel, 'ABGESCHLOSSEN')
              }

            }

          },
          /**
           * Bild auf max 2048 Breite verkleinern
           * @param img
           * @param file
           */
          resizeImageAndUpload: function (img, file, status) {

            this.showSpinner()
            const maxWidth = 2048
            let width = img.width
            let height = img.height

            if (width > maxWidth) {
              height = Math.round(height * (maxWidth / width))
              width = maxWidth
            }

            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height

            const context = canvas.getContext('2d')
            context.drawImage(img, 0, 0, width, height)

            const base64String = canvas.toDataURL(file.type)
            const url = base64String
            this.resizedImages.push({
              url,
              name: file.name,
              file: base64String
            })

            // Upload the resized image as base64
            this.uploadImage(base64String, file.name, status, file.type)

          },
          async uploadImage(base64String, fileName, status, mimeType) {

            const that = this
            try {

              const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/saveMangelImage'
              const response = await axios.post(url, {
                image: base64String,
                name: fileName,
                mangel_id: that.mangel.id,
                status: status,
                mimeType: mimeType,
              }, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })

              console.log('Upload erfolgreich:', response.data)
              const text = that.$t('message.maengelTable_upload_erfolgreich').replace('{0}', fileName)
              that.$bvToast.toast(text, {
                title: that.$t('message.maengelTable_bilder_hochladen'),
                variant: 'success',
                autoHideDelay: 5000
              })
              if (!that.mangel.bilder) that.mangel.bilder = []
              if (response.data.file)  that.mangel.bilder.push(response.data.file)

              // mangel detailtext tauschen:
              if (response.data.mangel && response.data.mangel.descriptionDetail) {
                that.mangel.descriptionDetail = response.data.mangel.descriptionDetail
              } else {
                console.error('Keine Mangel DescriptionDetail vorhanden')
              }

              that.hideSpinner()

            } catch (error) {

              console.error('Fehler beim Hochladen:', error)
              const text = that.$t('message.maengelTable_upload_error').replace('{0}', fileName)
              that.$bvToast.toast(text, {
                title: that.$t('message.printMaengel_kein_bild_title'),
                variant: 'danger',
                autoHideDelay: 5000
              })
              that.hideSpinner()

            }

          },
          /**
           * einen Mangel im Datenbroker löschen
           * @param mangel
           */
          deleteMangel: function (mangel) {

            const that = this
            this.showSpinner()
            const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/deleteMangel';
            var params   = {
              mangel_id: mangel.id
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(response => {

              that.hideSpinner()
              if (response.data && response.data.id) {

                let newlist = []
                for (let i = 0; i < that.maengel.length; i++) {
                  if (that.maengel[i].id != mangel.id) {
                    newlist.push(that.maengel[i])
                  }
                }
                that.maengel = newlist
                that.$bvModal.hide('bv-modal-details')

              } else {

                alert('Es gab einen Fehler beim Löschen des Mangels. Bitte versuchen Sie es erneut oder wenden Sie sich an einen Biotech Mitarbeiter.')

              }

            }).catch( () => {
              alert('Es gab einen Fehler beim Löschen des Mangels. Bitte versuchen Sie es erneut oder wenden Sie sich an einen Biotech Mitarbeiter.')
            })

          },
          /**
           * Rückfrage ob der Mangel gelöscht werden soll
           * @param mangel
           */
          confirmDeleteMangel: function (mangel) {

            const that = this
            const bezeichnung = '"' + mangel.descriptionShort + '"'
            const text = this.$t('message.maengelTable_confirm_delete').replace('{0}', bezeichnung);
            bootbox.confirm(text, result => {

              if (result) {
                that.deleteMangel(mangel)
              }

            })

          },
          /** Priorität des Mangels wurde in Detailansicht geändert, an Parent weitergeben */
          mangelPrioritaetChanged: function (mangel) {

            this.$emit('mangel-prioritaet-changed', mangel);

          },
          showAbgeschlossenButton: function (mangel) {
            console.log('eingeloggt ist ', this.$store.getters.user_role.authority)
            return (mangel.status.name != 'ABGESCHLOSSEN' && this.$store.getters.user_role && (this.$store.getters.user_role.authority == this.ROLE_ADMIN))
          },
          showErledigtButton: function (mangel) {
            return (mangel.status.name === 'OFFEN' && this.$store.getters.user_role && (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER || this.$store.getters.user_role.authority == this.ROLE_KEY_USER));
          },
          /** einen Mangel anzeigen */
          selected: function (obj) {

            this.mangel = obj;
            this.$bvModal.show('bv-modal-details');

          },
          /**
           * zeigt die Alert Farbe für den Status des Mangels
           * @param mangel
           */
          getAlertVariant: function (mangel) {

            if (mangel.status.name === 'OFFEN') {
              return 'danger';
            }
            if (mangel.status.name === 'ERLEDIGT') {
              return 'success';
            }
            if (mangel.status.name === 'ABGESCHLOSSEN') {
              return 'light';
            }

          },
          /** Modal öffnen um Mangel einzugeben */
          showNoteModal: function (mangel) {

            this.notiz  = null;
            this.mangel = mangel;
            this.$bvModal.show('note-modal');

          },
          /** Notiz zum Mangel ins Beschreibungs Feld hinzufügen - Modal öffnen */
          addNote: function () {

            var that = this;
            this.showSpinner();

            var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/saveMangelNotiz';
            var params   = {
              id:       this.mangel.id,
              notiz:    this.notiz,
              userjson: JSON.stringify(this.$store.getters.user)
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              that.hideSpinner();
              that.mangel.descriptionDetail = response.data.descriptionDetail;
              that.$bvModal.hide('note-modal');

            })

          },
          /**
           * zeigt einen Mangel, falls noch kein Plan vorhanden ist
           */
          showPlanMangel: function () {

            console.log('/plan/getPlanMangel ...');
            var url      = process.env.VUE_APP_SERVER_URL + '/plan/getPlanMangel';
            var that     = this;
            var customer = this.$store.getters.customer;
            var params   = {
              customer: JSON.stringify(customer)
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              that.hideSpinner();
              if (!response.data.error && response.data.id == -1) {

                that.maengel.unshift(response.data);
                that.empty = false;

              } else if (response.data.error) {
                console.log('error' + response.data.error);
              }

            }).catch(error => {
              console.log(error);
              that.hideSpinner();
            });

          },
          init: function () {

            if (!this.maengellist) {

              this.showSpinner();

              // hole alle Mängel des Kunden:
              //////////////////////////////////////////////////////////
              console.log('/datenbroker/getMaengel ...');
              var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getMaengel';
              var that     = this;
              var customer = this.$store.getters.customer;
              var params   = {
                customer: JSON.stringify(customer),
                status:   this.showAbgeschlossen
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(function (response) {

                that.hideSpinner();
                if (!response.data.error) {
                    that.maengel = response.data;
                    if (that.maengel.length < 1) {
                      that.empty = true;
                    } else {
                      that.empty = false;
                    }
                } else {
                  console.log('error' + response.data.error);
                }
                // bei fehlendem Plan, Mangel anzeigen:
                that.showPlanMangel();

              }).catch(error => {
                console.log(error);
                that.hideSpinner();
              });

              // prüfe ob es abgeschlossene Mängel gibt
              var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/countMaengel';
              var customer = this.$store.getters.customer;
              var params   = {
                customer:    JSON.stringify(customer),
                showDetails: true
              };
              axios ({
                method: 'GET',
                params: params,
                url:    url
              }).then(function (response) {

                if (response.data && response.data.abgeschlossen > 0) {
                  that.hasAbgeschlosseneMaengel = true;
                } else {
                  that.hasAbgeschlosseneMaengel = false;
                }

              }).catch(error => {
                console.log(error);
              });

              // wenn der User die Bilder nicht sehen darf, dann blende die Tabellenspalte für die Bilder aus
              if (!this.showMaengelFotos()) {

                var newfields = [];
                for (var i = 0; i < this.fields.length; i++) {
                  if (this.fields[i].key != 'thumb') {
                    newfields.push(this.fields[i]);
                  }
                }
                this.fields = newfields;

              }

            } else if (this.maengellist && this.maengellist.length > 0) {
              // zeige übergebene Mängel
              //////////////////////////////////////////////////////////
              this.maengel = this.maengellist;
            }

          },
          /** Mangel als erledigt markieren */
          mangelErledigt: function(mangel, status) {

            let text = this.$t('message.maengelTable_text1')
            if (status == 'ABGESCHLOSSEN') {
              text = this.$t('message.maengelTable_text2')
            }

            const that = this
            bootbox.confirm(text, function(result) {

              if (result) {

                that.mangel = mangel
                that.showSpinner()
                // speichere Status in MySQL und Firestore
                let url = process.env.VUE_APP_SERVER_URL + '/datenbroker/saveMangelStatusErledigt'
                if (status == 'ABGESCHLOSSEN') {
                  url = process.env.VUE_APP_SERVER_URL + '/datenbroker/saveMangelStatusAbgeschlossen'
                }
                const params = {
                  id:               mangel.id,
                  maengelStatus:    status,
                  userjson:         JSON.stringify(that.$store.getters.user)
                }
                axios ({
                  method:   'GET',
                  params:   params,
                  url:      url
                }).then(function (response) {

                  that.hideSpinner()
                  that.mangel.status            = response.data.status
                  that.mangel.descriptionDetail = response.data.descriptionDetail
                  // an Parent zurückgeben, dass sich Mangel geändert hat:
                  that.$emit('maengel-changed', true)

                })

              }

            })

          },
          /**
           * PDF oder Excel der Ergebnisliste anzeigen
           * @param type excel oder pdf
           * @param mangel wenn ein PDF eines einzelnen Mangels erstellt werden soll
           */
          generateReport: async function (type, mangel) {

            console.log('generateReport: ' + type)

            this.showSpinner()

            const serverurl       = process.env.VUE_APP_SERVER_URL
            let liste
            if (mangel) {
              // einzelnen Mangel:
              liste               = JSON.stringify([this.mangel])
            } else {
              // alle angezeigten Mängel:
              liste               = JSON.stringify(this.items)
            }
            liste                 = encodeURI(liste)
            let kunde             = JSON.stringify(this.$store.getters.customer)
            kunde                 = encodeURI(kunde)
            const url             = serverurl + '/report/generateMaengelReport'

            this.form_action = url
            document.getElementById('form_id').action       = url
            document.getElementById('form_type').value      = type
            document.getElementById('form_liste').value     = liste
            document.getElementById('form_customer').value  = kunde

            this.hideSpinner()

            this.$nextTick(function () {
              this.$refs.form.submit()
            })

          },
          /**
           * zeige Druck Page
           */
          print: function () {

            var items       = JSON.stringify(this.items);

            let routeData = this.$router.resolve({path: '/printMaengel/:name', query: {
                items:      items,
                kundenname: this.$store.getters.customer.kunde,
                adresse:    this.$store.getters.customer.zipcode + ' ' + this.$store.getters.customer.ort + ', ' + this.$store.getters.customer.adresse,
                cardcode:   this.$store.getters.customer.cardcode,
                showMaengelFotosRight: this.showMaengelFotosRight
              }});
            console.log('open ' + routeData.href);
            window.open(routeData.href, '_blank');

          }

        }

    }

</script>

<style scoped>

    .rowClass {
        max-height: 200px;
    }
    .alert-danger {
         background-color: #e3150f;
         border-color: #e3150f;
         color: white;
    }
    .alert-warning {
        background-color: #eda512;
        border-color: #eda512;
        color: black;
    }
    .alert-success {
        background-color: #73b025;
        border-color: #73b025;
        color: white;
    }

</style>
