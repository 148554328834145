<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

  <span class="eigenedokumentewrapper">

    <b-overlay :show="showOverlay" rounded="sm" style="min-height: 300px">

      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
          <p id="cancel-label">{{ $t('message.eigeneDokumente_wird_geladen') }}...</p>
        </div>
      </template>

        <div id="filter" class="row pb-2">

          <div class="col-md-3">
            <file-upload-button v-on:set-file="setFile" filetype="EIGENE_DATEI" />
          </div>
          <div class="col-md-8">

            <b-input-group size="sm">
              <b-form-input
                  v-model="keyword"
                  type="search"
                  id="filterInput"
                  :placeholder="$t('message.positionen_filter')" />
              <b-input-group-append>
                <b-button :disabled="!keyword" @click="keyword = ''"><b-icon-x></b-icon-x></b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col-md-1 text-right">

            <b-form-group horizontal label="" class="">
              <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
            </b-form-group>

          </div>

        </div>
        <div id="eigeneDokumenteTable">

          <div class="card-box">
            <b-table small
                     hover
                     :items="items"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :keyword="keyword">

              <template v-slot:cell(dateCreated)="row">
                <java-date-to-default-date-format v-model="row.item.dateCreated" />
              </template>
              <template v-slot:cell(actions)="row">

                 <b-button variant="outline-danger"
                           size="sm"
                           @click="deleteFile(row.item)"
                           class="mr-2"
                           v-b-tooltip.hover title="Dokument löschen">
                   <b-icon icon="trash" />
                 </b-button>
                 <b-button variant="outline-primary" size="sm" @click="open(row.item)">
                   {{ $t('message.allgemeineDokumente_oeffnen') }}&nbsp;&nbsp;<b-icon icon="box-arrow-up-right" />
                 </b-button>

              </template>

            </b-table>
          </div>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0">
              </b-pagination>
            </b-col>
          </b-row>

        </div>

      </b-overlay>

    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import bootbox from 'bootbox'
    import JavaDateToDefaultDateFormat from '@/components/layout/JavaDateToDefaultDateFormat.vue'
    import FileUploadButton from '@/components/dokumente/FileUploadButton.vue'

    export default {
      name: 'EigeneDokumente',
      components: {
        JavaDateToDefaultDateFormat,
        FileUploadButton,
      },
      props: [],
      data () {
          return {
            sortBy: 'dateCreated',
            sortDesc: true,
            /** Filter Query String */
            keyword: '',
            fields: [
              {
                label: this.$t('message.printMaengel_datum'),
                key: 'dateCreated',
                sortable: true
              },
              {
                label: this.$t('message.default_bezeichnung'),
                key: 'bezeichnung',
                sortable: true
              },
              {
                label: this.$t('message.eigeneDokumente_dateiname'),
                key: 'originalFilename',
                sortable: true
              },
              {
                label: '',
                key: 'actions',
                sortable: false,
                class: 'text-right'
              }
            ],
            files: null,
            currentPage: null,
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            showOverlay: false,
            url: null,
          }
      },
      computed: {

        // a computed getter
        items: function () {

          if (this.files) {

            var lowercase = this.keyword ? this.keyword.toLowerCase() : null

            var selectedRows = lowercase
                ? this.files.filter(item =>

                    (item.bezeichnung && item.bezeichnung.toLowerCase().includes(lowercase)) ||
                    (item.dateCreated && item.dateCreated.toLowerCase().includes(lowercase))

                ) : this.files

            return selectedRows

          }

          return null

        },
        totalRows: function () {
          return this.items ? this.items.length : 0
        }

      },
      created () {

        this.init()

      },
      methods: {

      /** neuer File wurde hochgeladen */
      setFile: function (obj) {

        this.showSpinner()
        // File wurde hochgeladen, speichere in Datenbank ...
        const that       = this
        const url        = process.env.VUE_APP_SERVER_URL + '/file/save'
        const mandant    = this.$store.getters.customer.biotechCountry.name
        const cardcode   = this.$store.getters.customer.cardcode
        const params     = {
          mandant: mandant,
          cardcode: cardcode,
          filetype: 'EIGENE_DATEI',
          originalFilename: obj.originalFilename,
          filename: obj.filename,
          bezeichnung: obj.bezeichnung,
        }
        axios({
          method: 'POST', // muss POST sein!
          params: params,
          url:    url,
        }).then( response => {

          if (!that.files) that.files = []
          that.files.unshift(response.data)
          that.hideSpinner()

        }).catch(error => {
          console.log(error)
          that.hideSpinner()
        })

      },
      /** Dokument löschen */
      deleteFile: function (item) {

        const that = this
        const text = this.$t('message.eigeneDokumente_loeschen_confirm').replace('{0}', (item.bezeichnung ? item.bezeichnung : item.originalFilename))
        bootbox.confirm(text, result => {

          if (result) {

            that.showSpinner()
            const url        = process.env.VUE_APP_SERVER_URL + '/file/delete'
            const params     = {
              file_id: item.id,
            }
            axios({
              method: 'POST', // muss POST sein!
              params: params,
              url:    url
            }).then( () => {

              for (let i = 0; i < that.files.length; i++) {
                if (that.files[i].id == item.id) {
                  that.files.splice(i, 1)
                  break
                }
              }
              that.hideSpinner()

            }).catch(error => {
              console.log(error)
              that.hideSpinner()
            })

          }

        })

      },
      /**
       * File anzeigen
       * @param file
       */
      open: function (file) {

          this.showSpinner()
          const that       = this
          const url        = process.env.VUE_APP_SERVER_URL + '/file/show'
          const params     = {
            id: file.id,
          }
          axios({
            method: 'GET', // muss GET sein!
            params: params,
            url:    url,
          }).then( response => {

            var a = document.createElement('a')
            a.href= 'data:application/octet-stream;base64,' + response.data.base64
            console.log('Zeige file base64 ', a.href)
            a.target = '_blank'
            a.rel = 'noopener noreferrer'
            a.download = response.data.originalFilename
            a.click()
            that.hideSpinner()

          }).catch(error => {
            console.log(error)
            that.hideSpinner()
          })

      },
      init: function () {

          this.showOverlay = true
          const that       = this
          const url        = process.env.VUE_APP_SERVER_URL + '/file/index'
          const mandant    = this.$store.getters.customer.biotechCountry.name
          const cardcode   = this.$store.getters.customer.cardcode
          const params     = {
            mandant: mandant,
            cardcode: cardcode,
            filetype: 'EIGENE_DATEI',
          };
          axios({
            method: 'GET', // muss GET sein!
            params: params,
            url:    url,
          }).then( response => {

            that.files = response.data
            that.currentPage = 1 // Pagination auf 1. Seite setzen
            that.showOverlay = false

          }).catch(error => {
            console.log(error)
            that.showOverlay = false
          })

        }

      }

    }

</script>