<template>

    <span class="printUserWrapper" v-if="user">
        <span v-if="user.vorname && user.nachname">
          {{ user.vorname }} {{ user.nachname }}
        </span>
        <span v-else-if="user.nachname">
          {{ user.nachname }}
        </span>
        <span v-else>
          {{ user.username }}
        </span>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'PrintUser',
      props: [
          /** ID des anzuzeigenden Benutzers */
          'value'
      ],
      data () {
        return {
          user: null
        }
      },
      watch: {
        value: function () {
          this.init();
        }
      },
      created () {
        this.init();
      },
      methods: {

        init: function () {

          if (this.value) {

            var url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/getUser';
            var params  = {
              user_id:  this.value
            };

            var that    = this;
            axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              if (response.data.id) {

                that.user = response.data;

              } else {
                console.log(response.data.error);
                that.user = null;
              }

            }).catch(error => {
              console.log(error);
            });
          }

        }

      }

    }

</script>
