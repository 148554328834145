<template>

    <div id="content" role="main">

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.customerNotifications_h1') }}</h1>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">

        <span v-if="total && total > 0">

          <!-- alle Zeilen markieren -->
          <div class="row">
            <div class="col-md-1">

              <b-form-checkbox
                  id="checkAll"
                  v-model="checkAll"
                  name="checkAll"
                  value="true"
                  unchecked-value="false">
                {{ $t('message.customerNotifications_alle') }}
              </b-form-checkbox>

            </div>
            <!-- wenn alle Zeilen markiert, kann auf alle gelesen geklickt werden -->
            <div class="col-md-11" v-if="checkAll == 'true'">
              <b-button variant="outline-primary" size="sm" @click="alleGelesen">
                <b-icon icon="check-all" /> {{ $t('message.customerNotifications_alle_gelesen') }}
              </b-button>
            </div>
          </div>

          <b-table striped
                   class="mt-2"
                   id="notifications-table"
                   small
                   :fields="fields"
                   :items="items"
                   :current-page="currentPage"
                   :per-page="perPage"
                   @sort-changed="sortingChanged"
                   :no-local-sorting="true"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :responsive="true">

            <template #cell(radio)="row">
              <b-form-group v-if="row.item.customerNotificationStatus && row.item.customerNotificationStatus.name != 'UNGEPRUEFTE_247_BILDER'">
                <b-form-checkbox
                    :id="'checked_' + row.item.id"
                    v-model="row.item.checked"
                    name="checked"
                    value="true"
                    unchecked-value="false"
                >&nbsp;</b-form-checkbox>
              </b-form-group>
            </template>
            <template #cell(dateCreated)="row">
              <span @click="show(row.item)">
                <java-date-to-default-date-format
                    style="cursor: pointer"
                    :class="(!row.item.lastCustomerNotificationHistory || !row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus || row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus.name == 'UNGELESEN') ? 'unread' : ''"
                    v-model="row.item.dateCreated"
                />
              </span>
            </template>
            <template #cell(alarmLevel)="row">
              <customer-notification-prioritaet v-model="row.item.alarmLevel.name" />
            </template>
            <template #cell(customerNotificationStatus)="row">

              <customer-notification-status v-model="row.item.customerNotificationStatus" />

            </template>
            <template #cell(customer)="row">

              <span v-if="row.item.customerNotificationStatus && row.item.customerNotificationStatus.name == 'UNGEPRUEFTE_247_BILDER'">
                ---
              </span>
              <span v-else-if="row.item.customer"
                    @click="show(row.item)"
                    :class="(!row.item.lastCustomerNotificationHistory || !row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus || row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus.name == 'UNGELESEN') ? 'unread' : ''"
                    style="cursor: pointer">
                {{ row.item.customer.kunde }} <small>({{ row.item.customer.cardcode }})</small>
              </span>
              <span v-else
                    @click="show(row.item)"
                    :class="(!row.item.lastCustomerNotificationHistory || !row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus || row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus.name == 'UNGELESEN') ? 'unread' : ''"
                    style="cursor: pointer">
                -- alle Standorte --
              </span>

            </template>
            <template #cell(subject)="row">

                <span :class="(!row.item.lastCustomerNotificationHistory || !row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus || row.item.lastCustomerNotificationHistory.customerNotificationMessageStatus.name == 'UNGELESEN') ? 'unread' : ''"
                      @click="show(row.item)"
                      style="cursor: pointer">
                  {{ row.item.subject }}
                </span>

            </template>
            <template #cell(actions)="row">

              <b-button variant="outline-danger"
                        v-if="row.item.customerNotificationStatus && row.item.customerNotificationStatus.name != 'UNGEPRUEFTE_247_BILDER'"
                        size="sm"
                        class="mr-1 mb-1"
                        @click="deleteRow(row.item)">
                <b-icon icon="trash"/>
              </b-button>
              <b-button variant="outline-primary"
                        v-if="row.item.customerNotificationStatus && row.item.customerNotificationStatus.name != 'UNGEPRUEFTE_247_BILDER'"
                        size="sm"
                        class="mr-1 mb-1"
                        @click="show(row.item)">
                  <span v-if="row.item.customerNotificationStatus">
                    <b-icon icon="pencil"/> {{ $t('message.default_bearbeiten') }}
                  </span>
                  <span v-else>
                    <b-icon icon="eye"/> {{ $t('message.default_anzeigen') }}
                  </span>
              </b-button>
              <router-link to="/traplinkedImages"
                           v-else-if="row.item.customerNotificationStatus && row.item.customerNotificationStatus.name == 'UNGEPRUEFTE_247_BILDER'"
                           :class="'btn btn-outline-warning'"
                           href="#">
                      <b-icon icon="eye"></b-icon> {{ $t('message.customerNotifications_247_bilder_pruefen') }}
              </router-link>

            </template>

          </b-table>

          <b-row v-if="total">
            <b-col md="12" class="my-1">
              <b-pagination :total-rows="total"
                            :per-page="perPage"
                            v-model="currentPage"
                            class="my-0" />
            </b-col>
          </b-row>

        </span><!-- end if total > 0 -->
        <b-alert variant="success" show v-else-if="total != null && total == 0">
            {{ $t('message.customerNotifications_alle_gelesen_erfolg') }}
        </b-alert>

      </div>

      <!-- Model zur Anzeige der Verständigung -->
      <b-modal v-if="notification" id="show-modal" :title="notification.subject" size="xl">

        <form>

          <!-- Auflistung der Antworten/Notizen des Kunden -->
          <b-row v-if="antworten && antworten.length > 0" class="mb-3">
            <b-col sm="2">
              <label for="antwort">{{ $t('message.customerNotifications_notizen') }}</label>
            </b-col>
            <b-col sm="10">

              <b-list-group>
                <b-list-group-item v-for="protokoll in antworten" :key="protokoll.id" style="padding:0;border:0">
                  <div class="card mb-2">
                    <div class="card-header">
                      <b-img v-if="protokoll.bioTechUser"
                             style="width: 30px; height: 30px"
                             class="mr-1"
                             v-b-tooltip.hover
                             :title="$t('message.customerNotifications_nachricht_von_biotech')"
                             alt="Biotech Logo"
                             :src="logo" />
                      <b-icon v-else icon="person-circle" class="mr-1"></b-icon>
                      <strong><java-date-to-default-time-format v-model="protokoll.lastUpdated" /></strong>
                    </div>
                    <div class="card-body">
                      {{ protokoll.antwort }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>

            </b-col>
          </b-row>

          <b-row>
            <b-col sm="2">
              <label for="message">{{ $t('message.customerNotifications_nachricht') }}</label>
            </b-col>
            <b-col sm="10">
              <!-- Nachricht im HTML format -->
              <div class="card">
                <div class="card-header">
                  <b-row>
                    <b-col sm="1">
                      <label for="datum">{{ $t('message.printMaengel_datum') }}</label>
                    </b-col>
                    <b-col sm="5">
                      <java-date-to-default-time-format
                          id="datum"
                          v-model="notification.dateCreated"
                      />
                    </b-col>
                    <b-col sm="1">
                      <label for="prioritaet">{{ $t('message.customerNotifications_prioritaet') }}</label>
                    </b-col>
                    <b-col sm="5">
                      <customer-notification-prioritaet
                          id="prioritaet"
                          v-model="notification.alarmLevel.name"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="card-body">
                  <span id="message" v-html="notification.message">
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- wenn es einen Status gibt, kann der Benutzer diesen ändern -->
          <b-row v-if="notification.customerNotificationStatus">
            <b-col sm="2">
              <label for="customerNotificationStatus">{{ $t('message.customerNotifications_status') }}</label>
            </b-col>
            <b-col sm="10">

                <b-form-radio-group
                    id="customerNotificationStatus"
                    v-model="notification.customerNotificationStatus.name"
                    @change="setCustomerNotificationStatus"
                    name="customerNotificationStatus">
                  <b-form-radio value="OFFEN">
                    <b-badge style="background-color: #e3150f">
                        {{ $t('message.customerNotifications_status_offen') }}
                    </b-badge>
                  </b-form-radio>
                  <b-form-radio value="BEARBEITET">
                    <b-badge style="background-color: #eda512">
                        {{ $t('message.customerNotifications_status_bearbeitet') }}
                    </b-badge>
                  </b-form-radio>
                  <b-form-radio value="ABGESCHLOSSEN"
                                disabled
                                v-b-tooltip.hover
                                :title="$t('message.customerNotifications_status_not_editable')">
                    <b-badge style="background-color: #73b025">
                        {{ $t('message.customerNotifications_status_abgeschlossen') }}
                    </b-badge>
                  </b-form-radio>
                </b-form-radio-group>

            </b-col>
          </b-row>

          <!-- neue Antwort/Notiz des Kunden auf die Nachricht -->
          <b-row class="mt-2">
              <b-col sm="2">
                  <label for="antwort">{{ $t('message.customerNotifications_notiz_add') }}</label>
              </b-col>
              <b-col sm="10">
                  <b-form-textarea
                          id="textarea"
                          v-model="antwort"
                          :placeholder="$t('message.customerNotifications_notiz_add') + '...'"
                          rows="3"
                          max-rows="6"
                  />
              </b-col>
          </b-row>

        </form>

        <template #modal-footer>
          <div class="w-100">
            <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="closeModal()">
                <b-icon icon="check"></b-icon>&nbsp;{{ $t('message.default_ok') }}
            </b-button>
          </div>
        </template>

      </b-modal>

    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import bootbox from 'bootbox';
  import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat.vue";
  import CustomerNotificationStatus from "@/components/customerNotifications/CustomerNotificationStatus.vue";
  import CustomerNotificationPrioritaet from "@/components/customerNotifications/CustomerNotificationPrioritaet.vue";
  import JavaDateToDefaultTimeFormat from "@/components/layout/JavaDateToDefaultTimeFormat.vue";

  export default {
    name: 'CustomerNotifications',
    components: {
      JavaDateToDefaultTimeFormat,
      JavaDateToDefaultDateFormat,
      CustomerNotificationStatus,
      CustomerNotificationPrioritaet
    },
    data: function () {
      return {
        logo: require('../assets/images/biotech-logo.svg'),
        /** Anzahl aller Nachrichten */
        total: null,
        perPage: 50,
        currentPage: 1,
        fields: [
          {
            key: 'radio',
            label: '',
            sortable: false
          },
          {
            key: 'dateCreated',
            label: this.$t('message.printMaengel_datum'),
            sortable: true
          },
          {
            key: 'customer',
            label: this.$t('message.customerNotifications_standort'),
            sortable: true
          },
          {
            key: 'subject',
            label: this.$t('message.customerNotifications_betreff'),
            sortable: true
          },
          {
            key: 'alarmLevel',
            label: this.$t('message.customerNotifications_prioritaet'),
            sortable: true
          },
          {
            key: 'customerNotificationStatus',
            label: this.$t('message.customerNotifications_status'),
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: 'text-right'
          }
        ],
        sortBy: 'dateCreated',
        sortDesc: true,
        /** eine Nachricht die über die Suche gefunden wurde */
        notification: null,
        /** alle Zeilen auswählen */
        checkAll: 'false',
        /** Liste mit Nachrichten */
        items: null,
        /** Antwort/Notiz auf die Nachricht */
        antwort: null,
        /** Liste von bestehenden Antworten */
        antworten: null
      }
    },
    mounted: function () {
      this.init();
    },
    watch: {

      /** alle Zeilen markieren */
      checkAll: function (newVal) {

        for (let i = 0; i < this.items.length; i++) {
          this.items[i].checked = newVal;
        }

      },
      /** Pagination */
      currentPage: function () {
        this.getItems();
      }

    },
    methods: {

      /**
       * Statusänderung an Datenbroker senden und Ergebnis anzeigen
       * @param params
       */
      sendCustomerNotificationHistory: function (params, id, aktion) {

        if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_CUSTOMER && this.$store.getters.customer) {
          params.customer_id = this.$store.getters.customer.id;
        } else if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.user) {
          params.key_user_id = this.$store.getters.user.keyUserId;
          params.mandant = this.$store.getters.user.biotechCountry.name;
        } else {
          console.error('Rolle für User fehlt?', this.$store.getters.user_role)
          return
        }

        const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/setCustomerNotificationHistory';

        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then( response => {

          const item = (response.data && response.data.customerNotifications) ? response.data.customerNotifications[0] : null;
          let newlist = [];
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id == id && item) {
              if (aktion != 'GELOESCHT') newlist.push(item);
            } else {
              newlist.push(this.items[i]);
            }
          }
          this.items = newlist;

        });

      },
      /** eine Nachricht als gelöscht markieren */
      deleteRow: function (item) {

        let that = this;
        const text = this.$t('message.customerNotifications_delete_sure').replace('{0}', item.subject);
        bootbox.confirm(text, result => {
          if (result) {

            let params   = {
              aktion: 'GELOESCHT',
              notification_ids: item.id
            }
            that.sendCustomerNotificationHistory(params, item.id, 'GELOESCHT');

          }
        });

      },
      /** Status der Nachricht ändern */
      setCustomerNotificationStatus: function () {

        console.info("setCustomerNotificationStatus", "status neu = " + this.notification.customerNotificationStatus.name);
        let params   = {
          aktion: 'STATUS',
          notification_ids: this.notification.id,
          status: this.notification.customerNotificationStatus.name
        }
        this.sendCustomerNotificationHistory(params, this.notification.id);

      },
      /** eine Nachricht als gelesen markieren */
      setGelesen: function (id) {

        let params   = {
          aktion: 'GELESEN',
          notification_ids: id
        }
        this.sendCustomerNotificationHistory(params, id);

      },
      /** alle Nachrichten als gelesen markieren */
      alleGelesen: function () {

        let ids = [];
        for (let i = 0; i < this.items.length; i++) {
          ids.push(this.items[i].id);
        }
        const url    = process.env.VUE_APP_SERVER_URL + '/datenbroker/setCustomerNotificationHistory';
        let params   = {
          aktion: 'GELESEN',
          notification_ids: ids.join(',')
        }
        if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_CUSTOMER && this.$store.getters.customer) {
          params.customer_id = this.$store.getters.customer.id;
        } else if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.user) {
          params.key_user_id = this.$store.getters.user.keyUserId;
          params.mandant = this.$store.getters.user.biotechCountry.name;
        }
        axios ({
          method: 'GET',
          params: params,
          url: url
        }).then( response => {

          this.items = response.data ? response.data.customerNotifications : null;

        });

      },
      init: function () {

        // zähle alle:
        const url    = process.env.VUE_APP_SERVER_URL + '/datenbroker/countCustomerNotifications';
        let params   = {}
        if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_CUSTOMER && this.$store.getters.customer) {
          params.customer_id = this.$store.getters.customer.id;
        } else if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.user) {
          params.key_user_id = this.$store.getters.user.keyUserId;
          params.mandant = this.$store.getters.user.biotechCountry.name;
          // gebe die Standorte mit falls schon vorhanden, dann geht das schneller
          if (this.$store.getters.standorte) {
            let custList = [];
            for (let i = 0; i < this.$store.getters.standorte.length; i++) {
              custList.push(this.$store.getters.standorte[i].id);
            }
            params.customers = custList.join(',');
          }
        }
        axios ({
          method: 'get',
          params: params,
          url: url
        }).then( response => {

          this.total = response.data.anzahl;

        });
        this.getItems();

      },
      sortingChanged(ctx) {

        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        this.sortBy = ctx.sortBy;
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.sortDesc = ctx.sortDesc;
        console.info("sorting", "sortby = " + this.sortBy + ' sortDesc = ' + this.sortDesc);
        this.getItems();

      },
      /** Nachricht anzeigen */
      show: function (item) {

        this.antworten = null;
        this.antwort = null;
        this.notification = item;
        this.$nextTick(() => {

          // Modal anzeigen:
          this.$bvModal.show('show-modal');

        });
        // als gelesen markieren
        this.setGelesen(item.id);
        // hole bestehende Antworten
        this.getAntworten(item.id);

      },
      /**
       * bestehende Antworten holen und anzeigen
       */
      getAntworten: function (notification_id) {

        let params = {
          notification_id: notification_id
        }
        const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getCustomerNotificationAntworten';

        axios ({
          method: 'get',
          params: params,
          url: url
        }).then( response => {

          this.antworten = response.data;

        });

      },
      closeModal: function () {

        // speichere die Notiz falls eine erfasst wurde:
        if (this.antwort) {

          let params = {
            aktion: 'SAVE_ANTWORT',
            notification_ids: this.notification.id,
            antwort: this.antwort
          }
          this.sendCustomerNotificationHistory(params, this.notification.id);

          // Rückmeldung an User, dass erfolgreich gespeichert wurde
          bootbox.alert(this.$t('message.customerNotifications_erfolgreich'));

        }

        this.$bvModal.hide('show-modal');

      },
      /** hole Nachrichten */
      getItems: function () {

        this.showSpinner();

        const offset = (this.currentPage - 1) * this.perPage;
        const sort   = this.sortDesc ? 'DESC' : 'ASC';
        const url    = process.env.VUE_APP_SERVER_URL + '/datenbroker/getCustomerNotifications';
        let params   = {
          offset: offset,
          sorter: this.sortBy,
          order:  sort,
        }
        if (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER && this.$store.getters.customer) {
          params.customer_id = this.$store.getters.customer.id;
        } else if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.user) {

          params.key_user_id = this.$store.getters.user.keyUserId;
          params.mandant = this.$store.getters.user.biotechCountry.name;
          // gebe die Standorte mit falls schon vorhanden, dann geht das schneller
          if (this.$store.getters.standorte) {
            let custList = [];
            for (let i = 0; i < this.$store.getters.standorte.length; i++) {
              custList.push(this.$store.getters.standorte[i].id);
            }
            params.customers = custList.join(',');
          }

        } else {
          console.error('Keine Rolle für User vorhanden?', this.$store.getters.user_role)
          return
        }

        axios ({
          method: 'get',
          params: params,
          url: url
        }).then( response => {

          this.hideSpinner();
          console.info('myProvider', response);
          // Pluck the array of items off our axios response
          this.items = response.data ? response.data.customerNotifications : null;

        });

      }

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .unread {
    font-weight: bold;
  }

</style>
