<!-- zeigt die TraplinkedId/Seriennummer falls es ein Traplinked Objekt ist -->
<template>

  <span v-if="objekt && position">
     {{ seriennr }}
  </span>

</template>
<script>

  // Define a new component
  import axios from "axios";

  export default {

    name: 'TraplinkedIdOfObjekt',
    props: ['objekt', 'position'],
    /*
      Defines the data used by the component
    */
    data: function(){
      return {
        seriennr: ''
      }
    },
    watch: {
      value: function () {
        this.init();
      }
    },
    mounted: function () {
      this.init();
    },
    methods: {

      /** hole Seriennummer wenn das Objekt ein Traplinked Device ist */
      init: function () {

        this.seriennr = '';
        if (this.objekt && this.objekt.objectId && this.objekt.objectId.startsWith('PM') && this.position) {

          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedDevice';
          var params   = {
            objekt_id:     this.objekt.id,
            position_id:   this.position.id,
            noDeinstalled: true // keine deinstallierten Fallen anzeigen
          };
          var that    = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && response.data.traplinkedId) {

              that.seriennr = response.data.traplinkedId;

            }

          }).catch(error => {
            console.log(error);
          });

        }
      }

    }

  }

</script>
