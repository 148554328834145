<template>

    <span class="besuchsberichtErgebnisse">

        <transition name="fade" mode="out-in">

            <div v-if="ergebnisse">

                <div class="row mb-3" v-if="totalRows >= anzahl && !hideHeaders">
                    <div class="col text-right">
                        <b-button variant="outline-secondary"
                                  size="sm"
                                  class="mr-2"
                                  @click="generateReport('excel')">
                            <font-awesome-icon icon="file-excel" />&nbsp;{{ $t('message.positionen_excel') }}
                        </b-button>
                        <b-button variant="outline-secondary"
                                  size="sm"
                                  class="mr-2"
                                  @click="generateReport('pdf')">
                            <font-awesome-icon icon="file-pdf" />&nbsp;{{ $t('message.positionen_pdf') }}
                        </b-button>
                        <b-button variant="outline-secondary"
                                  size="sm"
                                  class="mr-2"
                                  @click="print()">
                            <font-awesome-icon icon="print" />&nbsp;{{ $t('message.positionen_drucken') }}
                        </b-button>
                    </div>
                </div>

                <!-- Ergebnis Tabelle Filtern -->
                <div id="filter" class="row border-top mb-3 pt-3" v-if="!hideHeaders">

                    <div class="col-md-11">

                        <b-input-group size="sm">
                            <b-form-input v-model="keyword"
                                          type="search"
                                          id="filterInput"
                                          :placeholder="$t('message.positionen_filter')">
                            </b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                            </b-input-group-append>
                        </b-input-group>

                    </div>
                    <div class="col-md-1 text-right">

                        <b-form-group horizontal label="" class="">
                            <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                        </b-form-group>

                    </div>

                </div>

                <b-table striped
                         id="ergebnisse-table"
                         small
                         :fields="fields"
                         :items="items"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :keyword="keyword"
                         :current-page="currentPage"
                         :per-page="perPage"
                         :sort-compare="sortPositionsergebnis"
                         hover
                         :responsive="true">

                    <template #cell(datum_end)="row">
                      <span v-if="row.item.traplinked_report_timestamp">
                         <java-date-to-default-time-format v-model="row.item.traplinked_report_timestamp" />
                      </span>
                      <span v-else>
                        <span v-if="row.item.datum_start && row.item.datum_end && row.item.datum_start < row.item.datum_end">
                          <java-date-to-default-date-format v-model="row.item.datum_start" /> - <java-date-to-default-date-format v-model="row.item.datum_end" />
                        </span>
                        <span v-else-if="row.item.datum_start">
                          <java-date-to-default-date-format v-model="row.item.datum_start" />
                        </span>
                        <span v-if="!row.item.datum_start && row.item.datum_end">
                          <java-date-to-default-date-format v-model="row.item.datum_end" />
                        </span>
                      </span>
                    </template>

                    <template #cell(alarm_level)="row">

                        <span v-if="!row.item.alarm_level && row.item.count_traplinked_report_image">
                          <!-- unbewertetes Traplinked Image "Bild aufgenommen als grün" -->
                          <traplinked-ergebnis v-model="row.item.traplinked_report_device_status"
                                               :report-type="row.item.traplinked_report_event_type"
                                               :alarm-level="row.item.alarm_level"
                                               :id="row.item.traplinked_report_id"
                                               v-on:set-ergebnis-text="setErgebnisText"
                          />
                        </span>
                        <span v-else-if="row.item.traplinked_device_id">
                          <traplinked-ergebnis v-model="row.item.traplinked_report_device_status"
                                               :report-type="row.item.traplinked_report_report_type"
                                               :event-type="row.item.traplinked_report_event_type"
                                               :alarm-level="row.item.alarm_level"
                                               :id="row.item.traplinked_report_id"
                                               v-on:set-ergebnis-text="setErgebnisText"
                          />
                        </span>
                        <span v-else-if="!hideHeaders && (row.item.result_value || row.item.is_not_checkable)">
                          <ergebnis-and-chart
                               :hide-chart="hideHeaders"
                               :alarm_level="row.item.alarm_level"
                               :result_value="row.item.result_value"
                               :not-checkable="row.item.is_not_checkable"
                               :task_ergebnis_id="row.item.task_ergebnis_id"
                               :customer_ergebnis_id="row.item.customer_ergebnis_id"
                          />
                        </span>
                        <span v-else-if="hideHeaders && (row.item.result_value || row.item.is_not_checkable)">
                          <ergebnis-color-and-value-no-chart
                              :hide-chart="hideHeaders"
                              :alarm_level="row.item.alarm_level"
                              :result_value="row.item.result_value"
                              :not-checkable="row.item.is_not_checkable"
                              :task_ergebnis_id="row.item.task_ergebnis_id"
                          />
                        </span>
                    </template>

                    <template #cell(berater_name)="row">
                        <b-button variant="outline-primary"
                                  size="sm"
                                  v-if="row.item.berater_name"
                                  @click="showBearbeiter(row.item.berater_id)">
                             <b-icon icon="person-square"></b-icon>&nbsp;{{ row.item.berater_name }}
                        </b-button>
                    </template>

                    <template #cell(sicherheitsdatenblaetter)="row">
                        <sicherheitsdatenblaetter v-if="row.item.article_id" :article_id="row.item.article_id" />
                    </template>

                    <!-- Link auf Plandarstellung von Position weg -->
                    <template #cell(position_nr)="row">
                      <span v-if="row.item.position_nr && row.item.position_id">
                        <ShowPositionInMap :position-id="row.item.position_id"
                                           :position-nr="row.item.position_nr"
                        />
                      </span>
                    </template>

                    <!-- Bei Positions Text noch zusätzlich ausgeben ob es eine temp Position (Sap Temp) ist: -->
                    <template #cell(position_beschreibung)="row">
                      <span v-if="row.item.position_beschreibung">
                       {{ row.item.position_beschreibung }}
                      </span>
                      <span v-if="row.item.stays_temp">
                        <b-badge variant="info">{{ $t('message.positionDetails_zusatzleistung') }}</b-badge>
                      </span>
                    </template>

                    <!-- Traplinked task_name Biotech 24/7 anzeigen, sonst den tast_name -->
                    <template #cell(task_name)="row">
                      <span v-if="row.item.task_name">
                       {{ row.item.task_name }}
                      </span>
                      <span v-else-if="row.item.traplinked_device_id">
                        Biotech 24/7
                      </span>
                    </template>

                    <!-- Traplinked objekt_bezeichnung Device anzeigen, sonst die objekt_bezeichnung -->
                    <template #cell(objekt_bezeichnung)="row">
                      <span v-if="row.item.objekt_bezeichnung">
                       {{ row.item.objekt_bezeichnung }}
                      </span>
                      <span v-else-if="row.item.objekt && row.item.objekt.bezeichnung">
                        {{ row.item.objekt.bezeichnung }}
                      </span>
                      <span v-if="row.item.traplinked_device_traplinked_id">
                        <small>(Seriennummer: {{ row.item.traplinked_device_traplinked_id }})</small>
                      </span>
                    </template>

                    <!-- bei Traplinked in die Bemerkung den Timestamp reinschreiben -->
                    <template #cell(bemerkung)="row">
                      <span v-if="row.item.bemerkung">
                       {{ row.item.bemerkung }}
                       <br v-if="row.item.traplinked_device_installiert_bis"/>
                      </span>
                      <span v-if="row.item.traplinked_device_installiert_bis">
                        <small>({{ $t('message.besuchsberichtErgebnisse_deinstalliert_am') }}: <java-date-to-default-date-format v-model="row.item.traplinked_device_installiert_bis" />)</small>
                      </span>
                    </template>

                    <template v-slot:cell(bild)="row">
                      <span v-if="row.item.count_traplinked_report_image">
                        <show-traplinked-report-image v-model="row.item.traplinked_report_id" />
                      </span>
                    </template>

                </b-table>

                <b-row>
                    <b-col md="12" class="my-1">
                        <b-pagination :total-rows="totalRows"
                                      :per-page="perPage"
                                      v-model="currentPage"
                                      class="my-0" />
                    </b-col>
                </b-row>

            </div>
        </transition>

        <!-- Modal für Personal Details -->
        <b-modal id="modal-berater" title="Ihr(e) Berater(in)" hide-footer scrollable>
            <person :person="person" />
        </b-modal>

        <!-- Dummy Form um pdf und excel zu holen -->
        <form ref="form" action="" id="form_id" target="_blank" method="post" style="position: fixed; left: -1000px; top: -1000px;">
            <input name="type"            id="form_type"            value="">
            <input name="liste"           id="form_liste"           value="">
            <input name="customer"        id="form_customer"        value="">
            <input name="zeitraum"        id="form_zeitraum"        value="">
        </form>

    </span>

</template>

<script>

    import $ from 'jquery'
    import axios from 'axios'
    axios.defaults.withCredentials = true
    import Sicherheitsdatenblaetter from '@/components/ergebnisliste/Sicherheitsdatenblaetter'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
    import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
    import { faPrint } from '@fortawesome/free-solid-svg-icons'
    library.add(faFileExcel, faFilePdf, faPrint)
    import ShowTraplinkedReportImage from '@/components/traplinkedbericht/ShowTraplinkedReportImage.vue'
    import TraplinkedErgebnis from '@/components/traplinkedbericht/TraplinkedErgebnis'
    import JavaDateToDefaultTimeFormat from '@/components/layout/JavaDateToDefaultTimeFormat'
    import JavaDateToDefaultDateFormat from '@/components/layout/JavaDateToDefaultDateFormat'
    import ErgebnisColorAndValueNoChart from '@/components/ergebnisliste/ErgebnisColorAndValueNoChart'
    import Person from '@/components/team/person'
    import ErgebnisAndChart from '@/components/ergebnisliste/ErgebnisAndChart'

    // Define a new component
    export default {
        name: 'MonatsberichtErgebnisse',
        components: {
          Person,
          ErgebnisAndChart,
          Sicherheitsdatenblaetter,
          ShowPositionInMap: () => import('@/components/plan/ShowPositionInMap'),
          TraplinkedErgebnis,
          JavaDateToDefaultTimeFormat,
          JavaDateToDefaultDateFormat,
          ErgebnisColorAndValueNoChart,
          ShowTraplinkedReportImage,
        },
        props: [
          /** die Ergebnisse Liste */
          'value',
          'zeitraum',
          /** zeigt keine Filter oder Exporte wenn true */
          'hideHeaders'
        ],
        /*
          Defines the data used by the component
        */
        data: function () {
          return {
            ergebnisse: [],
            anzahl: 0,
            fields: [
              {
                key: 'datum_end',
                label: this.$t('message.printErgebnisliste_datum_uhrzeit'),
                sortable: true
              },
              {
                key: 'area_name',
                label: this.$t('message.printPositionen_bereich'),
                sortable: true
              },
              {
                key: 'position_nr',
                label: this.$t('message.printPositionen_position_nummer'),
                sortable: true
              },
              {
                key: 'position_beschreibung',
                label: this.$t('message.printPositionen_position'),
                sortable: true
              },
              {
                key: 'task_name',
                label: this.$t('message.printErgebnisliste_aufgabe'),
                sortable: true
              },
              {
                key: 'objekt_bezeichnung',
                label: this.$t('message.printErgebnisliste_objekt'),
                sortable: true
              },
              {
                key: 'alarm_level',
                label: this.$t('message.printErgebnisliste_objekt'),
                sortable: true
              },
              {
                key: 'bemerkung',
                label: this.$t('message.printErgebnisliste_bemerkung'),
                sortable: true
              },
              {
                key: 'item_name',
                label: this.$t('message.printErgebnisliste_material'),
                sortable: true
              },
              {
                key: 'menge',
                label: this.$t('message.printErgebnisliste_menge'),
                sortable: true
              },
              {
                key: 'sal_unit_msr',
                label: this.$t('message.printErgebnisliste_einheit'),
                sortable: true
              },
              {
                key: 'berater_name',
                label: this.$t('message.printErgebnisliste_berater'),
                sortable: true
              },
              {
                key: 'sicherheitsdatenblaetter',
                label: this.$t('message.ergebnisseTable_sdb'),
                sortable: false
              },
              {
                key: 'bild',
                label: '',
                sortable: false
              }
            ],
            /** Filter Query String für Suche nach Position */
            keyword: '',
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            /** Pagination der Ergebnistabelle */
            currentPage: 1,
            /** Berater der in den Details angezeigt wird */
            person: null,
            form_action: null,
            form_type: null,
            form_liste: null,
            form_customer: null,
            sortBy: 'datum_end',
            sortDesc: true,
          }
        },
        computed: {

          items: function () {

            var lowercase = this.keyword ? $.trim(this.keyword.toLowerCase()) : null;
            console.log('filter: ' + lowercase);
            var selectedRows = lowercase
                ? this.ergebnisse.filter( item =>

                    (item.area_name                       && item.area_name.toLowerCase().includes(lowercase))                ||
                    (item.position_nr                     && item.position_nr.toLowerCase().includes(lowercase))              ||
                    (item.position_beschreibung           && item.position_beschreibung.toLowerCase().includes(lowercase))    ||
                    (item.task_name                       && item.task_name.toLowerCase().includes(lowercase))                ||
                    (item.objekt_bezeichnung              && item.objekt_bezeichnung.toLowerCase().includes(lowercase))       ||
                    (item.ergebnis                        && item.ergebnis.toLowerCase().includes(lowercase))                 ||
                    (item.item_name                       && item.item_name.toLowerCase().includes(lowercase))                ||
                    (item.menge == lowercase)                                                                                 ||
                    (item.berater_name                    && item.berater_name.toLowerCase().includes(lowercase))             ||
                    (item.is_not_checkable === true       && 'nicht überprüfbar'.includes(lowercase))                         ||
                    (item.traplinked_device_traplinked_id && item.traplinked_device_traplinked_id.toLowerCase().includes(lowercase)) ||
                    (item.ergebnisText                    && item.ergebnisText.toLowerCase().includes(lowercase))             ||
                    (item.bemerkung                       && item.bemerkung.toLowerCase().includes(lowercase))                ||
                    (item.result_value                    && item.result_value.toLowerCase().includes(lowercase))

                )
                : this.ergebnisse;

            return selectedRows;

          },
          totalRows: function () {
            if (this.items) {
              return this.items.length;
            }
            return 0;
          }

        },
        watch: {

          value: function () {
            this.init();
          }

        },
        mounted: function () {

          this.init();

        },
        methods: {

          /**
           * Text des Ergebnisses in Liste aufnehmen zum Filtern
           * @param obj
           */
          setErgebnisText: function (obj) {

            /*
            const obj = {
              text: text,
              id: this.id
            }
            */

            for (let i = 0; i < this.ergebnisse.length; i++) {
              if (this.ergebnisse[i].traplinked_report_id == obj.id) {
                this.ergebnisse[i].ergebnisText = obj.text;
                break;
              }
            }

          },
          /** Sortierung der Positionsergebnisse */
          sortPositionsergebnis: function (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {

            // console.log("sort by key = " + key + " a=" + aRow);

            if (key === 'alarm_level') {

              const a = aRow[key];
              const b = bRow[key];
              const aValue = aRow['result_value'];
              const bValue = bRow['result_value'];
              var first = 0;
              var next  = 0;
              console.log('sort by key = ' + key + ' a= ' + (a ? a : null) + ' b='
                + (b ? b : null) + ' value a = ' + aValue + ' value b = ' + bValue);
              if (a && a == 'GRUEN')      first = 1;
              else if (a && a == 'GELB')  first = 2;
              else if (a && a == 'ROT')   first = 3;
              if (b && b == 'GRUEN')      next = 1;
              else if (b && b == 'GELB')  next = 2;
              else if (b && b == 'ROT')   next = 3;

              // wenn alarm_level ungleich ist, dann sortiere nach diesem:
              if (first != next) {
                return first < next ? -1 : first > next ? 1 : 0;
              } else {
                // sonst sortiere nach dem result_value
                console.log('sort by value a = ' + aValue + ' value b = ' + bValue);
                return this.toString(aValue).localeCompare(this.toString(bValue), compareLocale, compareOptions);
              }

            }

          },
          /**
           * zeige Druck Page
           */
          print: function () {

            var items       = JSON.stringify(this.items);
            var ended       = this.zeitraum;

            let routeData = this.$router.resolve({path: '/printErgebnisliste/:name', query: {
                items: items,
                ended: ended,
                kundenname: this.$store.getters.customer.kunde,
                adresse: this.$store.getters.customer.zipcode + " " + this.$store.getters.customer.ort + ', ' + this.$store.getters.customer.adresse,
                cardcode: this.$store.getters.customer.cardcode
            }});
            console.log('open ' + routeData.href);
            window.open(routeData.href, '_blank');

          },
          /**
           * PDF oder Excel der Ergebnisliste anzeigen
           */
          generateReport: function (type) {

            var serverurl       = process.env.VUE_APP_SERVER_URL
            var liste           = JSON.stringify(this.items);
            liste               = encodeURI(liste);
            var kunde           = JSON.stringify(this.$store.getters.customer)
            kunde               = encodeURI(kunde);
            var url             = serverurl + '/report/generateErgebnislisteReport';

            this.form_action = url;
            document.getElementById('form_id').action               = url;
            document.getElementById('form_type').value              = type;
            document.getElementById('form_liste').value             = liste;
            document.getElementById('form_customer').value          = kunde;
            if (this.zeitraum) { // Zeitraum eines Monatsberichts
              document.getElementById('form_zeitraum').value        = this.zeitraum;
            }

            this.$refs.form.submit();

          },
          /** zeigt Details eines Personals */
          showBearbeiter: function (personal_id) {

            this.person  = null;
            this.showSpinner();
            var person   = { id: personal_id };
            var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPerson';
            var that     = this;
            var params   = {
              person: JSON.stringify(person)
            };
            axios ({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              that.hideSpinner();
              that.person = response.data;
              that.$bvModal.show('modal-berater');

            }).catch(error => {
              console.log(error);
              that.hideSpinner();
            });

          },
          init: function () {

            this.ergebnisse = this.value;
            this.sortBy = 'datum_end';
            this.sortDesc = true;

          }

        }
    }

</script>

<style scoped>

    #modal-berater .img-fluid {
        width: 100%;
    }

</style>
