<template>

  <div v-if="bereiche">
    <span id="selectObjekttypenWrapper">
      <b-dropdown id="dropdown-form-areas"
                  :text="$t('message.selectBereiche_filtern')"
                  ref="dropdown"
                  variant="outline-primary">
        <b-dropdown-form>
          <b-form-checkbox-group
              id="checkbox-group-areas"
              v-model="selected"
              :options="options"
              name="checkbox-group-areas"
          />
        </b-dropdown-form>
      </b-dropdown>
      <b-alert variant="warning"
               show
               v-if="!selected || selected.length < 1">
        {{ $t('message.selectBereiche_mind') }}
      </b-alert>
    </span>
  </div>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    // Define a new component
    export default {
        name: 'SelectBereiche',
        components: {
        },
        props: ['plan'],
        /*
          Defines the data used by the component
        */
        data: function(){
            return {
              selected: null,
              options: [],
              bereiche: null
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          plan: function () {
            this.init();
          },
          selected: function (newVal, oldVal) {
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.emitme();
            } else if (newVal) {
              this.emitme();
            }
          }

        },
        methods: {

          init: function () {

            console.log('hole areas von plan ' + this.plan);
            this.options  = [];
            this.bereiche = null;
            this.selected = [];
            if (this.plan) {

              var that    = this;
              var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAreas';
              var params  = {
                'plan_id':    this.plan.id
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(function (response) {

                that.bereiche = response.data;
                if (that.bereiche && that.bereiche.length > 0) {
                  for (var i = 0; i < that.bereiche.length; i++) {

                    var text = that.bereiche[i].name;
                    if (!text || text == 'null') {
                      text = '-- ' + that.$t('message.selectBereich_keine_bezeichnung') + ' --';
                    }

                    that.options.push({ value: that.bereiche[i], text: text });
                    // default sind alle selektiert
                    that.selected.push(that.bereiche[i]);

                  }
                }

              });

            }

          },
          emitme: function () {

            // es muss mindestens eine Area ausgewählt werden
            if (!this.selected || this.selected.length < 1) {
              console.info("es muss mindestens eine Area ausgewählt werden", this.selected);
            } else {
              this.$emit('set-areas', this.selected);
            }

          }

        }

    }

</script>
